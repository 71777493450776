const defaultLang ="es";

var langPage = document.querySelector("html");
if (langPage != null) {
	langPage = langPage.getAttribute("lang");
}

if (langPage === null) {
	langPage = defaultLang; // default language
}
langPage = langPage.toLowerCase();

if (langPage =='gl') {
	langPage = 'ga';
}

function deployMenuPrincipal() {
    var renderedElement = jQuery("a.main-header__principal-menu__link.nav-link[data-id='"+getCurrentChannel()+"']");
    if (renderedElement.length > 0) {
        $(renderedElement).addClass("active");
    }
}

function addMenuLevel2(){
	let menuLateral = $(".block-direct-links-category__wrapper.jsFromDesktop");
	if(menuLateral.length > 0) {
		let breadCrumbMenu = $(".block-breadcrumbs__nav ul li");
		let divBlockSection = $.parseHTML('<div class=block-back-to-section>');
		let pBlockSectionWrapper = $.parseHTML('<p class=block-back-to-section__wrapper>');
		let spanIcon = $.parseHTML('<span class=\"icon-chevron-left link-text-icon-forward__icon\">');
		let breadCrumb;

		if(breadCrumbMenu.length > 0) {
			if (breadCrumbMenu.length > 2)
				breadCrumb = breadCrumbMenu[1];
			else breadCrumb = breadCrumbMenu[0];

			let linkBreadCrumb = $(breadCrumb).find("a");
			if(linkBreadCrumb) {
				let linkTitle = $(linkBreadCrumb).attr("title");
				let linkTarget = $(linkBreadCrumb).attr("target");
				let linkHref = $(linkBreadCrumb).attr("href");

				if(linkTitle && linkTarget && linkHref) {

					let spanDescription = $.parseHTML('<span class=link-text-icon-forward__description>');
					spanDescription = $(spanDescription).html(linkTitle);

					let aLinkReverse = $.parseHTML('<a class=\"block-back-to-section__link link-text-icon-forward link-text-icon-forward--reverse\"' +
						'href=\" ' + linkHref + '\"' + 'target=\"' + linkTarget + '\"' + 'title=\"' + linkTitle + '\"' + '>');

					aLinkReverse = $(aLinkReverse).html(spanDescription);
					aLinkReverse = $(aLinkReverse).append(spanIcon);

					pBlockSectionWrapper = $(pBlockSectionWrapper).html(aLinkReverse);

					divBlockSection = $(divBlockSection).html(pBlockSectionWrapper);

					$(menuLateral).before(divBlockSection);
				}
			}
		}

	}
}

function deployMenuLateral() {
    let renderedElement = jQuery(".link-direct-text__item a[data-id='"+getCurrentChannel()+"']");
	let find = false;
	let i = 0;
	if(renderedElement.length == 0) {
		let breadcrumbList = jQuery(".block-breadcrumbs__list-wrapper li");
		let breadcrumbFather ="";
		let breadcrumbFatherId ="";
		let breadcrumbListSize = breadcrumbList.length;
		if(breadcrumbListSize > 0) {
			i = breadcrumbListSize - 2;
			while (0 <= i  && find === false) {
				breadcrumbFather = $(breadcrumbList[i]);
				breadcrumbFatherId = $(breadcrumbFather).find("a").attr("data-id");
				if(breadcrumbFatherId) {
					renderedElement = jQuery(".link-direct-text__item a[data-id='" + breadcrumbFatherId + "']");
					i--;
					if (renderedElement.length > 0) find = true;
				}
			}
		}

	}
    if (renderedElement.length > 0) {
        $(renderedElement).parent(".link-direct-text__item").addClass("link-direct-text__item--active");
        var parents = $(renderedElement).parents(".link-direct-text__item");
        parents.each(function () {
            $(this).find("ul").first().removeClass("hide-element");
            $(this).find(".js-blockDirectLinksCategory").first().attr("aria-expanded","true");
        });
    }
}

function capitalizeFirstLetter(string) {
	return string.charAt(0).toUpperCase() + string.slice(1);
}

function addDaysToDate(date, days) {
	let result = new Date(date);
	result.setDate(result.getDate() + days);
	return result;
}

function divDateErrorDisplayNone(){
	let textError = $(".input-error-message");

	if(textError.length > 0)
		textError.css("display","none");

}

function validateDate(fixSameMonth) {
	let start = $("#datarangef1");
	let end = $("#datarangef2");
	let startValue;
	let endValue;
	let constDay ="01/";
	let correctDate = true;
	let textError = $(".input-error-message");

	if(start.length > 0 ) {
		startValue = start.val();
		startValue = startValue === "" ? null :  (constDay + startValue);
	}

	if(end.length > 0 ) {
		endValue = end.val();
		endValue = endValue === "" ? null : (constDay + endValue);
	}

	if((typeof startValue !== 'undefined' && startValue !== null ) && ( typeof endValue !== 'undefined' && endValue !== null )) {
		endValue = Date.parse(endValue);
		startValue = Date.parse(startValue);
		if(startValue > endValue  ) {
			if (!fixSameMonth) {
				correctDate = false;
				if (textError.length > 0)
					textError.css("display", "block");
			} else {
				$("#datarangef2").val($("#datarangef1").val());
			}
		}
	}
	return correctDate;
}

function translatedFilterMonth() {
	$(".js-schedule-field.js-schedule-field-month").on("click", function (event) {
		translateMonth();
		divDateErrorDisplayNone();
		event.preventDefault();
		$(".datepicker").on("click", function (event) {
			translateMonth();
			event.preventDefault();
		});
	});
}

//Funcion para obtener el último día de cada mes
function getLastMonthDay(month, year){
	return new Date(year, month, 0).getDate();
}

function validateInputSize() {
	let input = $("#input_search");
	let emptyInput = $("#emptyInput");
	let smallInput = $("#smallInput");
	let validateSize = true;

	if( emptyInput.length > 0)
		emptyInput.css("display","none");

	if( smallInput.length > 0)
		smallInput.css("display","none");

	if(input.length > 0) {
		let text = input.val();
		if(text.length === 0) {
			validateSize = false;
			if( emptyInput.length > 0)
				emptyInput.css("display","block");
		}

		if (text.length > 0 && text.length <3 ) {
			validateSize = false;
			if( smallInput.length > 0)
				smallInput.css("display","block");
		}
	}
	return validateSize;
}

function translateMonth(){
	let month = {
		"es":["enero","febrero","marzo","abril","mayo","junio","julio","agosto","septiembre","octubre","noviembre","diciembre"],
		"ca":["gener","febrer","març","abril","maig","juny","juliol","agost","setembre","octubre","novembre","desembre"],
		"en":["january","february","march","april","may","june","july","august","september","october","november","december"],
		"gl":["xaneiro","febreiro","marzo","abril","maio","xuño","xullo","agosto","setembro","outubro","novembro","decembro"],
		"ga":["xaneiro","febreiro","marzo","abril","maio","xuño","xullo","agosto","setembro","outubro","novembro","decembro"],
		"eu":["urtarrila","otsaila","martxoa","apirila","maiatza","ekaina","uztaila","abuztua","iraila","urria","azaroa","abendua"],
		"va":["gener","febrer","març","abril","maig","juny","juliol","agost","setembre","octubre","novembre","desembre"]
	};

	let monthNamesShort = {
		"es":["ene","feb","mar","abr","may","jun","jul","ago","sep","oct","nov","dic"],
		"ca":["gen","feb","març","abr","maig","juny","jul","ag","set","oct","nov","des"],
		"en":["jan","feb","mar","apr","may","jun","jul","aug","sep","oct","nov","dec"],
		"gl":["xan","feb","mar","abr","mai","xuñ","xul","ago","set","out","nov","dec"],
		"ga":["xan","feb","mar","abr", "mai", "xuñ","xul", "ago", "set", "out", "nov", "dec"],
		"eu":["urt.","ots.","mar.","api.","mai.","eka.","uzt.","abu.","ira.","urr.","aza.","abe."],
		"va":["gen","feb","març","abr","maig","juny","jul","ag","set","oct","nov","des"]
	};

	let dayOfTheWeekendSite=["Lu","Ma","Mi","Ju","Vi","Sa","Do"];

	let dayOfTheWeekend={
		"es":["Lu","Ma","Mi","Ju","Vi","Sa","Do"],
		"ca":["Dl","Dt","Dc","Dj","Dv","Ds","Dg"],
		"en":["Mo","Tu","We","Th","Fr","Sa","Su"],
		"gl":["Lu","Ma","Mé","Xo","Ve","Sá","Do"],
		"ga":["Lu","Ma","Mé","Xo","Ve","Sá","Do"],
		"eu":["Al","Ar","Az","Og","Ol","Lr","Ig"],
		"va":["Dl","Dt","Dc","Dj","Dv","Ds","Dg"]
	};

  let calenderMonth = $(".js-schedule-inline .datepicker-days .datepicker-switch").text();
  let calenderYear = new Date().getFullYear();
	 if(calenderMonth.length > 0) {
		 calenderYear= (calenderMonth.split(" ")[1]).trim();
		 calenderMonth = (calenderMonth.split(" ")[0]).trim().toLowerCase();
	 }
  let indexOfMonth;
  let translatedMonth;
  let finalString;
  let dayOfTheWeekendText;
  let indexOfDayOfWeek;
  let monthSort;

  if(month.hasOwnProperty("es")) {
	  indexOfMonth = month["es"].indexOf(calenderMonth);
  }
  if(indexOfMonth !== -1 && month.hasOwnProperty(langPage)) {
	  translatedMonth = capitalizeFirstLetter(month[langPage][indexOfMonth]);
	  finalString = translatedMonth + " "+calenderYear;
	  $(".datepicker-days .datepicker-switch").text(finalString);
  }

	$(".table-condensed .dow").each(function (){
		dayOfTheWeekendText = $(this).text();
		indexOfDayOfWeek = dayOfTheWeekendSite.indexOf(dayOfTheWeekendText);
		if(indexOfDayOfWeek !== -1 && dayOfTheWeekend.hasOwnProperty(langPage)) {
			$(this).text(dayOfTheWeekend[langPage][indexOfDayOfWeek]);
		}
	});


	$(".datepicker-months .month").each(function () {
		monthSort = $(this).text().toLowerCase();
		if(monthNamesShort.hasOwnProperty("es")) {
			indexOfMonth = monthNamesShort["es"].indexOf(monthSort);
		}
		if(indexOfMonth !== -1 && monthNamesShort.hasOwnProperty(langPage)) {
			$(this).text(capitalizeFirstLetter(monthNamesShort[langPage][indexOfMonth]));
		}
	});

}

function getSortOrderDate(prop) {
	return function(a, b) {
		if ( ((new Date (a[prop])).getTime())  > ((new Date (b[prop])).getTime()) ) {
			return 1;
		} else if ( ((new Date (a[prop])).getTime())  <  ((new Date (b[prop])).getTime()) ) {
			return -1;
		}
		return 0;
	};
}


function generateCalendarJson(){
	
	const fileNameES ="calendarioes.json";
	const fileNameEN ="calendarioen.json";
	const fileNameEU ="calendarioeu.json";
	const fileNameCA ="calendarioca.json";
	const fileNameVA ="calendariova.json";
	const fileNameGA ="calendarioga.json";
	
	const urlES ="https://app.bde.es/bierest/resources/srdatosapp/eventos?idioma=es&origen=SUBH";
	const urlEN ="https://app.bde.es/bierest/resources/srdatosapp/eventos?idioma=en&origen=SUBH";
	const urlEU ="https://app.bde.es/bierest/resources/srdatosapp/eventos?idioma=eu&origen=SUBH";
	const urlCA ="https://app.bde.es/bierest/resources/srdatosapp/eventos?idioma=ca&origen=SUBH";
	const urlVA ="https://app.bde.es/bierest/resources/srdatosapp/eventos?idioma=va&origen=SUBH";
	const urlGA ="https://app.bde.es/bierest/resources/srdatosapp/eventos?idioma=ga&origen=SUBH";
	
	let url;
	switch (langPage) {
		case 'es' :
			url = urlES;
			break;
		case 'en' :
			url = urlEN;
			break;
		/* Descomentar el siguiente bloque si alguna vez el servicio Bierest 
		 * se ofrece en cooficiales, por el momento se usa español para todos
		 * Inicio bloque cooficiales
		/*case 'eu' :
			url = urlEU;
			break;
		case 'ca' :
			url = urlCA;
			break;
		case 'va' :
			url = urlVA;
			break;
		case 'ga' :
			url = urlGA;
			break;
			Fin de bloque cooficiales */
		default :
			url = urlES;
			break;
	}
	

	jQuery
		.getJSON(url)
		.done((data) => {
			if (data !== null) {
				data.sort(getSortOrderDate("fechaPub"));
				generateCalendarHtml(data,langPage);
			} else {
				console.log("No Json data!");
				$(".noEventFound").css("display", "block");
			}
		})
		.fail(function(xhr, status,error) {
			console.log("Error getting the json!");
			$(".noEventFound").css("display", "block");
		});

}

function generateHtmlForEachEvent(urlEvent,tituloEvent,hour,minutes){
	let listItemClass;

	let liListItem = jQuery.parseHTML('<li class="block-schedule__content__appointments__list-item">');

	listItemClass = jQuery.parseHTML(
		'<a class="block-schedule__appointment appointment--statistics" style="border-left-color: #008286" target="_blank" href="' +
		urlEvent +
		'"' +
		'title="' +
		tituloEvent +
		'"' +
		'>'
	);

	let appointmentContent = jQuery.parseHTML("<span class='block-schedule__appointment__content'>");
	let appointmentHour;
	if (hour != null && minutes != null) {
		appointmentHour = jQuery.parseHTML("<span class='block-schedule__appointment__hour'>");
		minutes = minutes <= 9 ? '0'+minutes : minutes ;
		hour = hour <= 9 ? '0'+hour+':'+minutes+'h' : hour+':'+minutes+'h' ;
		appointmentHour = jQuery(appointmentHour).html(hour);
	}
	let appointmentText = jQuery.parseHTML("<span class='block-schedule__appointment__text'>");
	appointmentText = jQuery(appointmentText).html(tituloEvent);
	if (appointmentHour != null)
		appointmentContent = jQuery(appointmentContent).append(appointmentHour);
	appointmentContent = jQuery(appointmentContent).append(appointmentText);

	listItemClass = jQuery(listItemClass).append(appointmentContent);
	liListItem = jQuery(liListItem).append(listItemClass);

	return liListItem;
}

function getDayName(dateStr)
{
	return new Date(dateStr).toLocaleDateString(langPage, { weekday: 'long' });
}

function getMonthName(dateStr)
{
	return new Date(dateStr).toLocaleDateString(langPage, { month: 'long' });
}

function generateCalendarHtml(dataEventJson,langPage) {
	let actualDay = new Date();
	let i = 0;
	let event,eventDate,appointmentsDayClass,month,day,year,
		dayEvent,yearEvent,urlEvent,tituloEvent,
		hour,minutes,appointmentsList,eventDateString,pDayDate;
	let listOfMountEquals = [];
	let findEvent=false;

	for(i=0 ; i < dataEventJson.length ; i++) {
		findEvent=false;
		event = dataEventJson[i];
		eventDateString = event.fechaPub;
		urlEvent = event.url;
		tituloEvent = event.titulo;
		tituloEvent = tituloEvent !== null ? tituloEvent.replace("\'","") : tituloEvent;
		if(eventDateString != null) {
			eventDate = new Date(eventDateString);
			if (eventDateString.includes('T')) {
				hour = eventDate.getUTCHours();
				minutes = eventDate.getUTCMinutes();
			} else {
				hour = null;
				minutes = null;
			}
			//if((actualDay.getUTCMonth() === eventDate.getUTCMonth()) && (actualDay.getUTCFullYear() == eventDate.getUTCFullYear())) {
				month = eventDate.getUTCMonth() + 1;
				year = eventDate.getUTCFullYear();
				day = eventDate.getUTCDate();

				listOfMountEquals = jQuery(".block-schedule__content__appointments__day[data-date-month='" + (month) + "']");
				if(listOfMountEquals.length > 0) {
					listOfMountEquals.each(function (element) {
						dayEvent = parseInt($(this).attr("data-date-day"));
						yearEvent = parseInt($(this).attr("data-date-year"));
						if (dayEvent === day && yearEvent === year) {
							findEvent = true;
							$(this).find("ul").append(
								generateHtmlForEachEvent(urlEvent,tituloEvent,hour,minutes));
						}
					});
				}
				if(findEvent == false) {
					appointmentsDayClass = jQuery.parseHTML('<div style="display: none;" class="block-schedule__content__appointments__day"' +
						'data-date-month="' + (month) + "\"" +
						'data-date-day="' + day + "\"" +
						'data-date-year="' + year +"\"" +">");

					appointmentsList = jQuery.parseHTML('<ul class="block-schedule__content__appointments__list">');
					appointmentsList = jQuery(appointmentsList).append(
						generateHtmlForEachEvent(urlEvent,tituloEvent,hour,minutes));
					pDayDate = jQuery.parseHTML('<p class="block-schedule__content__appointments__day__date">');
					pDayDate = jQuery(pDayDate).html(capitalizeFirstLetter(getDayName(eventDateString)) + ' ' + day + ' ' + getMonthName(eventDateString));

					appointmentsDayClass = jQuery(appointmentsDayClass).append(pDayDate);
					appointmentsDayClass = jQuery(appointmentsDayClass).append(appointmentsList);

					jQuery(".block-schedule__content__appointments").append(appointmentsDayClass);

				}
			//}
		}
	}
	calendarAllFunction();
}


function getIndicadores() {
	let langPageIndicadores = (langPage == "en" ? langPage : "es");
	
	const origen = window.location.origin;
	const fileIndicadoresES =origen + "/webbe/es/estadisticas/compartido/datos/json/indicators_es.json";
	const fileIndicadoresEN =origen + "/webbe/en/estadisticas/compartido/datos/json/indicators_en.json";
	
	if (langPage == 'en') {
		url = fileIndicadoresEN;
	}
	else {
		url = fileIndicadoresES;
	}

	jQuery
		.getJSON(url)
		.done((data) => {
			if (data !== null) {
				var list = data.indicators;
				list.map((item) => {
					var query = document.querySelector("#" + item.id);
					if (query) {
						query.querySelector("a").setAttribute("href", item.link);
						query.querySelector(".block-card-indicator__name").innerText = item.name;
						query.querySelector(".block-card-indicator__value").innerText = item.value;
						query.querySelector(".block-card-indicator__date").innerText = item.updated.replaceAll(".", "/");
						query.querySelector(".block-card-indicator__more-info").href = item.link;
					}
				});
			}
		})
		.fail(() => {
			console.log("No Json data!");
		});
}

function getIndicadoresEstadisticas() {
	let langPageIndicadores = (langPage == "en" ? langPage : "es");	
	let url;
	
	if (langPage == "en") {
		url ="https://app.bde.es/bierest/resources/srdatosapp/listaSeries?idioma=en&origen=SUBH&series=IT.MDG.STR.Z.APP,IT.TRG.EUR.Z.APP,CT.CED.USD.Z.APP,AP.PDE.TOT.RPIB.APP,IF.IFM.THG.TVA.APP,EX.BAP.CNF.RPIB.APP";
	}
	else {
		url="https://app.bde.es/bierest/resources/srdatosapp/listaSeries?idioma=es&origen=SUBH&series=IT.MDG.STR.Z.APP,IT.TRG.EUR.Z.APP,CT.CED.USD.Z.APP,AP.PDE.TOT.RPIB.APP,IF.IFM.THG.TVA.APP,EX.BAP.CNF.RPIB.APP";
	}
	
	jQuery
		.getJSON(url)
		.done((data) => {
			if (data !== null) {
				let list = data;
				list.map((item) => {
					let id = item.codigo.replace(/\./g, '\\.');
					let query = document.querySelector("#" + id);
					if (query) {
						let tooltipNode;
						let titulo;
						if (item.descripcionCortaWeb != null && item.descripcionCortaWeb != '') {
							titulo = item.descripcionCortaWeb;
							if (item.descripcion != null && item.descripcion != '') {
								tooltipNode = '<em class="icon-info" tabindex="0" data-bs-toggle="tooltip" data-bs-placement="bottom" title="' + item.descripcion + 
									'" data-bs-original-title="' + item.descripcion + '" aria-label="' + item.descripcion + '"></em></span></p>';			
							}
						}
						else {
							if (item.descripcion != null && item.descripcion != '') {
								titulo = item.descripcion;
							}
						}
						if (titulo != null && titulo != '') {
							query.querySelector(".block-card-indicator__name").innerText = titulo.replace('&euro;','€');
						}
						if (tooltipNode != null && tooltipNode != '') {
							query.querySelector(".block-card-indicator__name").insertAdjacentHTML( 'beforeend', tooltipNode );
						}
						if (item.valores != null && item.valores != '' && item.valores[0] != null && item.valores[0] != '' && item.decimales != null && item.decimales != '') {
							query.querySelector(".block-card-indicator__value").innerText = formatNumber(item.valores[0], langPageIndicadores, item.decimales) + ((item.simbolo != null) ? ((item.simbolo == '%') ? item.simbolo : ' ' + item.simbolo) : '');
						}
						if (item.fechaFin != null && item.fechaFin != '' && item.codFrecuencia != null && item.codFrecuencia != '') {
							query.querySelector(".block-card-indicator__date").innerText = formatDate(item.fechaFin, item.codFrecuencia, langPageIndicadores);
						}
						
						let enlace;
						if (item.informacion != null && item.informacion != '') {
							for (let i = 0; i < item.informacion.length; i++){
							    if (item.informacion[i].titulo == 'Series relacionadas (cuadro pdf)' || item.informacion[i].titulo == 'Related series (pdf table)') {
							    	enlace = item.informacion[i].descripcion;
							    }
							}
						}
						
						if ( enlace != null && enlace != '' ) {
							// Obtenemos solo el enlace href, ya que nos llega una etiqueta html <a> completa
							let href = getHrefBlockCardIndicatorMoreInfoElement(enlace);
							// Obtenemos la etiqueta traducida para añadirla al nuevo nodo que agregaremos al arbol DOM
							let label = query.querySelector(".ver-mas-label").textContent;
							query.querySelector(".block-card-indicator__content").append(createBlockCardIndicatorMoreInfoElement(href, titulo, label));
						}
					}
				});
			}
		})
		.fail(() => {
			console.log("No Json data!");
			failIndicadores("block-indicators-statistics");
		});
}
function getHrefBlockCardIndicatorMoreInfoElement(tag) {
	if (!tag) {
		return '';
	}
	let splitedTag = tag.split("'");
	if (!splitedTag || splitedTag.length < 2) {
		return '';
	}
	return splitedTag[1];
}
function createBlockCardIndicatorMoreInfoElement(href, title, label) {
    let element = document.createElement('p');
    let a = document.createElement('a');
    let span1 = document.createElement('span');
    let span2 = document.createElement('span');
    let em = document.createElement('em');

    element.classList.add('block-card-indicator__more-info-wrapper');
    a.classList.add('block-card-indicator__more-info','link-text-icon-forward');
    a.setAttribute('title', title.replace('&euro;','€'));
    a.setAttribute('href', href);
    a.setAttribute('target', '_blank');
    span1.classList.add('link-text-icon-forward__description');
    span1.textContent = label;
    span2.classList.add('link-text-icon-forward__icon');
    em.classList.add('icon-chevron-right');
    span2.append(em);
    a.append(span1);
    a.append(span2);
    element.append(a);
	return element;
}
function formatDate(fecha, freq, idioma) {
	var meses = {
		    'es': ['Ene', 'Feb', 'Mar', 'Abr', 'May', 'Jun', 'Jul', 'Ago', 'Sep', 'Oct', 'Nov', 'Dic'],
		    'en': ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']
		};
    var uno = fecha.split("T")[0];
    var dos = uno.split("-");
    if (freq == 'A')
        return dos[0];
    else if (freq == 'Q')
        if (idioma == 'es')
            return (Math.floor(Number(dos[1]) / 3) + 1) + 'T ' + dos[0];
        else
            return (Math.floor(Number(dos[1]) / 3) + 1) + 'Q ' + dos[0];
    else if (freq == 'M')
        return meses[idioma][Number(dos[1]) - 1] + ' ' + dos[0];
    else
        return dos[2] + '.' + dos[1] + '.' + dos[0];
}

function formatNumber(dato, idioma, ndec) {
    if (idioma == 'es')
        return Number(dato).toLocaleString('de', { minimumFractionDigits: ndec }); // Esto es porque no funciona bien con 'es' con los números del 1.000 al 9.999.
    else
        return Number(dato).toLocaleString('en', { minimumFractionDigits: ndec });
}

function failIndicadores(id) {
	document.querySelector("#" + id).hidden = true;
	document.querySelector("#" + id).innerText = "<!-- #ERROR de conexión -->";
}

function getMonth (date) {

	return date.getMonth() + 1;
}

function removeActiveClassFromCalender () {
	$("td.day.active").removeClass("active");
}

function putEventOnCalender() {

	$("td.day").each(function (index) {
		var timeStamp = parseInt($(this).attr("data-date"));
		var date = new Date(timeStamp);
		var month = getMonth(date);
		var day = date.getDate();
		var year = date.getFullYear();
		var findEvent = false;
		$(".block-schedule__content__appointments__day[data-date-month='" + month + "'], .block-schedule__events[data-date-month='" + month + "']").each(function (element) {
				var dayEvent = parseInt($(this).attr("data-date-day"));
				var yearEvent = parseInt($(this).attr("data-date-year"));
				if (dayEvent === day && yearEvent === year)
					findEvent = true;
			}
		);
		if(findEvent) $(this).addClass("active");
	});
}

function convertTZ(date, tzString) {
    return new Date((typeof date === "string" ? new Date(date) : date).toLocaleString("en-US", {timeZone: tzString}));
}

function hideAndShowEventOneWeek() {
	let actualDay = new Date();
	actualDay.setHours(0,0,0,0);
	let sevenEvents = 0;
	let eventDay, eventMonth, eventYear;
	let stingDate;
	let eventDate;
	let findEvent = false;
	let i = 0;
	let liList;
	let showMaxEvent= (typeof maxEventsToShow !== 'undefined') ? maxEventsToShow : 5;

	if($(".block-schedule__content__appointments__day,.block-schedule__events.collapsableevents").length > 0) {
		$(".block-schedule__content__appointments__day,.block-schedule__events.collapsableevents").each(function () {
			eventDay = $(this).attr("data-date-day").padStart(2, '0');
			eventMonth = $(this).attr("data-date-month").padStart(2, '0');
			eventYear = $(this).attr("data-date-year");
			stringDate = eventYear + '-' + eventMonth + '-' + eventDay + 'T00:00:00';
			if(stringDate !== null) {
				eventDate = new Date(stringDate);
				if ((eventDate.getTime() >= actualDay.getTime()) && (sevenEvents < showMaxEvent)) {
					liList = $(this).find("li");
					liList.css("display", "none");
					for(i = 0 ; ( (i < showMaxEvent) && (i < liList.length) && (sevenEvents < showMaxEvent) ) ; i++) {
						$(liList[i]).css("display", "flex");
						sevenEvents++;
					}
					$(this).css("display", "block");
					findEvent = true;
				} else $(this).css("display", "none");
			}
		});
		if (findEvent) $(".noEventFound").css("display", "none");
		else $(".noEventFound").css("display", "block");
	}
}


function hideAndShowEvent() {
    $(".datepicker.datepicker-inline").on("click", function (event) {
        if (typeof event.target.dataset.date !== 'undefined'){
            var timeStamp = parseInt(event.target.dataset.date);
            var date = new Date(timeStamp);
			date = convertTZ(date, "Europe/Madrid");
            var month = getMonth(date);
			var day = date.getDate();
			var year = date.getFullYear();
            var findEvent = false;

            $(".block-schedule__content__appointments__day[data-date-month='" + month + "'], .block-schedule__events.collapsableevents[data-date-month='" + month + "']").each(function (element) {
					var dayEvent = parseInt($(this).attr("data-date-day"));
					var yearEvent = parseInt($(this).attr("data-date-year"));
					if (dayEvent === day && yearEvent === year) {
						findEvent = true;
						$(this).css("display", "block");
						$(this).find("li").css("display", "flex");
						$(".noEventFound").css("display", "none");
					}
                    else
						$(this).css("display", "none");
                }
            );
            $(".block-schedule__content__appointments__day[data-date-month!='" + month + "'], .block-schedule__events.collapsableevents[data-date-month!='" + month + "']").css("display", "none");

            if(findEvent === false) $(".noEventFound").css("display", "block");
    }
        else {
			removeActiveClassFromCalender();
			hideAndShowEventOneWeek();
		}

		putEventOnCalender();
		checkIfFirstEventMonth();
		translateMonth();
		//dontMoveCalender();
    });

}


/**
 * Función que recupera el mes mostrado en el calendario y comprueba si se trata del primer mes con eventos,
 * si es el caso, se oculta la flecha para que no se pueda seguir navegando hacia atrás.
 */
function checkIfFirstEventMonth(){
  	var firstMonthWithEvents;
	var firstYearWithEvents;
	var lastMonthWithEvents;
	var lastYearWithEvents;
	var elements;
	var lastElement;
	
	if ($('.block-schedule__content__appointments__day').length > 0){
		// Para el calendario de la home y estadísticas mostraremos desde el primer mes/año que existen eventos
		firstMonthWithEvents = $('div.block-schedule__content__appointments__day').attr('data-date-month');
		firstYearWithEvents = $('div.block-schedule__content__appointments__day').attr('data-date-year');
		elements = document.querySelectorAll('.block-schedule__content__appointments__day');
		lastElement = elements[elements.length - 1];		
		lastMonthWithEvents = $(lastElement).attr('data-date-month');
		lastYearWithEvents = $(lastElement).attr('data-date-year');
	}
	if ($('.block-schedule__events').length > 0){
		// Para el calendario de la agenda mostraremos desde el primer mes/año que existen eventos
		firstMonthWithEvents = $('.block-schedule__events').attr('data-date-month');
		firstYearWithEvents = $('.block-schedule__events').attr('data-date-year');
		elements = document.querySelectorAll('.block-schedule__events.collapsableevents');
		lastElement = elements[elements.length - 1];
		lastMonthWithEvents = $(lastElement).attr('data-date-month');
		lastYearWithEvents = $(lastElement).attr('data-date-year');
	}
		
	var timeStamp = parseInt($("td.day").not('.old').attr("data-date"),10);
	var date = new Date(timeStamp);
	var month = getMonth(date);
	var year = date.getFullYear();
	if (firstYearWithEvents == year && firstMonthWithEvents == month){
		if( $(".table-condensed .prev").length > 0 ){
			$(".table-condensed .prev").css("visibility","hidden");
			$("td.old.day").each(function (index) {
				$(this).css("pointer-events","none");
			});
		}
			
	} else {
		if( $(".table-condensed .prev").length > 0 ){
			$(".table-condensed .prev").css("visibility","visible");
		}
		
	}
	if (lastYearWithEvents == year && lastMonthWithEvents == month){
		if( $(".table-condensed .next").length > 0 ){
			$(".table-condensed .next").css("visibility","hidden");
			$("td.old.day").each(function (index) {
				$(this).css("pointer-events","none");
			});
		}
			
	} else {
		if( $(".table-condensed .next").length > 0 ){
			$(".table-condensed .next").css("visibility","visible");
		}
		
	}
}

function dontMoveCalender(){
	if($(".datepicker-switch").length > 0 )
		$(".datepicker-switch").css("pointer-events","none");
}

/**
 * 
 function dontMoveCalender(){
	if($(".datepicker-switch").length > 0 )
		$(".datepicker-switch").parent().css("pointer-events","none");

	$("td.day").each(function (index) {
		let timeStamp = parseInt($(this).attr("data-date"));
		let date = new Date(timeStamp);
		let month = getMonth(date);
		let todayDate =  new Date();
		let todayMonth = getMonth(todayDate);

		if(todayMonth !== month) {
			$(this).css("pointer-events", "none");
			$(this).removeClass("active");
		}

	});
	if( $(".table-condensed .next").length > 0 )
		$(".table-condensed .next").css("visibility","hidden");

	if( $(".table-condensed .prev").length > 0 )
		$(".table-condensed .prev").css("visibility","hidden");

}
*/

function calendarAllFunction () {
	hideAndShowEventOneWeek();
	hideAndShowEvent();
	putEventOnCalender();
	translateMonth();
	translatedFilterMonth();
	dontMoveCalender();
	checkIfFirstEventMonth();
}


$(window).on("load",function () {
	if($("#calendarInlineDescription").length > 0) {
		if ($(".eventos-statics-calendar-json").length > 0) {
			generateCalendarJson();
		}
		else {
			calendarAllFunction();
		}
	}

	if($("nav ul.main-header__principal-menu").length > 0){
		deployMenuPrincipal();
	}

	if($(".block-direct-links-category__wrapper .block-direct-links-category").length > 0){
		deployMenuLateral();
	}
	
});


document.addEventListener("DOMContentLoaded", function () {
	if (document.querySelectorAll("#block-indicators-homepage").length > 0) {
		getIndicadores();
	}
	if (document.querySelectorAll("#block-indicators-statistics").length > 0) {
		getIndicadoresEstadisticas();
	}
});

function isNotEmpty(variable){
    if( typeof variable !== 'undefined' &&  variable != null &&  variable != "") {
      return true;
    }
    return false;
}

function fillDatesWhenEmpty(){
	let start = document.getElementById("datarangef1");
	let end = document.getElementById("datarangef2");

	if(start != null && end != null && start.value == null && start.value == "" && end.value == null && end.value == ""){
		return;
	}

	if(start != null && end != null && start.value != null && start.value != "" && end.value == null || end != null && end.value == ""){
		end.value = start.value;
	}

	if(start != null && end != null && start.value == null || start != null && start.value == "" && end.value != null && end.value != ""){
		start.value = end.value;
	}
}

function addParam(param, prefix){
	return isNotEmpty(param) ? prefix + "=" + param : "";
}

function getParameters(pageBtnElement){
    fillDatesWhenEmpty();
    let page = 1;
    if(typeof pageBtnElement !== 'undefined' && pageBtnElement != null){
        page = pageBtnElement.getAttribute("data-page");
    } else {
        page = params.has(pageParameter) ? params.get(pageParameter) : 1;
    }
    let type = document.getElementById("select-type") != null && document.getElementById("select-type").value || "";
	let inputSearch = (document.getElementById("input_search") != null && document.getElementById("input_search").value || document.getElementById("search") != null && document.getElementById("search").value) || "";
    let author = document.getElementById("select-author") != null && document.getElementById("select-author").value || "";
   
	let theme = jQuery("#generic-search")[0] != null && jQuery("#generic-search")[0].value || "";
    if (theme === "") {
    	theme = jQuery("#select-theme")[0] != null && jQuery("#select-theme")[0].value || "";
    }
    //eliminamos los espacios en blanco del primer tema del buscador general
    theme = theme.trim();
    
	let channel = params.has(channelParameter) ? params.getAll(channelParameter) : [];
    let role = document.getElementById("select-role") != null && document.getElementById("select-role").value || "";
    let start = document.getElementById("datarangef1") != null && document.getElementById("datarangef1").value?.replace("/", "") || "";
    let end = document.getElementById("datarangef2") != null && document.getElementById("datarangef2").value?.replace("/", "") || "";
    let sort = document.getElementById("select-order") != null && document.getElementById("select-order").value || "";
	let limit = document.getElementById("select-pagination") != null && document.getElementById("select-pagination").value || "10";

    //si viene por relevancia, cambiamos a DESC
    if (sort === relevanceParameter) sort ='DESC';

    var paramsArray = [
        addParam(page, "page"),
        addParam(type, "type"),
        addParam(inputSearch, "A1"),
        addParam(author, "author"),
        addParam(theme, "theme"),
        addParam(role, "role"),
        addParam(start, "start"),
        addParam(end, "end"),
		addParam(sort, "sort"),
		addParam(limit, "limit")
    ];

    if (params.has(relevanceParameter)) {
    	paramsArray.push(addParam(params.has(relevanceParameter),relevanceParameter));
    }

	jQuery.each(channel, function (index, value) {
		paramsArray.push(addParam(channel[index], "Channel"));
	});
	
    return paramsArray.filter((a) => a).join("&");
}


function getParametersSanciones(pageBtnElement){
    fillDatesWhenEmpty();
    let inputSearch = document.getElementById("input_search_sanciones") != null && document.getElementById("input_search_sanciones").value || "";
    let start = document.getElementById("datarangef1") != null && document.getElementById("datarangef1").value?.replace("/", "") || "";
    let end = document.getElementById("datarangef2") != null && document.getElementById("datarangef2").value?.replace("/", "") || "";
    
    var paramsArray = [
    	addParam(encodeURIComponent(inputSearch), "ptext"),
        addParam(start, "start"),
        addParam(end, "end"),
    ];

    return paramsArray.filter((a) => a).join("&");
}

function getListObjects(element, event) {
	event.preventDefault();
	let comboSeleccionOrden = $("#select-order");
	if (typeof (cmsSearchEnabled) !== 'undefined' && cmsSearchEnabled == 'false') {

		//send request to addSearch unconditionally if input search text is long enough
		if (!inputSearchLengthIsGreaterThan3()) {
			return false;
		}

		if (comboSeleccionOrden.length > 0) {
			if ((comboSeleccionOrden.val() === relevanceParameter) || (comboSeleccionOrden[0].length === 2)) {
				params.set(relevanceParameter, "true");
			}
		}
		if ($('#form-blog').length >= 1 && element != null) {
			params.set(pageParameter, parseInt(element.dataset.page));
		} else {
			params.set(pageParameter, 1);
		}
		updateWindowHistoryParameters();
		performSpecificSearchEngine();
		performBlogSearchEngine();
	}
	else {
		if ((jQuery('#input_search').length > 0 && jQuery('#input_search').val().length > 0) ||
			(jQuery('#search').length > 0 && jQuery('#search').val().length > 0)) {
			if (!inputSearchLengthIsGreaterThan3()) { return false; }
			if (isPreviewInstance()) { return false; }

			if (comboSeleccionOrden.length > 0) {
				if ((comboSeleccionOrden.val() === relevanceParameter) || (comboSeleccionOrden[0].length === 2)) {
					params.set(relevanceParameter, "true");
				}
			}

			params.set(pageParameter, 1);
			setTermParameter();
			updateWindowHistoryParameters();
			performSpecificSearchEngine();
			performConvocatoriasSearchEngine();
			performBlogSearchEngine();
			performLatestAcademicPublicationsSearchEngine();
			if (jQuery('#search').length > 0) {
				removeAllResultsBeforeReCharge();
				performGeneralSearchEngine();
			}
		}
		else {
			params.delete(relevanceParameter);
			params.set(pageParameter, 1);
			window.location = window.location.pathname + (window.location.pathname.substr(-1) != "/" ? "/?" : "?") + getParameters(element);
		}
	}
}

function toggleLoading() {
	let content = document.getElementsByClassName("block-search-content");
	let pagination = document.getElementsByClassName("block-pagination");
	let loader = document.getElementsByClassName("block-spiner");

	if (content && content[0] !== undefined && pagination && pagination[0] !== undefined) {
		if (content[0].classList.contains("nuevo_bde-disable"))
			content[0].classList.remove("nuevo_bde-disable");
		else content[0].classList.add("nuevo_bde-disable");

		if (pagination[0].classList.contains("nuevo_bde-disable"))
			pagination[0].classList.remove("nuevo_bde-disable");
		else pagination[0].classList.add("nuevo_bde-disable");
	}

	if (loader && loader[0] !== undefined) {
		if (loader[0].style.display === "block") loader[0].style.display = "none";
		else loader[0].style.display = "block";
	}
}

function testRegex(string, pattern) {
	return pattern.test(string);
}

function setSelectedParameter(id, param) {
	let urlParams = new URLSearchParams(window.location.search);
	let select = document.getElementById(id);
	if (urlParams.has(param) && select !== undefined) {
		let value = urlParams.get(param);
		if (!testRegex(value, /<(.*)>/)) {
			let option = select.querySelector("option[value='" + value + "']");
			if (isNotEmpty(option)) {
				option.setAttribute("selected", "");
				select.dispatchEvent(new Event("change"));
			}
		}
	}
}

function setSelectedParameterForSpecificSearch() {
	let select =jQuery("#select-theme");
	if (select[0] === undefined){
		return;
	}

	let urlParams = new URLSearchParams(window.location.search);
	let temas = urlParams.getAll('theme');
	let temasParseados =[];
	for ( let i=0 ;i< temas.length; i++) {
		if (!testRegex(temas[i], /<(.*)>/)) {
			temasParseados.push(temas[i]);
		}
	}

	if (temasParseados.length > 0) {
		select.val(temasParseados);
	}
	select.trigger('change');
	updatePreviousAndNextButtonsParametersOnLoad();
}

function setSelectedParameterForGenericSearch() {
	let select =jQuery("#generic-search");
	if (select[0] === undefined){
		return;
	}

	let urlParams = new URLSearchParams(window.location.search);
	let temas = urlParams.getAll('theme');
	let temasParseados =[];
	for ( let i=0 ;i< temas.length; i++) {
		if (!testRegex(temas[i], /<(.*)>/)) {
			temasParseados.push(temas[i]);
		}
	}

	if (temasParseados.length > 0) {
		select.val(temasParseados);
	}
	select.trigger('change');
}


function setSelectedParameterForSelect2(id, param) {
	let select = jQuery('#' + id).data('select2');
	let urlParams = new URLSearchParams(window.location.search);
	let temas = urlParams.getAll('theme');
	let temasParseados =[];
	for ( let i=0 ;i< temas.length; i++) {
		if (!testRegex(temas[i], /<(.*)>/)) {
			temasParseados.push(temas[i]);
		}
	}

	if (temasParseados.length > 0) {
		select.val(temasParseados);
	}

	select.trigger('change');
}


function setTextInputParameter(id,param){
	let target = document.getElementById(id);
	let urlParams = new URLSearchParams(window.location.search);
	if(target != null && typeof target !== 'undefined' && urlParams.has(param)) {
		if(isEncoded(urlParams.get(param))){
			target.value = decodeURIComponent(urlParams.get(param));
		}else{
			target.value = urlParams.get(param);
		}
	}
}


function setInputParameter(id, param) {
	let urlParams = new URLSearchParams(window.location.search);
	let input = document.getElementById(id);

	if (input !== undefined && urlParams.has(param)) {
		let value = urlParams.get(param);
		if (testRegex(value, /\d{6}/)) {
			input.value = [value.substring(0, 2), value.substring(2)].join("/");
		}
	}
}

function filterList(event) {
	event.preventDefault();
	let correctDate =  validateDate(true);
	if (correctDate) {
		if (document.readyState === 'complete'){
            getListObjects(null, event);
		}
	}
}

function filterListSanciones(event) {
	event.preventDefault();
	let correctDate =  validateDate(false);
	if (correctDate) {
		if (document.readyState === 'complete'){							  
			if (typeof filter === 'function') {filter();}
		}
	} else {
		jQuery('p.input-error-message').show();
	}
}

function clearFilterListSanciones(event) {
	event.preventDefault();
	$('select.select2-hidden-accessible').each(function() {
	    $(this).val(null).trigger('change.select2');
    });
	if (jQuery('#input_search_sanciones').length > 0 && jQuery('#input_search_sanciones').val().length > 0) {
		jQuery('#input_search_sanciones').val("");
	}
	if (jQuery('#datarangef1').length > 0 && jQuery('#datarangef1').val().length > 0) {
		$("#datarangef1").val("");
	}
	if (jQuery('#datarangef2').length > 0 && jQuery('#datarangef2').val().length > 0) {
		$("#datarangef2").val("");
	}
	if (typeof filter === 'function') {filter();}
}

function getListObjectsSanciones(element, event) {
	event.preventDefault();
	if (jQuery('#input_search_sanciones').length > 0 && jQuery('#input_search_sanciones').val().length > 0){
        if(!inputSearchLengthIsGreaterThan3Sanciones()){ return false; } //menos de 3 caracteres, no hace nada
        setTermParameterSanciones();
        if (valDatesAreDifferentSanciones()){ 
        	//actualizar parámetros y refrescar la página
        	updateWindowHistoryParametersSanciones();
        	window.location = window.location.pathname + (window.location.pathname.substr(-1) != "/" ? "/?" : "?") + getParametersSanciones(element);
        }else{
        	//no refresco la página y hago la búsqueda del término
        	  performSpecificSearchEngineSanciones();
        }
    }else{
    	 window.location = window.location.pathname + (window.location.pathname.substr(-1) != "/" ? "/?" : "?") + getParametersSanciones(element);
    }
}


function showResultsNumber(){
	jQuery("#results-number").css('display','block');
}

function showSelectForm(){
	jQuery("div.select-form").css('display','block');
}

function printResultsNumber(totalResults){
	jQuery("#results-number").text(totalResults + resultadosEncontrados.get(langPage));
}

function detachBlockPagination(){
	jQuery(".block-pagination").detach();
}

function cleanupBlockSearchContent(){
	jQuery(".block-search-content").html(" ");
}

function setURLParameters(){

	//buscador general
	setInputParameter("datarangef1", "start");
	setInputParameter("datarangef2", "end");
	setTextInputParameter("input_search","A1");

	//listado de resultados
	setTextInputParameter("datarangef1","datarange1");
	setTextInputParameter("datarangef1","datarange2");
	setTextInputParameter("search","A1");

	setSelectedParameter("select-author", "author");
	setSelectedParameter("select-role", "role");
	setSelectedParameter("select-order", "sort");
    setSelectedParameter("select-type", "type");
}


function setURLParametersSanciones(){
	setInputParameter("datarangef1", "start");
    setInputParameter("datarangef2", "end");
	setInputParameter("input_search_sanciones","ptext");
}

function onLoadSpecificSearchEngine(){
	if ((document.querySelectorAll("div.block-search-header__filters").length > 0) || (document.querySelectorAll("div.block-sidebar-filter__accordion").length > 0 )){
		setURLParameters();
	
		$("#select-order").on("select2:select", (event) => {
			let selectedOrder = $('#select-order').find(':selected')[0].value;
			if (selectedOrder != relevanceParameter) {
				params.delete(relevanceParameter);
			}
			else {
				params.set(relevanceParameter,relevanceParameter);
			}
			filterList(event);
		});
		
		if (Array.from(params).length > 0){
			scrollIntoViewListResults();
		}
	}
	// Limpiamos el campo de texto del buscador del Blog para que no aparezca el * tras una búsqueda
	if ($('#form-blog').length > 0 && ($('#input_search')[0].value == '*' || $('#input_search')[0].value == 'null')) {
		$("#input_search")[0].value = "";
	}
	updatePreviousAndNextButtonsParametersOnLoad();
}

function updatePreviousAndNextButtonsParametersOnLoad(){

	var urlParams = new URLSearchParams(getParameters());
	jQuery(".block-pagination__numbers a[href]").each(function (){
		var pageButtonParam = new URLSearchParams($(this).attr("href")).get(pageParameter);
		if(pageButtonParam != null && pageButtonParam !== 'undefined'){
			urlParams.set(pageParameter, pageButtonParam);
			$(this).attr("href", "?" + urlParams.toString());
		}
	});

}

function scrollIntoViewListResults(){
	if(isNotEmpty(document.getElementById("list-results"))){
		document.getElementById("list-results").parentElement.scrollIntoView();
	}else if (params.get(pageParameter) != null && isNotEmpty(document.getElementById("form-blog"))){
		document.getElementById("form-blog").parentElement.scrollIntoView();
	}
}

function hideSpinner(){
	if(jQuery(".block-spiner").is(':visible') === true){
		toggleLoading();
	}
}

function showOrganigramaBanco(){
	$divOrg=jQuery('#orgBanco');	
	if( $divOrg.length ){
		$svg=jQuery('#contenedorSvg1');
		$urlSVG=jQuery('#urlSVG');
		$urlSVG.hide();
		$divOrg.css('text-align', 'center');
		$svg.show();
		if($svg.attr('height')){
			$svg.removeAttr('height');
		}
	}	
}

function addEventClickWemPaginationLink(){
    jQuery(document).on('click', '.block-pagination__numbers a', function(event){
    	var href = jQuery(this).attr("href");
    	if (href == null || href == ''){
			event.preventDefault();
	        getListObjects(this, event);
		}
    });
}

window.onload = () => {
	onLoadSpecificSearchEngine();
	showOrganigramaBanco();
	addEventClickWemPaginationLink();
};

$(document).ready(function(){
	$('a').click(function(){
		if( $('.block-entry-content__list-document-download').length > 0 ){
			$('.block-entry-content__list-document-download').removeClass('focus');
		}
	});
	$('.block-entry-content__list-document__types a').click(function(){
		$(this).parent().addClass('hide-element');
		$(this).parents().eq(1).find('.block-entry-content__list-document-download').focus().addClass('focus');
	});
	
});

$(document).ready(function(){

    $('.block-entry-content__list-document__types p').click(function(){

        $(this).parent().addClass('hide-element');

    });

});

window.addEventListener('load', () => {
	function getInitialMap(images) {
		let mapIndex = 0;
		images.forEach((image, index) => {
			if (image.hasAttribute('id')) {
				mapIndex = index;
				image.classList.add('currentMap');
			}
		})
		return mapIndex;
	}
	function getCurrentMapIndex(images) {
		let mapIndex = 0;
		images.forEach((image, index) => {
			if (image.classList.contains('currentMap')) {
				mapIndex = index;
			}
		})
		return mapIndex;
	}
	function hideMap(map) {
		map.style.display = 'none';
		map.classList.remove('currentMap');
	}
	function showMap(map) {
		map.style.display = 'inline';
		map.classList.add('currentMap');
	}
	function navigateToMap(mapList, currentMap, toMap) {
		hideMap(mapList.item(currentMap));
		showMap(mapList.item(toMap));
	}
	function activeNavigationControlMap(control) {
		control.removeAttribute('disabled');
	}
	function disableNavigationControlMap(control) {
		control.setAttribute('disabled', 'disabled');
	}
	function updateNavigationMap(currentMap, mapLength) {
		let mapa = document.querySelector('#divMapa');

		if (!mapa) {
			return;
		}
		let controlPrev = mapa.querySelector('#anterior');
		let controlNext = mapa.querySelector('#siguiente');
		
		activeNavigationControlMap(controlPrev);
		activeNavigationControlMap(controlNext);
		if (currentMap + 1 === mapLength) {
			disableNavigationControlMap(controlNext);
		}
		if (currentMap === 0) {
			disableNavigationControlMap(controlPrev);
		}
	}
	function initInteractiveMap() {
		let mapa = document.querySelector('#divMapa');

		if (!mapa) {
			return;
		}
		let controlPrev = mapa.querySelector('#anterior');
		let controlNext = mapa.querySelector('#siguiente');
		if (!controlPrev || !controlNext) {
			return;
		}
		
		let images = mapa.querySelectorAll('img');
		let currentMap = getInitialMap(images);
		
		updateNavigationMap(currentMap, images.length);
		
		controlNext.addEventListener('click', () => {
			currentMap = getCurrentMapIndex(images);
			navigateToMap(images, currentMap, currentMap + 1);
			updateNavigationMap(currentMap + 1, images.length);
		})
		
		controlPrev.addEventListener('click', () => {
			currentMap = getCurrentMapIndex(images);
			navigateToMap(images, currentMap, currentMap - 1);
			updateNavigationMap(currentMap - 1, images.length);
		})
	}
	initInteractiveMap();
});