let dateFilterFieldQueryMap = new Map([
	['FECHAMODI', 'custom_fields.fechaModificacion'],
	['FECHA-CREACION', 'custom_fields.FechaCreacion']
]);

var themeCategoryArray = [];

/* Declaracion Variables idiomas */

let monthNamesES = new Map([
	[1, 'ENERO'],
	[2, 'FEBRERO'],
	[3, 'MARZO'],
	[4, 'ABRIL'],
	[5, 'MAYO'],
	[6, 'JUNIO'],
    [7, 'JULIO'],
    [8, 'AGOSTO'],
	[9, 'SEPTIEMBRE'],
	[10, 'OCTUBRE'],
	[11, 'NOVIEMBRE'],
	[12, 'DICIEMBRE']
]);

let monthNamesEN = new Map([
	[1, 'JANUARY'],
	[2, 'FEBRUARY'],
	[3, 'MARCH'],
	[4, 'APRIL'],
	[5, 'MAY'],
	[6, 'JUNE'],
    [7, 'JULY'],
    [8, 'AUGUST'],
	[9, 'SEPTEMBER'],
	[10, 'OCTOBER'],
	[11, 'NOVEMBER'],
	[12, 'DECEMBER']
]);

let monthNameMap = new Map([
	['es', monthNamesES],
	['en', monthNamesEN],
]);

let encontradoBuscado = new Map([
	['es', '¿Has encontrado lo que buscabas? '],
	['en', 'Did you find what you were looking for? '],
	['ca', 'Has trobat el que buscaves?'],
	['va', 'Has trobat el que buscaves? '],
	['ga', 'Atopaches o que buscabas? '],
	['eu', 'Bilatzen ari zenuena aurkitu duzu? ']

]);

let infoUtil = new Map([
	['es', 'Marcar la información como útil '],
	['en', 'Mark information as useful '],
	['ca', 'Marcar la informació com a útil '],
	['va', 'Marcar la informació com a útil '],
	['ga', 'Marcar a información como útil '],
	['eu', 'Informazioa erabilgarria izan dela markatu ']

]);

let infoPocoUtil = new Map([
	['es', 'Marcar la información como poco útil '],
	['en', 'Mark the information as not very useful '],
	['ca', 'Marcar la informació com a poc útil '],
	['va', 'Marcar la informació com a poc útil'],
	['ga', 'Marcar a información como pouco útil '],
	['eu', 'Informazioa oso erabilgarria ez dela izan markatu ']

]);

let valoracionRegistrada = new Map([
	['es', 'Hemos registrado su valoración, muchas gracias por su participación. '],
	['en', 'We have registered your rating, thank you very much for your participation. '],
	['ca', 'Hem registrat la valoració, moltes gràcies per la vostra participació. '],
	['va', 'Hem registrat la valoració, moltes gràcies per la vostra participació.  '],
	['ga', 'Rexistramos a súa valoración, moitas gracias pola súa participación '],
	['eu', 'Zure iritzia erregistratu dugu; eskerrik asko parte hartzeagatik. ']

]);

let de = new Map([
	['es', 'de'],
	['en', 'of'],
	['ca', 'de'],
	['va', 'de'],
	['ga', 'de'],
	['eu', 'de']
]);

let ordenadoPor = new Map([
	['es', 'Ordenar por '],
	['en', 'Sort by '],
	['ca', 'Ordenar per '],
	['va', 'Ordenar per '],
	['ga', 'Ordenar por '],
	['eu', 'Honako honen arabera antolatu: ']

]);

let porRelevancia = new Map([
	['es', 'Por relevancia '],
	['en', 'By relevance '],
	['ca', 'Per rellevància '],
	['va', 'Per rellevància '],
	['ga', 'Por relevancia '],
	['eu', 'Garrantziaren arabera ']
]);

let coincidencia = new Map([
	['es', 'Coincidencia '],
	['en', 'Match '],
	['ca', 'Coincidència '],
	['va', 'Coincidència '],
	['ga', 'Coincidencia '],
	['eu', 'Kointzidentzia ']

]);

let fecha = new Map([
	['es', 'Fecha '],
	['en', 'Date '],
	['ca', 'Data '],
	['va', 'Data '],
	['ga', 'Data '],
	['eu', 'Data ']

]);


let pagina = new Map([
	['es', 'Página'],
	['en', 'Page'],
	['ca', 'Pàgina'],
	['va', 'Pàgina'],
	['ga', 'Páx'],
	['eu', 'Orria']

]);

let siguiente = new Map([
	['es', 'Siguiente'],
	['en', 'Next'],
	['ca', 'Seguint'],
	['va', 'Seguint'],
	['ga', 'Seguinte'],
	['eu', 'Hurrengoa']

]);

let anterior = new Map([
	['es', 'Anterior '],
	['en', 'Previous '],
	['ca', 'Anterior '],
	['va', 'Anterior '],
	['ga', 'Anterior '],
	['eu', 'Aurrekoa ']

]);

let primera_pagina = new Map([
	['es', 'Primera página'],
	['en', 'First page'],
	['ca', 'Primera pàgina'],
	['va', 'Primera pàgina'],
	['ga', 'Primeira páxina'],
	['eu', 'Lehen orrialdea']

]);

let ultima_pagina = new Map([
	['es', 'Última página'],
	['en', 'Last page'],
	['ca', 'Darrera pàgina'],
	['va', 'Darrera pàgina '],
	['ga', 'Última páxina'],
	['eu', 'azken orrialdea']

]);

let busqueda = new Map([
	['es', 'Búsqueda '],
	['en', 'Search '],
	['ca', 'Cerca '],
	['va', 'Cerca '],
	['ga', 'Busca '],
	['eu', 'Bilaketa ']

]);

let resultadoDe = new Map([
	['es', 'Resultados de '],
	['en', 'Results of '],
	['ca', 'Resultats de '],
	['va', 'Resultats de '],
	['ga', 'Resultados de '],
	['eu', 'Honako honen emaitzak ']

]);

let resultadoNoEncontrado = new Map([
	['es', '<p class="block-message__text error">No se encontraron resultados.<br>Asegúrese de que todos los términos están escritos correctamente o intente usar términos más generales.</p>'],
	['en', '<p class="block-message__text error">No results found.<br>Make sure that all terms are written correctly or try using more general terms.</p>'],
	['ca', '<p class="block-message__text error">No s\'han trobat resultats.<br>Assegureu-vos que tots els termes estan escrits correctament o intenteu emprar termes més generals.</p>'],
	['va', '<p class="block-message__text error">No s\'han trobat resultats.<br>Assegureu-vos que tots els termes estan escrits correctament o intenteu utilitzar termes més generals.</p>'],
	['ga', '<p class="block-message__text error">Non se atoparon resultados.<br>Asegúrese de que todos os termos están escritos correctamente ou intente usar termos máis xerais.</p>'],
	['eu', '<p class="block-message__text error">Ez da emaitzarik aurkitu.<br>Egiaztatu hitz guztiak behar bezala idatzi egin direla edo saiatu hitz orokorragoak erabiltzen.</p>']

]);

let servicioNoDisponible = new Map([
	['es', '<p class="block-message__text error">No se encontraron resultados.<br>El servicio de búsqueda no se encuentra disponible en estos momentos. Por favor pruebe pasados unos minutos.</p>'],
	['en', '<p class="block-message__text error">No results found.<br>The search tool is not available at the moment, please try again in few minutes.</p>'],
	['ca', '<p class="block-message__text error">No s\'han trobat resultats.<br>El servei de cerca no estÃ  disponible en aquests moments. Espereu uns minuts i torneu-ho a intentar.</p>'],
	['va', '<p class="block-message__text error">No s\'han trobat resultats.<br>El servei de cerca no estÃ  disponible en aquests moments. Espereu uns minuts i torneu-ho a intentar.</p>'],
	['ga', '<p class="block-message__text error">Non se atoparon resultados.<br>O servizo de busca non se atopa dispoñible nestes momentos. Por favor probe pasados uns minutos.</p>'],
	['eu', '<p class="block-message__text error">Ez da emaitzarik aurkitu.<br>Bilatzeko zerbitzua ez dago orain eskuragai. Saiatu berriro geroago, mesedez.</p>']

]);

let resultadosPorPagina = new Map([
	['es', 'resultados por página'],
	['en', 'results per page'],
	['ca', 'resultats per pàgina '],
	['va', 'resultats per pàgina'],
	['ga', 'resultados por páxina'],
	['eu', 'orrialde bakoitzeko emaitzak']                           
]);

let textoCorto = new Map([
	['es', 'El texto de búsqueda es demasiado corto.'],
	['en', 'The typed text is too short.'],
	['ca', 'El text de cerca és massa curt.'],
	['va', 'El text de cerca és massa curt.'],
	['ga', 'O texto da busca é demasiado curto.'],
	['eu', 'Bilaketaren testua laburregia da.']                           
]);	

let resultadosEncontrados = new Map([
  	['es', ' resultados encontrados para los filtros seleccionados.'],
  	['en', ' results found for selected filters.'],
  	['ca', ' resultats trobats per als filtres seleccionats.'],
  	['va', ' resultats trobats per als filtres seleccionats.'],
  	['ga', ' resultados atopados para os filtros seleccionados.'],
  	['eu', ' hautatutako iragazkietarako emaitzak aurkitu dira.']                           
]);	

let fechaInicioSuperiorFechaFinal = new Map([
	['es', 'La fecha de inicio no puede ser posterior a la fecha final.'],
	['en', 'The start date cannot be later than the end date.'],
	['ca', 'La data inicial no pot ser posterior a la data final.'],
	['va', 'La data inicial no pot ser posterior a la data final.'],
	['ga', 'A data de inicio non pode ser posterior á data de finalización.'],
	['eu', 'Hasiera-data ezin da izan amaiera-data baino geroagokoa.']                           
]);

let previewIsNotAllowed = new Map([
	['es', 'El buscador de términos en los listados solo está activado en el portal publicado.'],
	['en', 'The search engine for terms in the listed list is only activated in the published portal.'],
	['ca', 'El cercador de termes de la llista enumerada només s\'activa al portal publicat.'],
	['va', 'El cercador de termes de la llista enumerada només s\'activa al portal publicat.'],
	['ga', 'O buscador de termos da lista listada só está activado no portal publicado.'],
	['eu', 'Zerrendako zerrendako terminoen bilatzailea argitaratutako atarian bakarrik aktibatzen da.']
]);

let convocatoriaMapES = new Map([
	['TEMPORAL', 'TEMPORAL'],
	['GENERAL', 'FIJO'],
	['BECA', 'BECA'],
	['CONSULTA', 'CONSULTA'],
 	['RESEARCH', 'RESEARCH'],
 	[null, 'CONSULTA']
]);

let convocatoriaMapEN = new Map([
 	['TEMPORAL', 'TEMPORARY'],
	['GENERAL', 'PERMANENT'],
	['BECA', 'SCHOLARSHIP'],
	['CONSULTA', 'ENQUIRIES'],
 	['RESEARCH', 'RESEARCH'],
 	[null, 'ENQUIRIES']
]);

let convocatoriaMap = new Map([
	['es', convocatoriaMapES],
	['en', convocatoriaMapEN],
]);

let promotionsText = new Map([
	['es', 'LE PUEDE INTERESAR'],
	['en', 'YOU ALSO MIGHT LIKE'],
	['ca', 'LE PUEDE INTERESAR'],
	['va', 'LE PUEDE INTERESAR'],
	['ga', 'LE PUEDE INTERESAR'],
	['eu', 'LE PUEDE INTERESAR']
]);

let themePlaceholder = new Map([
	['es', 'Tema'],
	['en', 'Topic'],
	['ca', 'Tema'],
	['va', 'Tema'],
	['ga', 'Tema'],
	['eu', 'Gaia']                     
]);

let selectThemePlaceholder = new Map([
	['es', 'Seleccionar tema'],
	['en', 'Select topic'],
	['ca', 'Seleccionar tema'],
	['va', 'Seleccionar tema'],
	['ga', 'Seleccionar tema'],
	['eu', 'Hautatu gaia']                     
]);

let cargoPlaceholder = new Map([
	['es', 'Cargo'],
	['en', 'Position'],
	['ca', 'Càrrec'],
	['va', 'Càrrec'],
	['ga', 'Posición'],
	['eu', 'Kargua']                     
]);

let borrarPlaceholder = new Map([
	['es', 'Borrar'],
	['en', 'Clear'],
	['ca', 'Esborrar'],
	['va', 'Esborrar'],
	['ga', 'Borrar'],
	['eu', 'Garbitu']                     
]);

let puestoConvocatorias = new Map([
	['es', 'Puesto'],
	['en', 'Position'],
	['ca', 'Càrrec'],
	['va', 'Càrrec'],
	['ga', 'Posición'],
	['eu', 'Kargua']                     
]);

let FechaActualizacionConvocatorias = new Map([
	['es', 'Fecha de actualización'],
	['en', 'Update date'],
	['ca', 'Data d\'actualització'],
	['va', 'Data d\'actualització'],
	['ga', 'Data de actualización'],
	['eu', 'Eguneratu data']                     
]);


let numAnuncioConvocatorias = new Map([
	['es', 'Nº de anuncio'],
	['en', 'Number of announcement'],
	['ca', 'Número d\'anunci'],
	['va', 'Número d\'anunci'],
	['ga', 'Nº de anuncio'],
	['eu', 'iragarki zenbakia']                     
]);


let tipoEmpleoConvocatorias = new Map([
	['es', 'Tipo de empleo'],
	['en', 'Type of employment'],
	['ca', 'Tipus d\'ocupació'],
	['va', 'Tipus d\'ocupació'],
	['ga', 'Tipo de traballo'],
	['eu', 'lan mota']                     
]);


let serieBlog = new Map([
	['es', 'Serie'],
	['en', 'Series'],
	['ca', 'Sèrie'],
	['va', 'Sèrie'],
	['ga', 'Serie'],
	['eu', 'Seriea']               
]);


let serieBlogPlural = new Map([
	['es', 'Series'],
	['en', 'Series'],
	['ca', 'Sèries'],
	['va', 'Sèries'],
	['ga', 'Series'],
	['eu', 'Serieak']                 
]);

const publicApiKeyAnte = '539b17b42d07c7291b3c799dca088fd5'; /* API_KEY ANTE */
const publicApiKeyProd = 'c9cf5c5c36a9137604b3902ac2f80309'; /*
																 * API_KEY
																 * Produccion
																 */
const publicApiKeyProdWebbe = 'f134a5b85750f34f4515326e468f5174'; /*
																	 * API_KEY
																	 * Produccion
																	 * nuevo
																	 * webbe
																	 */

/* Eliminar a futuro la opción PROD-OLD */
let addSearchKey = new Map([
    ['undefined', publicApiKeyAnte],
    ['TEST', publicApiKeyAnte],
    ['ANTE', publicApiKeyAnte],
    ['PROD-OLD', publicApiKeyProd],
    ['PROD', publicApiKeyProdWebbe]
]);

const termParameter = "A1";
const termParameterSanciones = "ptext";
const sortParameter = "sort";
const fuzzyParameter = "fuzzy";
const langParameter = "lang";
const pageParameter = "page";
const limitParameter = "limit";
const collectAnalyticsParameter ="collectAnalytics";
const relevanceParameter = "relevance";
const postfixWildcardParameter = "postfixWildcard"; /*
													 * set it to false if you
													 * want to emphasise exact
													 * matches, you get less
													 * results and better
													 * highlights
													 */
const startDateTimeParameter = "start";	/* changed from FechaDesde to start */
const endDateTimeParameter = "end";	/* changed from FechaHasta to end */	
const roleParameter = "role";	/* changed from Cargo to role */
const authorParameter = "author";	/* changed from Autor to author */
const themeParameter = "theme";		/* changed from Tema to theme */
const typeParameter = "type";  /* needed for convocatorias section */
const contentTypeInstanceParameter ="contentTypeInstances"; /*
															 * needed for
															 * convocatorias
															 * section
															 */
const limitDateCerradasParameter ="limitDateCerradasParameter"; /*
																 * needed for
																 * convocatorias
																 * section
																 */
const limitDateAbiertasParameter ="limitDateAbiertasParameter"; /*
																 * needed for
																 * convocatorias
																 * section
																 */

const channelParameter = "Channel";

const prefixAddSearchUrl = 'https://api.addsearch.com/v1/search/'+addSearchKey.get(env);
const urlCategoriesJSON ="/f/webbe/Sistema/JSON/themeSubthemeCategories.json";
// Variable para almacenar la promesa de la llamada AJAX para themeCategories.json
var ajaxJsonPromise = null;

var client = new AddSearchClient(addSearchKey.get(env));	

var params = new URLSearchParams(window.location.search);

var thereIsPromotion = false;

var tryCounter = 0;

var forceAddSearch = false;

let imagenDefaultAddsearch = '';
if (document.getElementById("imagen-default-addsearch") != null){
	imagenDefaultAddsearch = document.getElementById("imagen-default-addsearch").value;
}


if(langPage === 'ca-valencia'){ langPage = 'va'; }

function isEncoded(uri) {
  try{
	 var regex = new RegExp("%([a-zA-Z0-9]{2})");
	 return regex.test(uri);  
  }catch(ex){
	 console.debug('Error in isEncoded function') ;
	 return false;
  }
}

function getTerm(){
	return params.get(termParameter);
}

function getTermSanciones(){
	return isEncoded(params.get(termParameterSanciones)) ? decodeURIComponent(params.get(termParameterSanciones)) : params.get(termParameterSanciones);
}

function thereAreMoreParams(typeParam){
	let result = false;
	if (typeParam === "theme"){
		if (params.has(channelParameter) || params.has(authorParameter) || params.has(roleParameter) 
         || params.has(typeParameter) || params.has(contentTypeInstanceParameter) || params.has(startDateTimeParameter) 
         || params.has(endDateTimeParameter)){

			 result = true;
		}

	} else if (typeParam === "channel"){
		if(params.has(authorParameter) || params.has(roleParameter) || params.has(typeParameter) 
				|| params.has(contentTypeInstanceParameter)|| params.has(startDateTimeParameter) || params.has(endDateTimeParameter)){
			
			result = true;
		}

	} else if (typeParam === "type") {
			if (params.has(contentTypeInstanceParameter) || params.has(startDateTimeParameter) || params.has(endDateTimeParameter)){
				result = true;
			}
	} else if (typeParam === "contentTypeInstances") {
			if(params.has(limitDateCerradasParameter) || params.has(limitDateAbiertasParameter) || params.has(startDateTimeParameter) || params.has(endDateTimeParameter)){
				result = true;
			}
	} else if (typeParam === "limitDateParameter") {
			if(params.has(startDateTimeParameter) || params.has(endDateTimeParameter)){
				result = true;
			}
}
	
	return result;
}

function getEndQuery(typeParam){
	let result = "]}";
	if (typeParam === "limitDateParameter" && thereAreMoreParams(typeParam)) {
		result =  "},";
	}else if (typeParam === "limitDateParameter" && !thereAreMoreParams(typeParam)) {
		result =  "}";
	}else if (typeParam !== "limitDateParameter" && thereAreMoreParams(typeParam)) {
		result =  "]},";
	}
	return result;
}

/*
 * Funcion que recoge todos los parametros de la URL y los setea en la variable
 * usada como QueryString para hacer las llamadas AJAX.
 */
async function createQueryStringParams() {
	var queryStringCustomFieldArray = [];
	var queryStringCustomFieldArrayTheme = [];
	var queryStringCustomFieldArrayChannel = [];
	var queryStringCustomField = [];

	/*
	 * Decisiones para añadir los custom_fields de
	 * Canal-Tema-Autor-Cargo-FechaDesde-FechaHasta en el formato JSON que
	 * AddSearch soporta.
	 */
	queryStringCustomField = "&filter={\"and\":[";

	if (params.has(themeParameter)) {
		queryStringCustomField += "{\"or\":[";
		// Si no ha dado tiempo a cargar los temas, esperamos a que llegen ya que es necesario
		if (themeCategoryArray.length == 0) {
			themeCategoryArray = await getCategoriesJSON();
		}
		let themeSubthemeArr = params.getAll(themeParameter);
		if ($("#select-theme").length > 0) {
			let childs = $("#select-theme option:selected").attr('childs');
			if (childs != null && childs != '') {
				jQuery.each(childs.split('-'), function (index, value) {
					themeSubthemeArr.push(value);
				});
			}
		}
		// Convertimos el Array a Set para omitir posibles duplicados
		themeSubthemeArr = Array.from(new Set(themeSubthemeArr));
		jQuery.each(themeSubthemeArr, function (index, value) {
			if (value.indexOf("RCRD") > -1) {
				var jsonValue;
				jsonValue = getJSONSubArray(themeCategoryArray, 'themesAndSubthemes').find(item => item.themeCategoryId == value);
				value = isNotEmpty(jsonValue) ? jsonValue.cod : value;
			}
			queryStringCustomFieldArrayTheme.push("{\"custom_fields.Tema\":\"" + value + "\"}");
		});
		queryStringCustomField += queryStringCustomFieldArrayTheme.join(',');
		queryStringCustomField += getEndQuery("theme");
	}
	if (params.has(channelParameter)) {
		if (typeof customChannelMeta !== 'undefined' && isNotEmpty(customChannelMeta)) {
			var arrayChannels = customChannelMeta.split(';');
			queryStringCustomField += "{\"or\":[";
			jQuery.each(arrayChannels, function (index, value) {
				queryStringCustomFieldArrayChannel.push("{\"custom_fields.Channel\":\"" + value + "\"}");
			});
			queryStringCustomField += queryStringCustomFieldArrayChannel.join(',');
			queryStringCustomField += getEndQuery("channel");
		} else {
			// El buscador de anuncios de estadísticas tiene una casuística especial por la cual
			// es necesario inyectar los canales manualmente, la variable customChannelRCRD nos
			// llega parametriada desde la DV
			const currentUrl = window.location.href;
			// Verificamos si nos llega parámetro contribuido
			if (typeof customChannelRCRD !== 'undefined' && customChannelRCRD != '') {
				var customChannelRCRDarr = customChannelRCRD.split(';');
				queryStringCustomField += "{\"or\":[";
				jQuery.each(customChannelRCRDarr, function (index, value) {
					queryStringCustomFieldArrayChannel.push("{\"custom_fields.Channel\":\"" + value + "\"}");
				});
				queryStringCustomField += queryStringCustomFieldArrayChannel.join(',');
				queryStringCustomField += getEndQuery("channel");
			} else {
				queryStringCustomField += "{\"or\":[";
				jQuery.each(params.getAll(channelParameter), function (index, value) {
					queryStringCustomFieldArrayChannel.push("{\"custom_fields.Channel\":\"" + value + "\"}");
				});
				queryStringCustomField += queryStringCustomFieldArrayChannel.join(',');
				queryStringCustomField += getEndQuery("channel");
			}
		}
	}
	if (params.has(authorParameter)) {
		jQuery.each(params.getAll(authorParameter), function (index, value) {
			queryStringCustomFieldArray.push("{\"custom_fields.Autor\":\"" + value + "\"}");
		});
	}
	if (params.has(roleParameter)) {
		jQuery.each(params.getAll(roleParameter), function (index, value) {
			queryStringCustomFieldArray.push("{\"custom_fields.Cargo\":\"" + value + "\"}");
		});
	}
	// Necesario para las convocatorias
	queryStringCustomField = createQueryStringParamsConvocatorias(queryStringCustomField);

	// Necesario para solo recoger las plantillas informativas del blog
	if ($('#form-blog').length > 0) {
		let queryStringCustomFieldBlogContentType = "{\"or\":[{\"custom_fields.tipoContenido\":\"BDE_PLANTILLA_INF_MULTIIDIOMA\"}]}";
		if (!queryStringCustomField.endsWith(','))
			queryStringCustomFieldBlogContentType = "," + queryStringCustomFieldBlogContentType;
		queryStringCustomFieldArray.push(queryStringCustomFieldBlogContentType);
	}

	var dateCustomField = dateFilterFieldQueryMap.get("FECHAMODI");
	if (isEventBlock()) {
		dateCustomField = dateFilterFieldQueryMap.get("FECHA-CREACION");
	}
	if (params.has(startDateTimeParameter) && params.has(endDateTimeParameter)) {
		queryStringCustomFieldArray.push("{\"range\":{\"" + dateCustomField + "\":{\"gt\":\"" + getLastDayOfPrevMonth(params.get(startDateTimeParameter)) + "\",\"lt\":\"" + getFirstDayOfNextMonth(params.get(endDateTimeParameter)) + "\"}}}");
	} else if (!params.has(startDateTimeParameter) && params.has(endDateTimeParameter)) {
		queryStringCustomFieldArray.push("{\"range\":{\"" + dateCustomField + "\":{\"lt\":\"" + getFirstDayOfNextMonth(params.get(endDateTimeParameter)) + "\"}}}");
	} else if (params.has(startDateTimeParameter) && !params.has(endDateTimeParameter)) {
		queryStringCustomFieldArray.push("{\"range\":{\"" + dateCustomField + "\":{\"gt\":\"" + getLastDayOfPrevMonth(params.get(startDateTimeParameter)) + "\"}}}");
	}
	queryStringCustomField += queryStringCustomFieldArray.join(',');
	/* Para cerrar el filtro de custom_fields */
	if (queryStringCustomFieldArray.length > 0 || queryStringCustomFieldArrayTheme.length > 0 || queryStringCustomFieldArrayChannel.length > 0) {
		queryStringCustomField += "]}";
	}
	else {
		queryStringCustomField = null;
	}
	return queryStringCustomField;
}

function createQueryStringParamsConvocatorias(queryStringCustomField){
	let queryStringCustomFieldConvocatoriaContentType = [];
	let queryStringCustomFieldConvocatoriaFechaLimite = [];
	let queryStringCustomFieldArrayType = [];
	
	if (params.has(typeParameter)){
		queryStringCustomField += "{\"and\":[";
		jQuery.each(params.getAll(typeParameter),function(index,value){
			queryStringCustomFieldArrayType.push("{\"custom_fields.tipoConvocatoria\":\""+getConvocatoriaType(value)+"\"}");
		});
		queryStringCustomField += queryStringCustomFieldArrayType.join(',');
		queryStringCustomField += getEndQuery("type");
	}
	if (params.has(contentTypeInstanceParameter)){
		queryStringCustomField += "{\"and\":[";
		jQuery.each(params.getAll(contentTypeInstanceParameter),function(index,value){
			queryStringCustomFieldConvocatoriaContentType.push("{\"custom_fields.tipoContenido\":\""+value+"\"}");
		});
		queryStringCustomField += queryStringCustomFieldConvocatoriaContentType.join(',');
		queryStringCustomField += getEndQuery("contentTypeInstances");
	}
	if (params.has(limitDateCerradasParameter)){
		queryStringCustomField += "{\"range\":{\"";
		jQuery.each(params.getAll(limitDateCerradasParameter),function(index,value){
			queryStringCustomFieldConvocatoriaFechaLimite.push("\custom_fields.fechaLimite\":{\"lt\":\""+value+"\"}}");
		});
		queryStringCustomField += queryStringCustomFieldConvocatoriaFechaLimite.join(',');
		queryStringCustomField += getEndQuery("limitDateParameter");
	}	
	if (params.has(limitDateAbiertasParameter)){
		queryStringCustomField += "{\"range\":{\"";
		jQuery.each(params.getAll(limitDateAbiertasParameter),function(index,value){
			queryStringCustomFieldConvocatoriaFechaLimite.push("\custom_fields.fechaLimite\":{\"gt\":\""+value+"\"}}");
		});
		queryStringCustomField += queryStringCustomFieldConvocatoriaFechaLimite.join(',');
		queryStringCustomField += getEndQuery("limitDateParameter");
	}
	return queryStringCustomField;
}

/* Función para llamar a la API por AJAX para obtener el JSON de resultados. */
function callAjaxAddSearch(){
	return new Promise(async function(resolve, reject) {
		try{
			jQuery.ajax({
				type: "get",
				url: prefixAddSearchUrl,
				data: await getDataRequest(),
				beforeSend: function(){
					toggleLoading();
				},
				complete: function() {
					toggleLoading();
				},
				error: function(err){
					reject(err);
				},
				success:function(data){
					resolve(addStructuredBreadcrumb(data));
					if (Array.from(params).length > 0){
						scrollIntoViewListResults();
					}
					if(data) {
						let totalHits = data.total_hits;
						addEventSearchResultsSendStats(totalHits);	
					}
	
				}
				
			});
		}catch (e){
			console.error(e.message);
		}
	});
}


function checkGoogleAnalyticsScriptLoaded() {
	tryCounter++;
    if (typeof setEventsResults === 'function') {
		// Llamada a la función donde se agregan los eventos que se envían a GA
        setEventsResults(); 
		setEventsResultsPiwik();
    } else if (tryCounter <= 5){
        setTimeout(checkGoogleAnalyticsScriptLoaded, 100); // Intenta nuevamente después de 100ms
    } else {
		console.log('No se pudo obtener script de GA!');
	}
}

/* Calcula el numero total de paginas en funcion del total de resultados */
function calculateTotalPages(totalHits,totalPageCount){
	let result = 0;
	if (totalHits == 0) return result;
	if(Number.isInteger(totalHits/totalPageCount)){
		result = totalHits/totalPageCount;
	}else{
		result = Math.trunc(totalHits/totalPageCount)+1;
	}
	return result;
}

function renderPagination(totalHits, printPaginationFilter){
	var current_page = params.has(pageParameter) ? params.get(pageParameter) : 1;
	var limit = params.has(limitParameter) ? params.get(limitParameter) : 10;
	var max_page_buttons = 5;
	var total_pages = Math.ceil(totalHits / limit);
	var paginacionBloque="";
	if(total_pages > 0){
		if(current_page > total_pages){
			current_page = total_pages;
		}
		if(current_page < 1){
			current_page = 1;
		}
		//var total_blocks = Math.ceil(total_pages/max_page_buttons);
		var current_block = Math.ceil(current_page/max_page_buttons);
		var last_block = Math.ceil(total_pages/max_page_buttons);	
		if(current_block > last_block){
			current_block = last_block;
		}	
		var page_block_end = current_block * max_page_buttons;
		var page_block_start = page_block_end - (max_page_buttons-1);
	
		
		var resultsPerPage = resultadosPorPagina.get(langPage);

		paginacionBloque = `<div class="block-pagination">
			<div class="block-pagination__numbers">
				<ul>
					${renderButtonPrevious(parseInt(current_page), total_pages, max_page_buttons)}
					${renderButtonPages(page_block_start,page_block_end,current_page,total_pages)}
					${renderButtonNext(parseInt(current_page),total_pages, max_page_buttons)}
				</ul>
			</div>`;
			if(printPaginationFilter){
				paginacionBloque += `<div class="block-pagination__filter">
						<div class="select-form">
							<select id="select-pagination" aria-labelledby="select-pagination">
								<option value="10" ${selectedDropDown("10")}>10 ${resultsPerPage}</option>
								<option value="20" ${selectedDropDown("20")}>20 ${resultsPerPage}</option>
								<option value="30" ${selectedDropDown("30")}>30 ${resultsPerPage}</option>
							</select>
						</div>
					</div>`;
			}

		paginacionBloque += `</div>`;
	}

	return paginacionBloque;
}

/*
 * Funcion auxiliar para saber si la página actual es la pintada y añadir la
 * clase para la vision CSS
 */
function isCurrent(index){
	var current = "class=\"current\"";
	return params.get(pageParameter) == index ? current:'';
}

function generateURLPagination(page){
    // we save the current page
    var current_page = params.has(pageParameter) ? params.get(pageParameter) : 1;
    // we change the page param to be able to generate the new URL
    params.set(pageParameter, page);
    var url = window.location.pathname + "?" + params.toString();
    // we rollback the current_page param after generated the URL
    params.set(pageParameter, current_page);
    return url;
}


function renderButtonPrevious(current_page, total_pages, max_page_buttons){
	var buttonsPrevious = "";
	if(total_pages > max_page_buttons && current_page > 3){
		buttonsPrevious += `<li class="pag-flecha"><a href="${generateURLPagination(1)}"><span class="element-hide">${primera_pagina.get(langPage)}</span><em class="icon-chevron-first"> </em></a></li>`;
	}
	if(current_page > 1){
		buttonsPrevious += `<li class="pag-flecha"><a href="${generateURLPagination(current_page-1)}" rel="prev"><span class="element-hide">${anterior.get(langPage)}</span><em class="icon-chevron-left"> </em></a></li>`;
	}
	return buttonsPrevious;
}

function renderButtonPages(page_block_start,page_block_end,current_page,total_pages){
	var htmlPages = "";
	for (var i=page_block_start; i<=page_block_end; i++){
		var page_number = i;
		if(page_number<=total_pages){
			htmlPages += `<li ${isCurrent(page_number)}><a href="${generateURLPagination(page_number)}"><span class="element-hide">${pagina.get(langPage)}</span>${page_number}</a></li>`;
		}
	}
	return htmlPages;
}

function renderButtonNext(current_page,total_pages, max_page_buttons){
	let buttonsNext = "";
	if (current_page < total_pages){
		buttonsNext += `<li class="pag-flecha"><a href="${generateURLPagination(current_page+1)}" rel="next"><span class="element-hide">${siguiente.get(langPage)}</span><em class="icon-chevron-right"> </em></a></li>`;
	}
	if ((total_pages > max_page_buttons) && (current_page < total_pages)){
		buttonsNext += `<li class="pag-flecha"><a href="${generateURLPagination(total_pages)}"><span class="element-hide">${ultima_pagina.get(langPage)}</span><em class="icon-chevron-last"> </em></a></li>`;
	}
	return buttonsNext;
}


var callbackAutocomplete = function(res) {
    var autoComp = "";
    jQuery('#autocompleteBox').html('');
    if(res.suggestions.length > 0){
        var count = 0;
        autoComp += `<div class="block-search__result"> `;
        for(var i = 0; i < res.suggestions.length; ++i)
        {
        	if (count < 5){
        		autoComp += `<p class="block-search__result__item block-search__result__item--text">`;
                autoComp += `<a tabindex="${i}" class="element-${i}" href="#">${res.suggestions[i].value}</a>`;
                autoComp += `</p>`;
                count++;
        	}
        }
        autoComp += `</div>`;
        if(count > 0){
            jQuery('#autocompleteBox').html(autoComp);
        }
    }
}

var callbackAutocompleteHeader = function(res) {
    var autoComp = "";
    jQuery('#autocompleteBoxHeader').html('');
    if(res.suggestions.length > 0){
        var count = 0;
        autoComp += `<div class="main-header__search__result"> `;
        for(var i = 0; i < res.suggestions.length; ++i)
        {
        	if (count < 5){
        		autoComp += `<p class="main-header__search__result__item main-header__search__result__item--text">`;
                autoComp += `<a tabindex="${i}" class="element-${i}" href="#">${res.suggestions[i].value}</a>`;
                autoComp += `</p>`;
                count++;
        	}
        }
        autoComp += `</div>`;
        if(count > 0){
            jQuery('#autocompleteBoxHeader').html(autoComp);
        }
    }
}

var callbackSuggestion = function(res) {
	var suggest = "";
	if(res.suggestions.length > 0){
		var count = 0;
		for(var i = 0; i < res.suggestions.length; ++i)
		{
			// if(encodeURIComponent(sanitizeText(res.suggestions[i].value)) !==
			// params.get(termParameter)){
			if(encodeURIComponent(res.suggestions[i].value) !== params.get(termParameter)){
				suggest += `<span class="block-search-result__intro"><a href="#">${res.suggestions[i].value}</a></span>`;
				count++;
			}
		}
		if(count > 0){
			jQuery('#suggestedSearch').append(suggest);
		} else {
			jQuery('#headingFive').parent().hide();
		}

	} else {
		jQuery('#headingFive').parent().hide();
	}
}

/*
 * Funcion de llamada a las renderizaciones
 */

function renderRelevanceOptionInCombo() {
	const  relevanceOrderOptionid = "relevanceOrderId";
	const relevanceOrderOptionElement = document.getElementById(relevanceOrderOptionid);
	// si ya está en el html no hacemos nada
	if (relevanceOrderOptionElement != null || relevanceOrderOptionElement != undefined) return;
	
	// obtenemos el combo de seleccion
	const comboSeleccion = document.getElementById("select-order");
	if (comboSeleccion == null || comboSeleccion == undefined) return;

	// creamos la nueva opción
	const opt = document.createElement("option");
	opt.id = relevanceOrderOptionid;
	opt.value = relevanceParameter;
	opt.text = porRelevancia.get(langPage);

	// añadimos la opción al principio del combo
	comboSeleccion.add(opt, comboSeleccion.options[0]);

	// cambiar el valor seleccionado por el de relevancia, solo si tiene el
	// parámetro de relevancia
	if (params.has(relevanceParameter)) {
		$('#select-order').val(relevanceParameter);
		$('#select-order').trigger('change');
	}
}


function renderJSONResultsGenericSearchEngine(json){
	renderRelevanceOptionInCombo();
	jQuery('#resultAddSearchJson').append(templatePatrocinado(json));
	jQuery('#resultAddSearchJson').append(templateResultadoHTML(json));
	let totalHits = 0;
	if(typeof json !== 'undefined' && json !== null){
		totalHits = json.total_hits;
		// Revisamos que el script de GA de Aplicaciones se ha cargado
		checkGoogleAnalyticsScriptLoaded();
	}
	jQuery('#paginationAddSearchResults').append(renderPagination(totalHits, true));
	//client.suggestions(params.get(termParameter), callbackSuggestion);
	
}

function renderJSONResultsSpecificSearch(json){
		renderRelevanceOptionInCombo();
    if(isEventBlock()){
		// borramos el html que pudiera existir
		jQuery('#list-results ul.block-schedule__events').html("");
		jQuery('#list-results ul.block-schedule__events').append(specificSearchEngineHtmlEventResult(json));
		jQuery('#list-results ul.block-schedule__events').append(renderPagination(json.total_hits, false));
    } else {
    	// borramos el html que pudiera existir
    	jQuery('.block-search-content').html("");
      jQuery('.block-search-content').append(specificSearchEngineHtmlTemplateResult(json));
      jQuery('.block-search-content').append(renderPagination(json.total_hits, false));
    }
}

function renderJSONResultsLatestAcademicPublicationsSearch(json, total) {
    // borramos el html que pudiera existir
    jQuery('.block-search-content').html("");
    jQuery('.block-search-content').append(latestAcademicPublicationsSearchEngineHtmlTemplateResults(json));
    jQuery('.block-search-content').append(renderPagination(json.total_hits, false));
} 

function renderJSONResultsConvocatoriasSearch(json,numeroConvocatorias) {
	renderRelevanceOptionInCombo();
	// vaciamos el html previamente
	jQuery("#list-results").html("");
	jQuery("#list-results").append(convocatoriasSearchEngineHtmlTemplateResults(json,numeroConvocatorias));
	jQuery('#list-results').append(renderPagination(numeroConvocatorias, false));
	blockTableResponsive(); //para añadir unos estilos que se perdían al filtrar
} 

function renderJSONResultsBlogSearch(json,numeroArticulosBlog) {
	// Mostramos la opción de ordenación por relevancia tras llamar a AddSearch
	renderRelevanceOptionInCombo();
	// vaciamos el html previamente
	jQuery("#form-blog").html("");
	jQuery("#form-blog").append(blogSearchEngineHtmlTemplateResults(json,numeroArticulosBlog));
	jQuery('#form-blog').append(renderPagination(numeroArticulosBlog, false));
} 

function hideAddSearchMessage(){
	jQuery('#addSearchMessage').addClass("visually-hidden");
}

function showAddSearchMessage(){
	jQuery('#addSearchMessage').removeClass("visually-hidden");
}

/*
 * Funcion para la template de renderización de patrocinados si los hubiera
 */
function templatePatrocinado(json){
	let htmlTemplatePatrocinado = null;
	let position = 1;
	for(var i = 0; i < json.hits.length; ++i)
	{
		if(json.hits[i].type !== null && json.hits[i].type === "PROMOTED"){
			let promotionsMightLike = promotionsText.get(langPage);
			htmlTemplatePatrocinado= `<div class="block-search-result block-search-result--highlighted">
								<p class="block-search-result__tag">${promotionsMightLike}</p>
								<p class="block-search-result__title"><a href="${json.hits[i].url}" data-id="${json.hits[i].id}" data-position="${position}" data-event="patrocinado-busqueda" > ${json.hits[i].title}<span class="block-search-result__intro">${json.hits[i].highlight}</span></a></p>
							</div>`;
			thereIsPromotion = true;
		}
	}
	return htmlTemplatePatrocinado;
}

function templateSearchResultImage(hit){
    // if the result belongs to the current channel, we do not print the image
	// block [#113843]
    if(contentBelongsToCurrentChannel(hit)){ return ""; }
    return `<div class="block-search-result__image">
                <picture>
                    <img loading="lazy" src="${hit.custom_fields.detail_content_image}" alt="${hit.title}" >
                </picture>
             </div>`;
}

function templateHighlightText(highlightText){
    return `<span class="block-search-result__intro">${highlightText}</span>`;
}

function templateHighlightTextBlog(highlightText){
    return `<p class="block-search-result__intro">${highlightText}</p>`;
}

function addDotsToHighlightText(highlightText, searchTerm) {
	if (searchTerm != null && searchTerm != "" && searchTerm != "*") {
		highlightText = `...${highlightText}...`;
	}
	return highlightText;
}

function templateDownloadPDFFile(hit){
    if(hit.document_type === "pdf" || hit.custom_fields.hasOwnProperty("detail_pdf_link")){
        var link = hit.custom_fields.hasOwnProperty("detail_pdf_link") ? hit.custom_fields.detail_pdf_link : hit.url;
        var fileName = getPDFFileName(link);
        if(isNotEmpty(fileName)){
            return `<li><a href="${link}" target="_blank" title="${hit.title}" download="${fileName}"><em class="icon-download"> </em></a></li>`;
        }
    }
    return "";
}

function templateAuthorsBlock(authors){
    return `<span class="block-search-result__intro">${authors}</span>`;
}

/* Obtener la posición del elemento */
function getPosition(json){
	var numberPage = json.page;
	var limitPerPage = 10;
	var firstPosition = 1;
	if (thereIsPromotion && numberPage == 1) {
		return 2;
	}else if((!thereIsPromotion && numberPage == 1)){
		return 1;
	}else if (numberPage > 1){
		return ((numberPage-1)*limitPerPage)+firstPosition;
	}
}

/*
 * Funcion para renderizar cada entrada de resultado
 */
function templateResultadoHTML(json){
	let htmlTemplateHTML="";
	let position = getPosition(json);
	for(var i = 0; i < json.hits.length; ++i)
	{
		var title = "";
		let documentDate = formatDateCustom(json.hits[i].ts);
		if(json.hits[i].type !== "PROMOTED"){
			if (json.hits[i].custom_fields != null){
				title = json.hits[i].custom_fields.hasOwnProperty("h1") ? json.hits[i].custom_fields.h1 : json.hits[i].title;
				documentDate = json.hits[i].custom_fields.hasOwnProperty("fechaModificacion") ? formatDateCustom(json.hits[i].custom_fields.fechaModificacion) : formatDateCustom(json.hits[i].ts);
			}else{
				title = json.hits[i].title;
			}
		} else {
			title = json.hits[i].title;
		}
	    var highlightTitle = getHighLightedTitle(title);
		if(json.hits[i].custom_fields !== null && json.hits[i].document_type !== "pdf" && json.hits[i].type !== "PROMOTED"){ // Para documentos HTML
			htmlTemplateHTML +=		`<div class="block-search-result">
										<div class="block-search-result__icon">
											<picture><img data-src="images/portada.png" src="images/image-preview.gif" loading="lazy" alt="portada"></picture>
										</div>
										<div class="block-search-result__content">
											<p class="block-search-result__date">${documentDate}</p>
											<p class="block-search-result__title"><a href="${json.hits[i].url}" data-id="${json.hits[i].id}" data-position="${position}" data-event="resultado-busqueda">${highlightTitle}<span class="block-search-result__intro">${addDotsToHighlightText(json.hits[i].highlight, getTerm())}</span></a></p>
											<div class="block-search-result__wrap">
												${printThemeAddS(json.hits[i], i)}
											</div>
												<ul class="block-search-result__breadcrumbs">
													${renderBreadCrumb(json.hits[i])}
												</ul>
										</div>
									</div>`;
			position++;
		}else if(json.hits[i].type !== "PROMOTED"){ // Para documentos PDF
			htmlTemplateHTML +=    `<div class="block-search-result">
										<div class="block-search-result__icon">
											<picture><img data-src="images/portada.png" src="images/image-preview.gif" loading="lazy" alt="portada"></picture>
										</div>
										<div class="block-search-result__content">
											<p class="block-search-result__date">${documentDate}</p>
											<p class="block-search-result__title"><a href="${json.hits[i].url}" data-id="${json.hits[i].id}" data-position="${position}" data-event="resultado-busqueda">${highlightTitle}<span class="block-search-result__intro">${json.hits[i].title}</span></a></p>
										</div>
									</div>`;
				position++;
		}
	}
	return htmlTemplateHTML;
}

function templateInputValidationErrorMessage(errorMessage){
	return `<p class="input-error-message">${errorMessage}</p>`;
}

function getHighLightedTitle(addSearchTitle){
		if (typeof(addSearchTitle) === 'object' && addSearchTitle.length > 0 && typeof(addSearchTitle[0] ==='string')) {
			addSearchTitle = addSearchTitle.join("; ");
		}

		if (typeof(addSearchTitle) !== 'string') return addSearchTitle;
    
    var words = [""];
    for(var i = 0; i < getTerm().length; i++){
      if(getTerm()[i] !== " ") {
        words[words.length - 1] += getTerm()[i];
      } else if(words[words.length - 1]){
        words.push("");
      }
    }
    var filtered = words.filter((a) => a.length > 3);
    if(filtered.length == 0){
    	return addSearchTitle;
    }
    var replaceThis = filtered.join("|");
    var re = new RegExp(`${replaceThis}`, 'gi');
    return addSearchTitle.replace(re, `<span class="highlightedSearchTerm">$&</span>`);
}

function isValidPDFLink(link){
    return link.toLowerCase().endsWith(".pdf") ? true : false;
}

function getPDFFileName(link){
    if(isValidPDFLink(link)){
        var linkArr = link.split("/");
        return linkArr[linkArr.length-1];
    }
}

function specificSearchEngineHtmlEventResult(json){
	let htmlEventTemplateHTML="";
	for(var i = 0; i < json.hits.length; ++i)
	{
		if(json.hits[i].custom_fields !== null && json.hits[i].type !== "PROMOTED"){
			var eventDate = json.hits[i].custom_fields.FechaCreacion;
			if(eventDate === undefined) { continue; }
			
			var link = json.hits[i].url;
			var title = json.hits[i].custom_fields.hasOwnProperty("h1") ? json.hits[i].custom_fields.h1 : json.hits[i].title;
            var highlightTitle = getHighLightedTitle(title);
            var day = new Date(eventDate).getDate();
            var month = monthNameMap.get(langPage).get(new Date(eventDate).getMonth() + 1);
            var year = new Date(eventDate).getFullYear();

			htmlEventTemplateHTML +=`<li class="block-schedule__events__item">
                                    	<span class="block-schedule__events__item__date">
                                    		<strong>${day}</strong>
                                    		<span>${month}</span>
                                    		<span>${year}</span>
                                    	</span>
                                    	<span>
                                    		<a class="block-schedule__events__item__content" target="_self" href="${link}" title="${title}" tabindex="0">
                                    			<strong class="block-schedule__events__item__content__name"></strong>
                                    			<span><span class="block-schedule__events__item__content__event">${highlightTitle}</span></span>
                                    		</a>
                                    	</span>
                                    </li>`;
		}
	}
	return htmlEventTemplateHTML;
}

function specificSearchEngineHtmlTemplateResult(json){
	let htmlTemplateHTML="";
	var position = getPosition(json);
	
	for(var i = 0; i < json.hits.length; ++i)
	{
	    var hit = json.hits[i];
	    
		if(hit.custom_fields !== null && hit.type !== "PROMOTED"){
			var link = hit.url;
			var title = hit.custom_fields.hasOwnProperty("h1") ? hit.custom_fields.h1 : hit.title;
			var documentDate = hit.custom_fields.hasOwnProperty("fechaModificacion") ? formatDateCustom(hit.custom_fields.fechaModificacion) : formatDateCustom(hit.ts);
			var highlightBlock = hit.hasOwnProperty("highlight") ? templateHighlightText(addDotsToHighlightText(hit.highlight, getTerm())) : "";
			var thumbImageBlock = hit.custom_fields.hasOwnProperty("detail_content_image") ? templateSearchResultImage(hit) : "";
			var hasThumbImageIcon = thumbImageBlock !== "" ? "block-search-result--icon" : "";
            var pdfDownloadLinkBlock = templateDownloadPDFFile(hit);
			var highlightTitle = getHighLightedTitle(title);
			var authors = hit.custom_fields.hasOwnProperty("autoresPublicacion") ? hit.custom_fields.autoresPublicacion : "";
			var contentType = hit.custom_fields.hasOwnProperty("tipoContenido") ? hit.custom_fields.tipoContenido : "";
			var authorsBlock = '';
			if (authors != '' && getTerm() == '*' && contentType == 'BDE_PUBLICACION_MULTIIDIOMA' 
				&& params.get(themeParameter) != null && params.get(themeParameter) != ''){
				// Si se cumplen las condiciones pintamos los autores en lugar del highlight en los resultados
				authorsBlock = authors != '' ? templateAuthorsBlock(authors) : "";
				highlightBlock = '';
			}
			
            htmlTemplateHTML +=	`<div class="block-search-result ${hasThumbImageIcon}">
                                    ${thumbImageBlock}
                                    <div class="block-search-result__content">
                                        <p class="block-search-result__date">${documentDate}</p>
                                        <p class="block-search-result__title">
                                            <a href="${link}" title="${title}" target="_self" data-id="${json.hits[i].id}" data-position="${position}" data-event="resultado-busqueda">
                                                <strong>${highlightTitle}</strong>
                                                ${highlightBlock}
												${authorsBlock}
                                            </a>
                                        </p>
                                        <div class="block-search-result__wrap">
                                            ${printThemeAddS(json.hits[i], i)}
                                            <ul class="block-search-result__actions">
                                                ${pdfDownloadLinkBlock}
                                                <li>
                                                    <a class="js-showRRSS block-card-news__actions__list__item__link" href="javascript:;" title="Compartir"><em class="icon-share"></em>Compartir</a>
                                                    <ul class="block-rrss">
                                                        <li><a href="http://www.facebook.com/sharer.php?u=${link}" title="Compartir en Facebook" target="_blank" tabindex="-1" data-event="share-facebook"><em class="icon-facebook"></em></a></li>
                                                        <li><a target="_blank" title="Compartir en Twitter" href="https://twitter.com/share?text=${title}&amp;via=BancoDeEspana&amp;url=${link}" tabindex="-1" data-event="share-twitter"><em class="icon-twitter"></em></a></li>
                                                        <li><a href="https://www.linkedin.com/cws/share?url=${link}" title="Compartir en Linkedin" target="_blank" tabindex="-1" data-event="share-linkedin"><em class="icon-linkedin"></em></a></li>
                                                        <li><a href="mailto:?subject=Banco de España : ${title}&amp;body=${title} : ${link}" title="Compartir página por email" tabindex="-1" data-event="share-email"><em class="icon-email"></em></a></li>
                                                    </ul>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>`;
            position++;
		}
	}
	return htmlTemplateHTML;
}


function getCurrentChannel(){

	var metaChannel = jQuery("meta[name='addsearch-custom-field'][content*='Channel']");	
	if(metaChannel.length == 0){
		metaChannel = jQuery("meta[name='channel-custom-field'][content*='Channel']");
	}
	if(metaChannel.length > 0){
		var channels = metaChannel.attr("content").replaceAll("Channel=","").split(";");
		return channels[channels.length-1];
	}
    return "";
}

function estoyEnConvocatoriasAbiertas(){
	var result = false;
	if(getCurrentChannel() == "f6a7a34e58a36810VgnVCM1000001af614acRCRD"){
		result = true;
	}
	return result;
}

function estoyEnConvocatoriasCerradas(){
	var result = false;
	if(getCurrentChannel() == "efe73364105f3810VgnVCM1000001af614acRCRD"){
		result = true;
	}
	return result;
}

function getConvocatoriaType(value) {
	if (value ==='CONT_TEMP') return "TEMPORAL";
	return value;
}


function latestAcademicPublicationsSearchEngineHtmlTemplateResults(json, total){
	let htmlTemplateHTML="";
	var position = getPosition(json);
	
	for(var i = 0; i < json.hits.length; ++i)
	{
	    var hit = json.hits[i];
	    
		if(hit.custom_fields !== null && hit.type !== "PROMOTED"){
			var link = hit.url;
			var encodedLink = encodeURI(link);
			var title = hit.custom_fields.hasOwnProperty("h1") ? hit.custom_fields.h1 : hit.title;
			var documentDate = hit.custom_fields.hasOwnProperty("fechaModificacion") ? formatDateCustom(hit.custom_fields.fechaModificacion) : formatDateCustom(hit.ts);
            var pdfDownloadLinkBlock = templateDownloadPDFFile(hit);
			var highlightTitle = getHighLightedTitle(title);
			var publicationLink = hit.custom_fields.hasOwnProperty("detail_pdf_link") ? hit.custom_fields.detail_pdf_link : "#";
			var publicationTitle = hit.custom_fields.hasOwnProperty("detail_file_title") ? hit.custom_fields.detail_file_title : "";
			var authorBlock = templateAuthorPublication(hit);
			
            htmlTemplateHTML +=	`<div class="block-search-result">
                                    <div class="block-search-result__content">
                                        <p class="block-search-result__date">${documentDate}</p>
                                        <div class="block-search-result__title">
                                            <a href="${link}" title="${title}" target="_self" data-id="${json.hits[i].id}" data-position="${position}" data-event="resultado-busqueda">
                                                <strong>${highlightTitle}</strong>
                                            </a>
            								<span class="block-search-result__publication">											
												<a href="${publicationLink}" title="${publicationTitle}" target="_blank">
													${publicationTitle}
            										<img class="icono ext" height="12" width="12" loading="lazy" src="/f/Sistema/Icons/exturlicon.gif" alt="Abre en nueva ventana">
												</a>
											</span>
											${authorBlock}                            
                                        </div>
                                        <div class="block-search-result__wrap">
                                            ${printThemeAddS(json.hits[i], i)}
                                            <ul class="block-search-result__actions">
                                                ${pdfDownloadLinkBlock}
                                                <li>
                                                    <a class="js-showRRSS block-card-news__actions__list__item__link" href="javascript:;" title="Compartir"><em class="icon-share"></em>Compartir</a>
                                                    <ul class="block-rrss">
                                                        <li><a href="http://www.facebook.com/sharer.php?u=${encodedLink}" title="Compartir en Facebook" target="_blank" tabindex="-1" data-event="share-facebook"><em class="icon-facebook"></em></a></li>
                                                        <li><a target="_blank" title="Compartir en Twitter" href="https://twitter.com/share?text=${title}&amp;via=BancoDeEspana&amp;url=${encodedLink}" tabindex="-1" data-event="share-twitter"><em class="icon-twitter"></em></a></li>
                                                        <li><a href="https://www.linkedin.com/cws/share?url=${encodedLink}" title="Compartir en Linkedin" target="_blank" tabindex="-1"data-event="share-linkedin"><em class="icon-linkedin" ></em></a></li>
                                                        <li><a href="mailto:?subject=Banco de España : ${title}&amp;body=${title} : ${encodedLink}" title="Compartir página por email" tabindex="-1" data-event="share-email"><em class="icon-email"></em></a></li>
                                                    </ul>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>`;
            position++;
		}
	}
	return htmlTemplateHTML;
}

function templateAuthorPublication(hit){
	let author = hit.custom_fields.hasOwnProperty("Autor") ? hit.custom_fields.Autor : "";
	let authorLink = hit.custom_fields.hasOwnProperty("AutorLink") ? hit.custom_fields.AutorLink : "#";
	let authorBlock = "";
	if (Array.isArray(author)){
		let arraySizes = author.length === authorLink.length;
		for (let i = 0; i < author.length; i++) {
			if(arraySizes){
				if (authorLink[i] !== "" && authorLink[i] !== "#"){
					authorBlock += `<a class="block-search-result__researcher block-search-result__authors" href="${authorLink[i]}" target="_blank">${author[i]}</a>`;
				} else {
					authorBlock += `<span class="block-search-result__researcher block-search-result__authors">${author[i]}</span>`;
				}
			} else {
				authorBlock += `<span class="block-search-result__researcher block-search-result__authors">${author[i]}</span>`;
			}
			authorBlock += (author.length-1 === i) ? "" : ", ";
		}
	} else {
		if (authorLink !== "" && authorLink !== "#"){
			authorBlock = `<a class="block-search-result__researcher block-search-result__authors" href="${authorLink}" target="_blank">${author}</a>`;
		} else {
			authorBlock = `<span class="block-search-result__researcher block-search-result__authors">${author}</span>`;
		}
	}    
	return authorBlock;
}

function convocatoriasSearchEngineHtmlTemplateResults(json,numeroConvocatorias){

	let templateConvocatoriasHTML ="";
	if (numeroConvocatorias > 0) {
		templateConvocatoriasHTML ="<div class='block-entry-content__table'>" + 
														   "<div class='block-entry-content__table--medium table-responsive'>";
		templateConvocatoriasHTML += "<table><thead>";
		templateConvocatoriasHTML +="<tr><th>"+puestoConvocatorias.get(langPage)+"</th><th>"+FechaActualizacionConvocatorias.get(langPage)+"</th>"
															+ "<th>"+numAnuncioConvocatorias.get(langPage)+"</th><th>"+tipoEmpleoConvocatorias.get(langPage)+"</th>" 
															+"</tr></thead><tbody>";

			let convocatoriaActual = null;
			let htmlConvocatoriaActual = "";
			let fechaActualizacion = null;
			let numAnuncio = null;
			let title = null;
			let position = getPosition(json);
			for (let i =0; i < json.hits.length; i++) {
				convocatoriaActual = json.hits[i];
				
				if (convocatoriaActual.custom_fields == null) continue; // skip
																		// promoted
																		// links


				if (convocatoriaActual.custom_fields.tipoContenido != undefined 
						&& convocatoriaActual.custom_fields.tipoContenido ==='BDE_CONVOCATORIA_MULTIIDIOMA') {
					htmlConvocatoriaActual ="<tr>";
					// puesto
					title = convocatoriaActual.custom_fields['h1'];
					if (title == undefined){
						  title = convocatoriaActual.highlight;
					}
					htmlConvocatoriaActual +="<td><a href='" + convocatoriaActual.url 
					+"' data-id='"+ json.hits[i].id +"' data-position='"+ position +"' title='" + title + "' target ='_self'>"
								+ title + "</a></td>";

					// fecha-actualización
					if(convocatoriaActual.custom_fields.hasOwnProperty("fechaModificacion")){
						fechaActualizacion = convocatoriaActual.custom_fields.fechaModificacion.substring(0,convocatoriaActual.custom_fields.fechaModificacion.indexOf("T"));
						if(fechaActualizacion != null) fechaActualizacion = fechaActualizacion.split("-").reverse().join("-").replace(/-/g,'/');
					}else{
						fechaActualizacion = convocatoriaActual.ts.substring(0,convocatoriaActual.ts.indexOf("T"));
						if(fechaActualizacion != null) fechaActualizacion = fechaActualizacion.split("-").reverse().join("-").replace(/-/g,'/');
					}
					
					htmlConvocatoriaActual +="<td>" + fechaActualizacion + "</td>";
					
					// numero-anuncio
					numAnuncio = convocatoriaActual.custom_fields['numAnuncio'];
					if(numAnuncio == null || numAnuncio == undefined || numAnuncio == ""){
						numAnuncio= " ";
					}
					htmlConvocatoriaActual +="<td>"+numAnuncio+"</td>";
					
					// tipo
					let classParrafoTipo="block-category-tag block-category-tag--";
					switch (json.hits[i].custom_fields['tipoConvocatoria']) {
						case 'BECA':
							classParrafoTipo +="grant";
							break;
						case 'RESEARCH':
							classParrafoTipo +="research";
							break;
						case 'TEMPORAL':
							classParrafoTipo +="temporary";
							break;
						case 'FIJO':
							classParrafoTipo +="permanent";
							break;
						case 'AYUDAS':
							classParrafoTipo +="help";
							break;
					}

					var tipoConvocatoria = typeof convocatoriaActual.custom_fields['tipoConvocatoria'] !== 'undefined' ? convocatoriaActual.custom_fields['tipoConvocatoria'] : null;
					
					htmlConvocatoriaActual +="<td><p class='" + classParrafoTipo +"'>" + convocatoriaMap.get(langPage).get(tipoConvocatoria) + "</p></td>";

					templateConvocatoriasHTML += htmlConvocatoriaActual;
				}
				position++;
			} // for
			templateConvocatoriasHTML +="</tbody></table></div></div>";
		}
	return templateConvocatoriasHTML;
}

function blogSearchEngineHtmlTemplateResults(json,numeroArticulosBlog){
	let htmlTemplateHTML="";
	var position = getPosition(json);
	
	for(var i = 0; i < json.hits.length; ++i)
	{
	    var hit = json.hits[i];
	    
		if(hit.custom_fields !== null && hit.type !== "PROMOTED"){
			var link = hit.url;
			var encodedLink = encodeURI(link);
			var title = hit.custom_fields.hasOwnProperty("h1") ? hit.custom_fields.h1 : hit.title;
			var documentDate = hit.custom_fields.hasOwnProperty("fechaModificacion") ? formatDateCustom(hit.custom_fields.fechaModificacion) : formatDateCustom(hit.ts);
			var highlightBlock = "";
			if (getTerm() == '*'){
				highlightBlock = hit.custom_fields.hasOwnProperty("descripcion") ? templateHighlightTextBlog(hit.custom_fields.descripcion) : "";
			} else {
				highlightBlock = hit.hasOwnProperty("highlight") ? templateHighlightTextBlog(addDotsToHighlightText(hit.highlight, getTerm())) : "";
			}
			var thumbImageBlock = hit.custom_fields.hasOwnProperty("imagenBlog") ? hit.custom_fields.imagenBlog : imagenDefaultAddsearch;
            var readTime = hit.custom_fields.hasOwnProperty("tiempoLecturaBlog") ? templateReadTimeBlog(hit.custom_fields.tiempoLecturaBlog) : "";
            var audioLink = hit.custom_fields.hasOwnProperty("AudioLink") ? templateAudioLinkBlog(hit.custom_fields.AudioLink) : "";
            var nameSerie = hit.custom_fields.hasOwnProperty("NameSerie") ? templateNameSerieBlog(hit.custom_fields.NameSerie) : "";
            var author = hit.custom_fields.hasOwnProperty("Autor") ? templateAuthorBlog(hit.custom_fields.Autor) : "";
			var highlightTitle = getHighLightedTitle(title);
			let onlyOneResult = json.hits.length == 1 ? "onlyoneresult" : "";
            htmlTemplateHTML +=	`<div class="block-search-result ${onlyOneResult} block-search-result--image">
            	<div class="block-search-result__image">
                    <picture>
						<img data-src="${replaceUrlCharacters(thumbImageBlock)}" src="${replaceUrlCharacters(thumbImageBlock)}" loading="lazy" alt="${title}" class="loaded">
				    </picture>
	            </div>
                <div class="block-search-result__content">
                    <p class="block-search-result__date">${documentDate}</p>
                    <p class="block-search-result__title">
						<a href="${link}" title="${title}" target="_self" data-id="${json.hits[i].id}" data-position="${position}" data-event="resultado-busqueda">
							<strong>${highlightTitle}</strong>
						</a>
						${nameSerie}
					</p>
							${author}
							${highlightBlock}
					<div class="block-search-result__wrap">
						<ul class="block-search-result__actions">
							${readTime}
							   <li>
                                    <a class="js-showRRSS block-card-news__actions__list__item__link" href="javascript:;" title="Compartir"><em class="icon-share"></em>Compartir</a>
                                    <ul class="block-rrss">
                                        <li><a href="http://www.facebook.com/sharer.php?u=${encodedLink}" title="Compartir en Facebook" target="_blank" tabindex="-1"><em class="icon-facebook"></em></a></li>
                                        <li><a target="_blank" title="Compartir en Twitter" href="https://twitter.com/share?text=${title}&amp;via=BancoDeEspana&amp;url=${encodedLink}" tabindex="-1"><em class="icon-twitter"></em></a></li>
                                        <li><a href="https://www.linkedin.com/cws/share?url=${encodedLink}" title="Compartir en Linkedin" target="_blank" tabindex="-1"><em class="icon-linkedin"></em></a></li>
                                        <li><a href="mailto:?subject=Banco de España : ${title}&amp;body=${title} : ${encodedLink}" title="Compartir página por email" tabindex="-1"><em class="icon-email"></em></a></li>
                                    </ul>
                                </li>
							<li>
								<p class="block-card-news__tag tag tag--blue">${printThemeAddS(json.hits[i], i)}</p>
							</li>
						</ul>
						${audioLink}
					</div>
				</div>
			</div>`;
            position++;
		}
	}
	return htmlTemplateHTML;
}

function templateReadTimeBlog(readTime){
    return `<li>
				<a class="block-card-news__actions__list__item__link block-card-news__actions__list__item__link--gray" href="javascript:;" title="${readTime}">
					<em class="block-card-news__actions__list__item__icon icon-time"></em>${readTime}
				</a>
			</li>`;
}


function customEncodeURI(str) {
    return str.replace(/[\u00C0-\u00FF]/g, function(c) {
        return '%' + c.charCodeAt(0).toString(16).toUpperCase();
    });
}

function templateAudioLinkBlog(audioLink){
	var files = "/f";
	if(audioLink == "null" || audioLink == ""){
      	return "";
    }else if(!audioLink.startsWith('/')){
    	files += "/";
    }
	var encodedAudioLink = audioLink.split('/').map(customEncodeURI).join('/');
    return `<div class="audio-wrapper">
				<audio class="audio-item" style="width: 80%;" controls="controls" controlslist="nodownload" data-mce-fragment="1">
				<source type="audio/mpeg" src="${files}${encodedAudioLink}">
				</audio>
			</div>`;
}


function templateNameSerieBlog(nameSerie){
	var txtSerie = serieBlog.get(langPage)+": ";
	var txtSeriePlural = serieBlogPlural.get(langPage)+": ";
	var result = "";
	if(nameSerie){
		let parts = nameSerie.split(',');
		let commaCount = parts.length - 1;
		if(commaCount == 0){
			result = `<span class="blog_series">${txtSerie}${nameSerie}</span>`;
		}else{
			result = `<span class="blog_series">${txtSeriePlural}${nameSerie}</span>`;
		}
	}
    return result;
}


function templateAuthorBlog(author){
	let authorBlock = "";
	if (Array.isArray(author)){
		let multiAuthorString = "";
		for (let i = 0; i < author.length; i++) {
			multiAuthorString += author[i] + ", ";
		}
		multiAuthorString = multiAuthorString.slice(0, -2);
		authorBlock = `<p class="block-search-result__author">${multiAuthorString}</p>`;
	}
	else{
		authorBlock = `<p class="block-search-result__author">${author}</p>`;
	}    
	return authorBlock;
}

function printThemeAddS(elemento, position){
	let theme = getMatchTheme(elemento); //lista con todos los elementos que hace match entre los parametros de la url
	let valueTheme = "";
	let firstTheme = theme[0]; //cod first theme
	if(isNotEmpty(firstTheme)){
		if(themeCategoryArray.length > 0) { 
			valueTheme = getValueTheme(firstTheme);
		}
		else {
			// Si no ha llegado el json a tiempo, esperamos a la promesa para incrustar el elemento
			getCategoriesJSON().then((data) => {
				valueTheme = getValueTheme(firstTheme);
				$('#resultAddSearchJson > div:nth-child(' + (position+1) + ') > div.block-search-result__content > div')
					.append(`<p class="block-search-result__category">${valueTheme}</p>`);
			})    
			.catch((error) => {
				// El recuso no es accesible
				console.log(error.message);
			});
			return valueTheme; // Retornamos vacio, ya que se creara despues, cuando llegue la promesa
		}
		if(valueTheme){
			valueTheme = `<p class="block-search-result__category">${valueTheme}</p>`;
		} 
	}
	return valueTheme;
}

function getValueTheme(firstTheme){
	let jsonValue = "";
	let lang = "text_"+langPage;
	if(firstTheme.includes('RCRD')){
		jsonValue = getJSONSubArray(themeCategoryArray, 'themesAndSubthemes').find(item=>item.themeCategoryId==firstTheme);
	}else{
		jsonValue = getJSONSubArray(themeCategoryArray, 'themesAndSubthemes').find(item=>item.cod==firstTheme);
	}
	return isNotEmpty(jsonValue) ? jsonValue[lang] : firstTheme; //pinto el primer elemento que hace match entre los parametros de la url y las cat que tiene la instancia
}

function getParamsThemeUrl(){
	let values = [];
	let url = window.location.href;
	let param= "theme";
	let urlParams = new URLSearchParams(url);
	let jsonValue = "";
	// Iterar sobre todos los valores asociados al parámetro "theme", traducir si viene en la url con Id en vez de codigo
	urlParams.getAll(param).forEach(function(value) {
		if(value.includes('RCRD')){
			jsonValue = getJSONSubArray(themeCategoryArray, 'themesAndSubthemes').find(item=>item.themeCategoryId==value);
			value = isNotEmpty(jsonValue) ? jsonValue.themeCategoryId : value;
			values.push(value);
		}else{
			values.push(value);
		}
	});
	return values;
}

function getTemas(elemento){
	let customFields = "";
	let temasInstancia = [];
	if (elemento.hasOwnProperty("custom_fields")){
		customFields = elemento.custom_fields;
		if (customFields.hasOwnProperty("Tema")){
			temasInstancia = customFields.Tema;
			if(Array.isArray(temasInstancia)){
				temasInstancia.push(temasInstancia);
			}
		}
	}
	return temasInstancia;
}

function translateRCRDToACode(list){
	let result = [];
	let jsonValue = "";
	for (let i = 0; i < list.length; i++) {
		let elem = list[i];
		jsonValue = getJSONSubArray(themeCategoryArray, 'themesAndSubthemes').find(item=>item.themeCategoryId==elem);
		if(jsonValue){
		result.push(jsonValue.cod);
		}
	}	
	return result;
}

function getMatchTheme(elemento) {
	  let match = [];
	  let temasInstancia = getTemas(elemento);
	  let paramsThemeUrl = getParamsThemeUrl();
	  if(paramsThemeUrl != null && paramsThemeUrl.length > 0 && paramsThemeUrl[0].includes('RCRD')){
	    //Traducir RCRD a codigo
		  paramsThemeUrl = translateRCRDToACode(paramsThemeUrl);
	    }
	  if(Array.isArray(temasInstancia)){
		  for (let i = 0; i < temasInstancia.length; i++) {
			    let temaInst = temasInstancia[i];
			    if (paramsThemeUrl != null && paramsThemeUrl.length > 0 && paramsThemeUrl.includes(temaInst)) {
			      match.push(temaInst);
			    }
			  }
	  }else{
		  if (paramsThemeUrl != null && paramsThemeUrl.length > 0 && paramsThemeUrl.includes(temasInstancia)) {
		      match.push(temasInstancia);
		   }
	  }
	  return match;
	}



/*
 * Funcion para renderizar los breadcrumb de cada entrada de resultados.
 */
function renderBreadCrumb(arrayBreadCrumb){
	var htmlTemplateBreadCrumbHTML="";
	let wrongBreadcrumbLink = false;
	if(arrayBreadCrumb.custom_fields.length !== 0 && arrayBreadCrumb.custom_fields.hasOwnProperty("breadcrumb")){
        if(typeof arrayBreadCrumb.custom_fields.breadcrumb == 'object'){

            for(var i = 0; i < arrayBreadCrumb.custom_fields.breadcrumb.levels.length; ++i) {
            		wrongBreadcrumbLink = (arrayBreadCrumb.custom_fields.breadcrumb.levels[i].link.startsWith('/') === false);
            		if (wrongBreadcrumbLink) return "";

                if(arrayBreadCrumb.custom_fields.breadcrumb.levels.length-1 === i){
                    htmlTemplateBreadCrumbHTML +=`<li><strong><a href=${arrayBreadCrumb.custom_fields.breadcrumb.levels[i].link}>${arrayBreadCrumb.custom_fields.breadcrumb.levels[i].description}</a></strong></li>`;
                }else{
                    htmlTemplateBreadCrumbHTML += `<li>${arrayBreadCrumb.custom_fields.breadcrumb.levels[i].description}</li>`;
                }
            }
        } else {
            htmlTemplateBreadCrumbHTML += `<li>${arrayBreadCrumb.custom_fields.breadcrumb}</li>`;
        }
	}
	return htmlTemplateBreadCrumbHTML;
}


/*
 * Funcion auxiliar para formatear la fecha de ISO para DD/MM/YYYY en cada
 * entrada los resultados
 */
function formatDateCustom(date){
		date = new Date(date);
		year = date.getFullYear();
		month = date.getMonth()+1;
		dt = date.getDate();

		if (dt < 10) {
		  dt = '0' + dt;
		}
		if (month < 10) {
		  month = '0' + month;
		}
		var formatDate = dt +'/' + month + '/'+year;

		return formatDate;
}

/*
 * Función auxiliar para agrupar el breadcrumb en un único Array clave:valor y
 * setearlo en el JSON a renderizar
 */
function addStructuredBreadcrumb(jsonResult){
	var json = jsonResult;
	for(var i = 0; i < json.hits.length; ++i)
	{
		if(json.hits[i].custom_fields && json.hits[i].custom_fields.breadcrumb_links){
			var dataBreadcrumb = { levels: [ ] };
			if(typeof json.hits[i].custom_fields.breadcrumb_links === 'string'){
				dataBreadcrumb.levels.push({
				   description: json.hits[i].custom_fields.breadcrumb_texts[0],
				   link: json.hits[i].custom_fields.breadcrumb_links
				});
			} else {
				for(var j = 0; j < json.hits[i].custom_fields.breadcrumb_texts.length-1; ++j)
				{
					dataBreadcrumb.levels.push({
						description:   json.hits[i].custom_fields.breadcrumb_texts[j],
						link: typeof json.hits[i].custom_fields.breadcrumb_links[j] === 'undefined' ? json.hits[i].url : json.hits[i].custom_fields.breadcrumb_links[j]
					});
				}
			}
			json.hits[i].custom_fields.breadcrumb = dataBreadcrumb;
		}
	}
		return json;
}

/*
 * Funcion auxiliar para seleccion dropDown limite ficheros
 */
function selectedDropDown(value){
	return params.get(limitParameter) == value ? "selected" : "";
}

/*
 * Funcion auxiliar borrado resultados antes de re llamada AJAX con filtros.
 */
function removeAllResultsBeforeReCharge(){
	jQuery('#resultAddSearchJson').empty();
	jQuery('#paginationAddSearchResults').empty();
}


/*
 * Funcion para validar si las fechas son correctas y pode enviar todos los
 * datos...
 */
function validateDates(dateFrom,dateTo){
	if(dateFrom && dateTo){
		if(Date.parse(getLastDayOfMonth(dateTo)) >= Date.parse(getFirstDayOfMonth(dateFrom))){
			return true;
		}
		else{
			return false;
		}
	}else{
		return true;
	}
}

/* Funcion auxiliar para crear el array de inputs hidden de los Tipos */
function createArrayFilterTipo(){
	var arrayObjetTipo ={};
	jQuery("div.form-check input.form-check-input[checked]:checked").each(function(index){
		let inputObject = document.createElement('input');
		inputObject.setAttribute('type', 'hidden');
		inputObject.setAttribute('name', channelParameter);
		inputObject.setAttribute('value', jQuery(this).next('label').data('id'));
		arrayObjetTipo[index] = inputObject;
	});
	return arrayObjetTipo;
}

/* Funcion auxiliar para crear el array de inputs hidden de los Temas */
function createArrayFilterTema(){
	let arrayObjetTema ={};
	let conjuntoTemasHTML = document.querySelectorAll("option[value2]");
	let conjuntoTemasSeleccionados =jQuery("span.select2-selection__choice__display");
	let idTemaEncontrado = false;
	let themeValue ="";
	let index =0;
	let j = 0;
	for (let i =0; i < conjuntoTemasSeleccionados.length;i++){
		let inputObject = document.createElement('input');
		inputObject.setAttribute('type', 'hidden');

		while (j < conjuntoTemasHTML.length && !idTemaEncontrado) {
			if (conjuntoTemasHTML[j].textContent === conjuntoTemasSeleccionados[i].textContent) {
				themeValue = conjuntoTemasHTML[j].value;
				idTemaEncontrado = true;
			}

			j++;
		}
		
		inputObject.setAttribute('name', themeParameter);
		inputObject.setAttribute('value', themeValue);
		arrayObjetTema[index] = inputObject;
		index++;
		// reinicio de las variables para entrar otra vez en el while
		j = 0;
		idTemaEncontrado = false;
	}

	return arrayObjetTema;
}

/* Funcion auxiliar par acrear el input hidden de la fecha Desde */
function createObjtDateFrom(dateFrom){
	var inputObjectDateFrom = document.createElement('input');
	inputObjectDateFrom.setAttribute('type', 'hidden');
	inputObjectDateFrom.setAttribute('name', startDateTimeParameter);
	inputObjectDateFrom.setAttribute('value', dateFrom);
	return inputObjectDateFrom;
}


/* Funcion auxiliar para obtener la fecha Desde filtrada si la hay */
function getDateFrom(){
	var d = jQuery("#datarangef1").val();
	return isNotEmpty(d) ? d.replace('/','') : null;
}

/* Funcion auxiliar para crear el input hidden de la fecha Hasta */
function createObjDateTo(dateTo){
	var inputObjectDateTo = document.createElement('input');
	inputObjectDateTo.setAttribute('type', 'hidden');
	inputObjectDateTo.setAttribute('name', endDateTimeParameter);
	inputObjectDateTo.setAttribute('value', dateTo);
	return inputObjectDateTo;
}

/* Funcion auxiliar para obtener la fecha Hasta filtrada si la hay */
function getDateTo(){
	var d = jQuery("#datarangef2").val();
	return isNotEmpty(d) ? d.replace('/','') : null;
}

/**
 * Tarea APWBEWBD-224
 * Las URLs con tilde se codifican en UTF-8 en el navegador, por lo que el servidor no es capaz de encontrar los recursos.
 * En este método se sustituyen los caracteres con tilde, diéresis, etc en su código Windows 1252, que es el que usa el servidor.
 */ 
function replaceUrlCharacters(input) {
    const lookupTable = {
        'À': '%C0','Á': '%C1','Â': '%C2','Ã': '%C3','Ä': '%C4','Å': '%C5','Ç': '%C7','È': '%C8','É': '%C9','Ê': '%CA',
        'Ë': '%CB','Ì': '%CC','Í': '%CD','Î': '%CE','Ï': '%CF','Ñ': '%D1','Ò': '%D2','Ó': '%D3','Ô': '%D4','Õ': '%D5',
        'Ö': '%D6','Ù': '%D9','Ú': '%DA','Û': '%DB','Ü': '%DC','à': '%E0','á': '%E1','â': '%E2','ã': '%E3','ä': '%E4',
        'å': '%E5','ç': '%E7','è': '%E8','é': '%E9','ê': '%EA','ë': '%EB','ì': '%EC','í': '%ED','î': '%EE','ï': '%EF',
        'ñ': '%F1','ò': '%F2','ó': '%F3','ô': '%F4','õ': '%F5','ö': '%F6','ù': '%F9','ú': '%FA','û': '%FB','ü': '%FC'
    };

    let result = '';
    for (const char of input) {
        if (lookupTable[char]) {
            result += lookupTable[char];
        } else {
            result += char;
        }
    }

    return result;
}


/*
 * Bloque de funciones para crear objetos input hidden de parámetros necesario
 * para la búsqueda, son independientes de los filtros y SIEMPRE deben de ir en
 * las búsquedas
 */
function createObjParam(param){
	let inputObjectParam = document.createElement('input');
	inputObjectParam.setAttribute('type', 'hidden');
	inputObjectParam.setAttribute('type', 'hidden');
	inputObjectParam.setAttribute('name', termParameter);
	inputObjectParam.setAttribute('value', param);
	return inputObjectParam;
}

function createObjlimit(limit){
	let inputObjectLimit = document.createElement('input');
	inputObjectLimit.setAttribute('type', 'hidden');
	inputObjectLimit.setAttribute('name', limitParameter);
	inputObjectLimit.setAttribute('value', limit);
	return inputObjectLimit;
}

function createObjFuzzy(fuzzy){
	let inputObjectFuzzy = document.createElement('input');
	inputObjectFuzzy.setAttribute('type', 'hidden');
	inputObjectFuzzy.setAttribute('name', fuzzyParameter);
	inputObjectFuzzy.setAttribute('value', fuzzy);
	return inputObjectFuzzy;
}

/*
 * function createObjAnalytic(analytic){ let inputObjectAnalytic =
 * document.createElement('input'); inputObjectAnalytic.setAttribute('type',
 * 'hidden'); inputObjectAnalytic.setAttribute('name',
 * collectAnalyticsParameter); inputObjectAnalytic.setAttribute('value',
 * analytic); return inputObjectAnalytic; }
 */

function createObjLang(lang){
	let inputObjectLang = document.createElement('input');
	inputObjectLang.setAttribute('type', 'hidden');
	inputObjectLang.setAttribute('name', langParameter);
	inputObjectLang.setAttribute('value', lang);
	return inputObjectLang;
}

function createObjPage(page){
	let inputObjectPage = document.createElement('input');
	inputObjectPage.setAttribute('type', 'hidden');
	inputObjectPage.setAttribute('name', pageParameter);
	inputObjectPage.setAttribute('value', page);
	return inputObjectPage;
}

function updateFiltersMenuBasedOnURL(){
	var term = params.get(termParameter);
	var channels = params.getAll(channelParameter);
	var themes = params.getAll(themeParameter);
	let type = params.getAll(typeParameter);
	var startDate = params.get(startDateTimeParameter);
	var endDate = params.get(endDateTimeParameter);
	var sanitizedTerm = "";
	if(isEncoded(term)){
		sanitizedTerm = DOMPurify.sanitize(decodeURIComponent(term));
	}else{
		sanitizedTerm = DOMPurify.sanitize(term);
	}
	
	if(isNotEmpty(sanitizedTerm)){
		jQuery("#search").val(sanitizedTerm);
	}

	for(let i=0; i<channels.length; i++){
		jQuery("label[data-id='"+channels[i]+"']").siblings("input").attr( 'checked', true );
	}

	// seteamos los temas
	let themeSelect2Selector = jQuery("#select-theme").select2ToTree();
	if (themeSelect2Selector === undefined) {
		themeSelect2Selector = jQuery("#generic-search").select2ToTree();
	}
	
	if (themeSelect2Selector != undefined && themeSelect2Selector != null) {
		themeSelect2Selector.val(themes);
	}

	// seteamos el tipo (para las convocatorias)
	let typeSelect2Selector = jQuery("#select-type").data('select2');
	if (typeSelect2Selector != undefined && typeSelect2Selector != null && type.length > 0 ) {
		typeSelect2Selector.val(type);
	}

	if(isNotEmpty(startDate)){
		jQuery("#datarangef1").val(convertToDatePickerValue(startDate));
	}

	if(isNotEmpty(endDate)){
		jQuery("#datarangef2").val(convertToDatePickerValue(endDate));
	}
}


function updateFiltersMenuBasedOnURLSanciones(){
	var term = params.get(termParameterSanciones);
	var startDate = params.get(startDateTimeParameter);
	var endDate = params.get(endDateTimeParameter);

	if(isNotEmpty(term)){
		if(isEncoded(term)){
			jQuery("#input_search_sanciones").val(decodeURIComponent(term));
		}else{
			jQuery("#input_search_sanciones").val(term);
		}

	}

	if(isNotEmpty(startDate)){
		jQuery("#datarangef1").val(convertToDatePickerValue(startDate));
	}

	if(isNotEmpty(endDate)){
		jQuery("#datarangef2").val(convertToDatePickerValue(endDate));
	}
}

/*
 * Pinta todos los inputs hidden finales a ser enviados por formulario tras
 * revisar que los datos filtrados son correctos
 */
function printAllInputsHiddenForm(param,lang,limit,page,objTipo,objTema,DateFrom,DateTo){

	jQuery("#filterSearchForm").append(param);
	jQuery("#filterSearchForm").append(lang);
	jQuery("#filterSearchForm").append(limit);
	jQuery("#filterSearchForm").append(page);
	jQuery.each(objTipo,function(index,value){
		jQuery("#filterSearchForm").append(value);
	});

	jQuery.each(objTema,function(index,value){
		jQuery("#filterSearchForm").append(value);
	});
	if(DateFrom){
		jQuery("#filterSearchForm").append(createObjtDateFrom(DateFrom));
	}
	if(DateTo){
		jQuery("#filterSearchForm").append(createObjDateTo(DateTo));
	}
}

function pshowErrorMessageGeneralSearchEngine(errorMessage){
    cleanUpErrorMessageSpecificSearchEngine();
	jQuery('div.form-input div.form-input__icon').append(templateInputValidationErrorMessage(errorMessage));
}

function cleanUpErrorMessageSpecificSearchEngine(){
	jQuery('.form-input p.block-message__text').remove();
	jQuery('.form-input p.input-error-message').remove();
}

async function getDataRequest(){
	var dataRequest="";

	/*
	 * Generacion dataRequest de parámetros a ser usados en la búsqueda en la
	 * llamada a la API de AddSearch
	 */
	if(params.has(termParameter)){
		dataRequest += '&term='+params.get(termParameter);
	}
	if(isNotEmpty(langPage)){
		dataRequest += '&lang='+langPage;
	} else {
		dataRequest += '&lang=es';
	}
	if(!params.has(fuzzyParameter) || !isNotEmpty(fuzzyParameter)){
		dataRequest += '&fuzzy=auto';
	} else {
		dataRequest += '&fuzzy='+params.get(fuzzyParameter);
	}
	if(!params.has(collectAnalyticsParameter) || !isNotEmpty(collectAnalyticsParameter)){
		dataRequest += '&collectAnalytics=false';
	}else {
		dataRequest += '&collectAnalytics='+params.get(collectAnalyticsParameter);
	}
	if(!params.has(postfixWildcardParameter) || !isNotEmpty(postfixWildcardParameter)){
		dataRequest += '&postfixWildcard=false';
	}else {
		dataRequest += '&postfixWildcard='+params.get(postfixWildcardParameter);
	}
	if(params.has(limitParameter) && isNotEmpty(params.get(limitParameter))){
		dataRequest += '&limit='+params.get(limitParameter);
	}
	if(params.has(sortParameter) && isNotEmpty(params.get(sortParameter))){
		dataRequest += '&order='+params.get(sortParameter).toLowerCase();
	}

	if (params.has(relevanceParameter) && isNotEmpty(params.get(relevanceParameter))){
		dataRequest += '&sort='+ relevanceParameter;
	}

	if(jQuery('#input_search').length > 0){
		if (params.has(relevanceParameter) == false) {
			dataRequest += '&sort='+getDateFilterFieldParameter();
		}        
        params.set("Channel", getChannelParameter());
	} else if (jQuery('#search').length > 0) {
		if (params.has(relevanceParameter) == false) {
			dataRequest += '&sort=' + getDateFilterFieldParameter();
		}
	}
	if(params.has(pageParameter) && isNotEmpty(params.get(pageParameter))){
		dataRequest += '&page='+params.get(pageParameter);
	}

	// necesario para las convocatorias
	if(params.has(typeParameter) && isNotEmpty(params.get(typeParameter))){
		dataRequest += '&type='+params.get(typeParameter);
	}
	

	var queryStringCustomField = await createQueryStringParams();
	if(isNotEmpty(queryStringCustomField)){
		dataRequest += queryStringCustomField;
	}

	return dataRequest;
}

function parseMonthDateParameter(MMYYYY){
	// we subtract 1 because Date object months use 0-11 (0=Jan, 1=Feb, 2=Mar,
	// 3=Apr, 4=May, 5=Jun, 6=Jul, 7=Aug, 8=Sep, 9=Oct, 10=Nov, 11=Dec)
	return parseInt(MMYYYY.substring(0,2))-1;
}

function parseYearDateParameter(MMYYYY){
	return parseInt(MMYYYY.substring(2,6));
}

function getDatePickerFormat(date){
	return date.toLocaleString('pt', { year: 'numeric', month: '2-digit' });
}

function getAddSearchFormat(date){
	return date.toLocaleString('af', { year: 'numeric', month: '2-digit', day: '2-digit' });
}

function convertToDatePickerValue(MMYYYY){
	return getDatePickerFormat(new Date(parseYearDateParameter(MMYYYY), parseMonthDateParameter(MMYYYY)));
}

function getLastDayOfPrevMonth(MMYYYY){
	return getAddSearchFormat(new Date(parseYearDateParameter(MMYYYY), parseMonthDateParameter(MMYYYY), 0));
}

function getFirstDayOfNextMonth(MMYYYY){
	return getAddSearchFormat(new Date(parseYearDateParameter(MMYYYY), parseMonthDateParameter(MMYYYY) + 1, 1));
}

function getFirstDayOfMonth(MMYYYY){
	return getAddSearchFormat(new Date(parseYearDateParameter(MMYYYY), parseMonthDateParameter(MMYYYY), 1));
}

function getLastDayOfMonth(MMYYYY){
	return getAddSearchFormat(new Date(parseYearDateParameter(MMYYYY), parseMonthDateParameter(MMYYYY) + 1, 0));
}

function setInputSearchValue(){
	if (((typeof(cmsSearchEnabled) !== 'undefined' && cmsSearchEnabled == 'false') || forceAddSearch) && params.get(termParameter) == '*') {
		jQuery("#input_search").val("");
	}
    else{
		jQuery("#input_search").val(params.get(termParameter));
	}
	
}

function setInputSearchValueSanciones(){
	jQuery("#input_search_sanciones").val(params.get(termParameterSanciones));
}

function hasValidInputSearch(){
    if(jQuery('#input_search').length > 0 && jQuery('#input_search').val().length > 0){
        return true;
    } else {
        return false;
    }
}

function hasValidInputSearchSanciones(){
    if(jQuery('#input_search_sanciones').length > 0 && jQuery('#input_search_sanciones').val().length > 0){
        return true;
    } else {
        return false;
    }
}

function isPreviewInstance(){
    if(hasValidInputSearch()){
        if(typeof isMgmt !== 'undefined' && isMgmt){
            pshowErrorMessageGeneralSearchEngine(previewIsNotAllowed.get(langPage));
            return true;
        }
	}
}

function inputSearchLengthIsGreaterThan3(){
	cleanUpErrorMessageSpecificSearchEngine();
    if(hasValidInputSearch() && jQuery('#input_search').val().length < 3){
        pshowErrorMessageGeneralSearchEngine(textoCorto.get(langPage));
        return false;
    }
	return true;
}

function inputSearchLengthIsGreaterThan3Sanciones(){
	cleanUpErrorMessageSpecificSearchEngine();
    if(hasValidInputSearchSanciones() && jQuery('#input_search_sanciones').val().length < 3){
        pshowErrorMessageGeneralSearchEngine(textoCorto.get(langPage));
        return false;
    }
	return true;
}

function addKeyPressListenerInputSearch(){
    var input = document.getElementById("input_search");
    if(input != null){
        input.addEventListener("keypress", function(event) {
          if (event.key === "Enter") {
            event.preventDefault();
            inputSearchLengthIsGreaterThan3();
            jQuery("form .block-search-header__filters__more button").click();
          }
        });
    }
}

function addKeyUpAutoCompleteGeneralSearchListenerHeader(){
    var myTextBox = document.getElementById('searchField');
    if(myTextBox != null){
        let timeout = null;
        myTextBox.addEventListener('keyup', function(){
            clearTimeout(timeout);
            timeout = setTimeout(function () {
                let term = myTextBox.value;
                if(term.length > 0){
                    jQuery(".main-header").addClass("main-header--form-active");
                    client.suggestions(term, callbackAutocompleteHeader);
                }else{
                    jQuery(".main-header").removeClass("main-header--form-active");
                    jQuery('#autocompleteBoxHeader').html('');
                }
            }, 500);
        });
    }
}

function addKeyUpAutoCompleteGeneralSearchListener(){
    var myTextBox = document.getElementById('search');
    if(myTextBox != null){
        let timeout = null;
        myTextBox.addEventListener('keyup', function(){
            clearTimeout(timeout);
            timeout = setTimeout(function () {
               let term = myTextBox.value;
               if(term.length > 0){
                   jQuery(".block-search").addClass("block-search--form-active");
                   client.suggestions(term, callbackAutocomplete);
               }else{
                   jQuery(".block-search").removeClass("block-search--form-active");
                   jQuery('#autocompleteBox').html('');
               }
            }, 500);
        });
    }
}

function getCurrentAddSearchChannel(){

	if(typeof customChannelMeta !== 'undefined' && isNotEmpty(customChannelMeta)){
		return customChannelMeta;
	}
	
	var metaChannel = jQuery("meta[name='addsearch-custom-field'][content*='Channel']");	
	if(metaChannel.length == 0){
		metaChannel = jQuery("meta[name='channel-custom-field'][content*='Channel']");
	}
	
	if(metaChannel.length > 0){
		var channels = metaChannel.attr("content").replaceAll("Channel=","").split(";");
		return channels[channels.length-1];
	}
    return "";
}

function getChannelParameter(){
	var currentChannelMeta = getCurrentAddSearchChannel();
	if(isNotEmpty(currentChannelMeta)){
	    return currentChannelMeta;
	}
	return "";
}

function setTermParameter(){
	var inputSearch = jQuery('#input_search').length > 0 ? jQuery('#input_search').val() : "";
	if(isNotEmpty(inputSearch)){
		var sanitizedInputSearch = DOMPurify.sanitize(inputSearch);
		params.set(termParameter, sanitizedInputSearch);
	}
}

function setTermParameterSanciones(){
	var inputSearch = jQuery('#input_search_sanciones').length > 0 ? jQuery('#input_search_sanciones').val() : "";
	if(isNotEmpty(inputSearch)){
		var sanitizedInputSearch = DOMPurify.sanitize(inputSearch);
		params.set(termParameterSanciones, sanitizedInputSearch);
	}
}

function contentBelongsToCurrentChannel(hit){
    var channels = hit.custom_fields.hasOwnProperty("Channel") ? hit.custom_fields.Channel : [];
    if(channels.length > 0){
        return channels[channels.length-1] === getCurrentAddSearchChannel() ? true : false;
    }
    return false;
}

function getDateFilterFieldParameter(){
	if(typeof dateFilterFieldQuery !== 'undefined'){
		return dateFilterFieldQueryMap.get(dateFilterFieldQuery);
	} else {
	    return dateFilterFieldQueryMap.get("FECHAMODI");
	}
}

function isEventBlock(){
    return jQuery("ul.block-schedule__events").length > 0 ? true : false;
}


function performConvocatoriasSearchEngine() {
	if (document.getElementById('form-convocatorias') == undefined) {
		return;
	}
	else {
		setURLParameters();

		if(jQuery('#input_search').length > 0){
			updateFiltersMenuBasedOnURL();
			var promotedLinks = 0;
			if (isNotEmpty(params.get(termParameter))){
				setInputSearchValue();
				if (!inputSearchLengthIsGreaterThan3()){ return; }
				if (isPreviewInstance()){ return; }
				cleanupBlockSearchContent();
				detachBlockPagination();
				// obligamos a que solo se busquen instancias de contenido de
				// tipo convocatoria
				params.set(contentTypeInstanceParameter,'BDE_CONVOCATORIA_MULTIIDIOMA');
				let currentDate = new Date(); 
				let currentIsoDate = currentDate.toISOString();
				
				if(estoyEnConvocatoriasCerradas()){
					params.set(limitDateCerradasParameter,currentIsoDate);
				}else if(estoyEnConvocatoriasAbiertas()){
					params.set(limitDateAbiertasParameter,currentIsoDate);
				}
				callAjaxAddSearch().then(function(json){
					hideAddSearchMessage();
					promotedLinks = json.hits.filter((a) => a.type === "PROMOTED").length;
					printResultsNumber(json.total_hits-promotedLinks);
					var numeroConvocatorias = json.total_hits-promotedLinks;
					renderJSONResultsConvocatoriasSearch(json,numeroConvocatorias);
					showRRSS();
		    }).catch(function(err) {
		    	hideSpinner();
		    	console.error("Error llamada ajax", err);
		    	pshowErrorMessageGeneralSearchEngine(servicioNoDisponible.get(langPage));
		    	showAddSearchMessage();
		    });
	
			}
		}
	}
}

function performLatestAcademicPublicationsSearchEngine() {
	if(jQuery('#input_search').length > 0 && jQuery('#form-latest-academic-publications').length > 0){
		updateFiltersMenuBasedOnURL();
		if(isNotEmpty(params.get(termParameter))){
			setInputSearchValue();
			if(!inputSearchLengthIsGreaterThan3()){ return; }
			if(isPreviewInstance()){ return; }
			cleanupBlockSearchContent();
			detachBlockPagination();
			callAjaxAddSearch().then(function(json){
				hideAddSearchMessage();
			    promotedLinks = json.hits.filter((a) => a.type === "PROMOTED").length;
				printResultsNumber(json.total_hits-promotedLinks);
				renderJSONResultsLatestAcademicPublicationsSearch(json);
				showRRSS();
		    }).catch(function(err) {
		    	hideSpinner();
		    	console.error("Error llamada ajax", err);
		    	pshowErrorMessageGeneralSearchEngine(servicioNoDisponible.get(langPage));
		    	showAddSearchMessage();
		    });

		}
	}
	
}

function performBlogSearchEngine() {
	if (document.getElementById('form-blog') == undefined) {
		return;
	}
	else if (params.has(pageParameter)){
		var promotedLinks = 0;
		if (typeof(cmsSearchEnabled) !== 'undefined' && cmsSearchEnabled == 'false') {
			// send always the request to addSearch, even if there is no
			// input_search
			if (!isNotEmpty(params.get(termParameter)) || params.get(termParameter) ==='null') {
				params.set(termParameter, "*");
			}
			updateFiltersMenuBasedOnURL();
			setInputSearchValue();
		}
		else {
			setURLParameters();	
			if(jQuery('#input_search').length > 0){
				updateFiltersMenuBasedOnURL();				
				if (isNotEmpty(params.get(termParameter))){
					setInputSearchValue();
					if (!inputSearchLengthIsGreaterThan3()){ return; }	
				}
			}
		}
		if (isPreviewInstance()){ return; }
		cleanupBlockSearchContent();
		detachBlockPagination();
		callAjaxAddSearch().then(function(json){
			hideAddSearchMessage();
			promotedLinks = json.hits.filter((a) => a.type === "PROMOTED").length;
			printResultsNumber(json.total_hits-promotedLinks);
			var numeroArticulosBlog = json.total_hits-promotedLinks;
			renderJSONResultsBlogSearch(json,numeroArticulosBlog);
			showRRSS();
		}).catch(function(err) {
	    	hideSpinner();
	    	console.error("Error llamada ajax", err);
	    	pshowErrorMessageGeneralSearchEngine(servicioNoDisponible.get(langPage));
	    	showAddSearchMessage();
	    });
	}
}

function performSpecificSearchEngine(){
	if (document.getElementById('input_search') == undefined) {
		return;
	}

	if (document.getElementById('form-convocatorias')!= undefined) {
		return;
	}
	
	if (document.getElementById('form-blog') != undefined) {
		return;
	}
	
	if (document.getElementById('form-latest-academic-publications') != undefined) {
		return;
	}
	
	else {
		renderThemesListForSpecificSearchEngine('select-theme');
		setURLParameters();
		var promotedLinks = 0;
        if (params.get(themeParameter) != null && params.get(themeParameter) != ''){
			forceAddSearch = true;
		}
		if (typeof(cmsSearchEnabled) !== 'undefined' && cmsSearchEnabled == 'false' || forceAddSearch) {
			// send always the request to addSearch, even if there is no
			// input_search
			if (!isNotEmpty(params.get(termParameter)) || params.get(termParameter) ==='null') {
				params.set(termParameter, "*");
			}
			updateFiltersMenuBasedOnURL();
			setInputSearchValue();
			if (isPreviewInstance()){ return; }
			cleanupBlockSearchContent();
			detachBlockPagination();
			callAjaxAddSearch().then(function(json){
				hideAddSearchMessage();
				promotedLinks = json.hits.filter((a) => a.type === "PROMOTED").length;
				printResultsNumber(json.total_hits-promotedLinks);
				renderJSONResultsSpecificSearch(json);
				showRRSS();
			}).catch(function(err) {
			    	hideSpinner();
			    	console.error("Error llamada ajax", err);
			    	pshowErrorMessageGeneralSearchEngine(servicioNoDisponible.get(langPage));
			    	showAddSearchMessage();
			    });
		}
		else {

			if (jQuery('#input_search').length > 0){
				updateFiltersMenuBasedOnURL();

				if (isNotEmpty(params.get(termParameter))){
					setInputSearchValue();
					if (!inputSearchLengthIsGreaterThan3()){ return; }
					if (isPreviewInstance()){ return; }
					cleanupBlockSearchContent();
					detachBlockPagination();
					callAjaxAddSearch().then(function(json){
						hideAddSearchMessage();
						promotedLinks = json.hits.filter((a) => a.type === "PROMOTED").length;
						printResultsNumber(json.total_hits-promotedLinks);
						renderJSONResultsSpecificSearch(json);
						showRRSS();
			    }).catch(function(err) {
			    	hideSpinner();
			    	console.error("Error llamada ajax", err);
			    	pshowErrorMessageGeneralSearchEngine(servicioNoDisponible.get(langPage));
			    	showAddSearchMessage();
			    });
		
				}
			}
		}
	}
}

function valDatesAreDifferentSanciones(){
	var result = false;
	var queryString = window.location.search;
	var urlParams = new URLSearchParams(queryString);
	var start = urlParams.get('start');
	var end = urlParams.get('end');
	var startInput = jQuery('#datarangef1').val();
	var endInput = jQuery('#datarangef2').val();
	if(startInput != undefined && startInput != null && startInput != ''){
		startInput = startInput.replace('/','');
	}
	if(endInput != undefined && endInput != null && endInput != ''){
		endInput = endInput.replace('/','');
	}
	if((startInput != '' && startInput != start) || (endInput != '' && endInput != end)){
		result = true;
	}
	return result;
}


function performSpecificSearchEngineSanciones(){
	if (document.getElementById('input_search_sanciones') == undefined) {
		return;
	}
	
	else {
		setURLParametersSanciones();

		if(jQuery('#input_search_sanciones').length > 0){
			updateFiltersMenuBasedOnURLSanciones();

			if (isNotEmpty(params.get(termParameterSanciones))){
				setInputSearchValueSanciones();
				if (!inputSearchLengthIsGreaterThan3Sanciones()){ return; }
				filter();
			}
		}
	}
}

function performGeneralSearchEngine(){
	if (document.getElementById('generic-search') == undefined) {
		return;
	}
	else {
		renderThemesListForGenericSearchEngine('generic-search');
		if (jQuery('#generic-search').length > 0){
	
			jQuery('#headingFive').parent().hide();
	
			/*
			 * Llamada AJAX a la API con la URL y los parámetros de filtrado
			 * necesarios
			 */
			if(params.get(termParameter) == null || !isNotEmpty(params.get(termParameter))){
				jQuery("#addSearchMessage").html(resultadoNoEncontrado.get(langPage));
				showAddSearchMessage();
			} else {
				if(!isEncoded(getTerm())){
					params.set(termParameter, encodeURIComponent(getTerm()));	
				}else{
					params.set(termParameter, decodeURIComponent(getTerm()));
					window.history.replaceState(null, null, "?" + params.toString());
				}
				let selectedOrder = document.getElementById("select-order") != null && document.getElementById("select-order").value || "";
				if (selectedOrder === relevanceParameter) {
					params.set(relevanceParameter, "true");
				}
			    callAjaxAddSearch().then(function(json){
					if (!isNotEmpty(json) || json.total_hits === 0){
						jQuery("#addSearchMessage").html(resultadoNoEncontrado.get(langPage));
						showAddSearchMessage();
					}
					else{
						hideAddSearchMessage();
						promotedLinks = json.hits.filter((a) => a.type === "PROMOTED").length;
						printResultsNumber(json.total_hits - promotedLinks);
						renderJSONResultsGenericSearchEngine(json);
					}

		    }).catch(function(err) {
		    	hideSpinner();
		    	console.error("Error llamada ajax", err);
					jQuery("#addSearchMessage").html(servicioNoDisponible.get(langPage));
					showAddSearchMessage();
		    });
	
			    updateFiltersMenuBasedOnURL();
			}
		}
	}
}

function addEventClickSuggestedSearch(){
	jQuery("div#suggestedSearch").on("click", "span.block-search-result__intro a", function (event) {
		event.preventDefault();
		var suggestion = $(this).text();
		var urlParams = new URLSearchParams(window.location.search);
		urlParams.set(termParameter, encodeURIComponent(suggestion));
		urlParams.set(pageParameter, 1);
		window.history.replaceState(null, null, "?" + urlParams.toString());
		window.location.reload();
	});
}

function addEventClickPaginationResults(){
	// Funciones eventos del selector de resultados por página y re-llamada
	// AJAX.
	jQuery("#paginationAddSearchResults").on("change", "#select-pagination", function () {
		let valueSelected = jQuery("#select-pagination option:selected").val();
		params.set(limitParameter, valueSelected);
		params.set(pageParameter, 1);
		let urlOrigin = window.location.pathname;
		removeAllResultsBeforeReCharge();
		window.location.href = urlOrigin + '?' + params.toString();
	});
}

function addEventSearchResultsSendStats(numberOfResults){
	let sectionNameTag = getSectionName();
	let term = getTerm();
    if(term != null && isEncoded(term)){
    	term = decodeURIComponent(term);
    }
    if(term != null && typeof term !== 'undefined' && term.length > 2){
    	if (sectionNameTag != null){
    		client.setAnalyticsTag(sectionNameTag);
    	}
    	client.sendStatsEvent('search', term, {numberOfResults: numberOfResults});
    }
}

/*función para eventos de clickado para recoger analítica y enviarla a AddSearch por API.*/
function addEventClickResultsSendStats(){
	let sectionNameTag = getSectionName();
	  /*Promocionados*/
    jQuery(document).on("pointerdown", ".block-search-result--highlighted p.block-search-result__title", function(event) {
        var documentId = jQuery(this).children('a').data('id');
        var positionDocument = jQuery(this).children('a').data('position');
        let term = getTerm();
        if(term != null && isEncoded(term)){
        	term = decodeURIComponent(term);
        }
        if(term != null && typeof term !== 'undefined' && term.length > 2){
        	if (sectionNameTag != null){
        		client.setAnalyticsTag(sectionNameTag);
        	}
        	client.sendStatsEvent('click', term, {documentId: documentId, position: positionDocument});
        }
    });
    jQuery(document).on("keydown", ".block-search-result--highlighted p.block-search-result__title", function(event) {
    	// Verificamos si la tecla presionada es Enter 
        if (event.keyCode === 13) {
	        var documentId = jQuery(this).children('a').data('id');
	        var positionDocument = jQuery(this).children('a').data('position');
	        let term = getTerm();
	        if(term != null && isEncoded(term)){
	        	term = decodeURIComponent(term);
	        }
	        if(term != null && typeof term !== 'undefined' && term.length > 2){
	        	if (sectionNameTag != null){
	        		client.setAnalyticsTag(sectionNameTag);
	        	}
	        	client.sendStatsEvent('click', term, {documentId: documentId, position: positionDocument});
	        }
        }
    });
   /*Buscador general y específicos*/
    jQuery(document).on("pointerdown", ".block-search-result__content p.block-search-result__title", function(event) {
        var documentId = jQuery(this).children('a').data('id');
        var positionDocument = jQuery(this).children('a').data('position');
        let term = getTerm();
        if(term != null && isEncoded(term)){
        	term = decodeURIComponent(term);
        }
        if(term != null && typeof term !== 'undefined' && term.length > 2){
        	if (sectionNameTag != null){
        		client.setAnalyticsTag(sectionNameTag);
        	}
        	client.sendStatsEvent('click', term, {documentId: documentId, position: positionDocument});
        }
    });
    jQuery(document).on("keydown", ".block-search-result__content p.block-search-result__title", function(event) {
    	// Verificamos si la tecla presionada es Enter 
        if (event.keyCode === 13) {
	        var documentId = jQuery(this).children('a').data('id');
	        var positionDocument = jQuery(this).children('a').data('position');
	        let term = getTerm();
	        if(term != null && isEncoded(term)){
	        	term = decodeURIComponent(term);
	        }
	        if(term != null && typeof term !== 'undefined' && term.length > 2){
	        	if (sectionNameTag != null){
	        		client.setAnalyticsTag(sectionNameTag);
	        	}
	        	client.sendStatsEvent('click', term, {documentId: documentId, position: positionDocument});
	        }
        }
    });
    /*Buscador convocatorias*/
    jQuery(document).on("pointerdown", "table td", function(event) {
        var documentId = jQuery(this).children('a').data('id');
        var positionDocument = jQuery(this).children('a').data('position');
        let term = getTerm();
        if(term != null && isEncoded(term)){
        	term = decodeURIComponent(term);
        }
        if(term != null && typeof term !== 'undefined' && term.length > 2){
        	if (sectionNameTag != null){
        		client.setAnalyticsTag(sectionNameTag);
        	}
        	client.sendStatsEvent('click', term, {documentId: documentId, position: positionDocument});
        }
	 });
    jQuery(document).on("keydown", "table td", function(event) {
    	// Verificamos si la tecla presionada es Enter 
        if (event.keyCode === 13) {
	        var documentId = jQuery(this).children('a').data('id');
	        var positionDocument = jQuery(this).children('a').data('position');
	        let term = getTerm();
	        if(term != null && isEncoded(term)){
	        	term = decodeURIComponent(term);
	        }
	        if(term != null && typeof term !== 'undefined' && term.length > 2){
	        	if (sectionNameTag != null){
	        		client.setAnalyticsTag(sectionNameTag);
	        	}
	        	client.sendStatsEvent('click', term, {documentId: documentId, position: positionDocument});
	        }
        }
	 });
}

function addEventClickFormCheckInput(){
	/*
	 * Evento para mostrar checked al elemento seleccionado de los filtros
	 * laterales
	 */
	jQuery(".form-check-input").on("click", function () {
		jQuery(this).is(':checked') ? jQuery(this).attr( 'checked', true ) : jQuery(this).removeAttr('checked');
	});
}

function addEventClickClearFilters(){
	/* Evento funcion onclick limpiarFiltros laterales */
	jQuery("#clearFilters").on("click", function () {
		jQuery(".form-check-input").removeAttr('checked');
		let temasBuscadorGeneral =jQuery("#generic-search");
		if (temasBuscadorGeneral != null && temasBuscadorGeneral != undefined) {
			temasBuscadorGeneral = temasBuscadorGeneral.select2ToTree();
			temasBuscadorGeneral.val("[]");
			temasBuscadorGeneral.trigger('change');
		}

		jQuery("#datarangef2").val('');
		jQuery("#datarangef1").val('');
		return false;
	});
}

function addEventClickSubmitFilters(){
	/*
	 * Evento funcion onClick de botón de Filtrar menu lateral Si las fechas
	 * introducidas son correctas llama a función de pintado de inputs hidden en
	 * el formulario para ser enviados. Si las fechas son incorrectas, muestra
	 * mensaje por alert.
	 */
	jQuery("#submitFilters").on("click", function () {
		if(validateDates(getDateFrom(),getDateTo())){
			printAllInputsHiddenForm(
					createObjParam(params.get(termParameter)),
					createObjLang(langPage),
					createObjlimit(params.get(limitParameter) ? params.get(limitParameter) : '10'),
					createObjPage(1),
					createArrayFilterTipo(),
					createArrayFilterTema(),
					getDateFrom(),
					getDateTo()
			);
		}
		else{
			jQuery('#datarangef1').parent().append(templateInputValidationErrorMessage(fechaInicioSuperiorFechaFinal.get(langPage)));
			return false;
		}
	});
}

function addEventClickSearchBoxButton(){
	jQuery("form .block-search__box__row button").on("click", function () {
		toggleLoading();
	});
}


function addEventSanitizeBeforeSendForm(){
	jQuery('#searchFormAddSearch').submit(function() {
	   	var inputSearch = jQuery('#search').length > 0 ? jQuery('#search').val() : "";
		if(isNotEmpty(inputSearch)){
			var sanitizedInputSearch = DOMPurify.sanitize(inputSearch);
			jQuery('#search').val(sanitizedInputSearch);
		}
	    return true; // return false to cancel form action
	});
	
	jQuery('.main-header__search__form').submit(function() {
	   	var inputSearch = jQuery('#searchField').length > 0 ? jQuery('#searchField').val() : "";
		if(isNotEmpty(inputSearch)){
			var sanitizedInputSearch = DOMPurify.sanitize(inputSearch);
			jQuery('#searchField').val(sanitizedInputSearch);
		}
	    return true; // return false to cancel form action
	});
	
}

function addEventClickLinkAutoCompletedElement(){
    /*
	 * Evento que añade al input de busqueda el elemento seleccionado del
	 * autocompletado
	 */
    jQuery(document).on('click', 'a[class^=element]', function(){
        jQuery('#search').val(jQuery(this).text());
        jQuery("#buttonSearch").click();
    });
}

function addEventClickLinkAutoCompletedElementHeader(){
    /*
	 * Evento que añade al input de busqueda de la cabecera el elemento
	 * seleccionado del autocompletado
	 */
    jQuery(document).on('click', 'a[class^=element]', function(){
        jQuery('#searchField').val(jQuery(this).text());
        jQuery(".main-header__search__form").submit();
    });
}

function getSectionName(){
	let sectionNameTag;
	if (typeof fields !== 'undefined' && fields != null && fields.pageType != "" && fields.bu_Hierarchy2 != ""){
		let catName = fields.bu_Hierarchy2.normalize('NFD').replace(/([aeio])\u0301|(u)[\u0301\u0308]/gi,"$1$2").normalize();
		if (fields.pageType == "Busca"){
			sectionNameTag = "Buscador general";
		}else{
			sectionNameTag = "Buscador especifico - " + catName;
		}
	}
	return sectionNameTag;
}

function updateWindowHistoryParameters(){
    params = new URLSearchParams(getParameters());
    params.set(termParameter, getTerm());
    window.history.replaceState(null, null, '?' + params.toString());
}

function updateWindowHistoryParametersSanciones(){
    params = new URLSearchParams(getParametersSanciones());
    params.set(termParameterSanciones, getTermSanciones());
    window.history.replaceState(null, null, '?' + params.toString());
}

function addEventClickPaginationLink(){
    jQuery(document).on('click', '.block-pagination__numbers a', function(event){
        event.preventDefault();
        var href = jQuery(this).attr("href");
        if (href != null && href != ''){
	        var pageParameters = new URLSearchParams(href.split("?").length > 0 ? href.split("?")[1] : "page=1");
	        if(jQuery('#input_search').length > 0 && jQuery('#input_search').val().length > 0){
	            params.set(pageParameter, pageParameters.get(pageParameter));
	            updateWindowHistoryParameters();
	            scrollIntoViewListResults();
	            performSpecificSearchEngine();
	            performConvocatoriasSearchEngine();
	            performBlogSearchEngine();
	            performLatestAcademicPublicationsSearchEngine();
	        } else {
	            window.location = href;
	        }
        }
    });
}

function addEventClickFormSpecificSearch(){
	jQuery("#formSpecificSearch").on('click', 'button', function(event){
		event.preventDefault();
		setTermParameter();
		setInputSearchValue();
		setURLParameters();
		window.location = jQuery("#formSpecificSearch").attr("action") + "?" + getParameters();
	});

	jQuery("#search-filter-form").on('click', 'button', function(event){
		event.preventDefault();
		setTermParameter();
		setInputSearchValue();
		setURLParameters();
		// seteamos el parámetro 'relevance' si no viene ordenación
		if ((!params.has(sortParameter)) && (!params.has(relevanceParameter))) {
			params.set(relevanceParameter,'true');
		}
		window.location = jQuery("#search-filter-form").attr("action") + "?" + getParameters();
	});
}

function getJSONSubArray(arrName, subArrName){
  for(var i=0; i<arrName.length; i++){
      if(typeof arrName[i][subArrName] === 'object'){
          return arrName[i][subArrName];
      }
  }
  return [];
}

function appendOptionThemeSelect(newOption){
	jQuery('#select-theme').append(newOption).trigger('change');
}

function cleanUpOptionsThemeSelect(){
	jQuery("#select-theme").empty().trigger("change");
	jQuery("#select-theme").append('<option value=" " class="l1">'+ themePlaceholder.get(langPage) +'</option>');
}

function removeThemeSelect(){
	jQuery("#select-theme").parent().detach();
}

function getNewOptionThemeSelect(id, text){
	return new Option(text, id, false, false);
}

/**
 * Obtiene los temas y subtemas corrspondientes al canal (channelId) del JSON y 
 * jerarqiza el resultado, añadiendo además los padres de los temas que no lo tuvieran
 */
function getThemeListFromChannel(channelId, jsonData) {
    // Buscamos y extraemos el channelId en el JSON para solo tratar ese canal
    const channelData = jsonData[1].channelsThemeCategories.find(channel => channel.channelId === channelId);
    if (!channelData) {
        // Si no encontramos en el canal devolvemos el objeto vacío
        return {};
    }
    const resultObject = mergeInfoThemesAndSubthemes(channelData, jsonData);
    const hierarchicalResult = hierarchizeThemesAndSubthemes(resultObject, false);

    return hierarchicalResult;
}

/**
 * Obtiene los RCRD de los temas/subtemas de themeCategories y la cruza para con
 * la información de themesAndSubthemes para crear un objeto completo
 */
function mergeInfoThemesAndSubthemes(channelData, jsonData) {
    const resultObject = {};
    for (var i = 0; i < channelData.themeCategories.length; i++) {
        //console.log('Tratando: ' + channelData.themeCategories[i].themeCategoryId)
        if (channelData.themeCategories[i].themeCategoryParentId != null && channelData.themeCategories[i].themeCategoryParentId != ""){
        	// Si el padre de un tema no esta en el listado, lo añadimos para obtener su información y jerárquizarlo más adelante
            const parentId = channelData.themeCategories.find(theme => theme.themeCategoryId === channelData.themeCategories[i].themeCategoryParentId);
            if (parentId == null) {
                channelData.themeCategories.push({themeCategoryId: channelData.themeCategories[i].themeCategoryParentId});
                //console.log('Añadiendo padre: ' + channelData.themeCategories[i].themeCategoryParentId);
            }
        }
        // Filtramos el tema en la sección themesAndSubthemes del JSON para obtener su información
        const themeCategoryId = channelData.themeCategories[i].themeCategoryId;
        const themeCategoryCount = channelData.themeCategories[i].hasOwnProperty("themeCategoryCount") ? channelData.themeCategories[i].themeCategoryCount : 0;
        const themeInfo = jsonData[2].themesAndSubthemes.find(theme => theme.themeCategoryId === themeCategoryId);
        if (themeInfo) {
            resultObject[themeInfo.themeCategoryId] = {
                themeCategoryId: themeInfo.themeCategoryId,
                themeCategoryParentCode: themeInfo.themeCategoryParentCode,
                themeCategoryParentId: themeInfo.themeCategoryParentId,
                text_es: themeInfo.text_es,
                text_en: themeInfo.text_en,
                cod: themeInfo.cod,
                count: themeCategoryCount
            };
        }
    }
    return resultObject;
}

/**
 * Jerarquiza los temas y subtemas
 */
function hierarchizeThemesAndSubthemes(data, hasCount) {
    // Creamos un diccionario para almacenar las referencias de cada tema y sus subtemas hijos
    let themeCategoryDict = {};
    for (const themeId in data) {
        const themeInfo = data[themeId];
        themeCategoryDict[themeId] = {
            themeCategoryId: themeId,
            text_es: themeInfo.text_es,
            text_en: themeInfo.text_en,
            cod: themeInfo.cod,
            count: themeInfo.count,
            children: []
        };
    }
    // Creamos y rellenamos el objeto jerárquizado
    let hierarchicalObject = [];
    for (const themeId in data) {
        const parentCategoryId = data[themeId].themeCategoryParentId;
        if (parentCategoryId && themeCategoryDict[parentCategoryId]) {
        	// Si es un hijo lo incluimos en el array children
            themeCategoryDict[parentCategoryId].children.push(themeCategoryDict[themeId]);
        } else {
        	// Si es un padre lo incluimos en el objeto principal
            hierarchicalObject.push(themeCategoryDict[themeId]);
        }
    }
    // Ordenamos los temas según el campo 'count' en orden descendente
    if (hasCount != null && hasCount) {
        sortThemesByCount(hierarchicalObject);
    } else {
        sortThemesAlphabetically(hierarchicalObject);
    }
    return hierarchicalObject;
}
/**
 * Ordena los temas según el campo 'count' en orden descendente
 */ 
function sortThemesByCount(themesObject) {
    themesObject.sort((a, b) => b.count - a.count);
    // Recursivamente ordena los temas hijos
    for (const theme of themesObject) {
        if (theme.children.length > 0) {
            sortThemesByCount(theme.children);
        }
    }
}
/**
 * Ordena los temas según el campo 'text_xx' de forma alfabética
 */
function sortThemesAlphabetically(themes) {   
    themes.sort((a, b) => a['text_' + langPage].localeCompare(b['text_' + langPage]));
    // Recursivamente ordena los temas hijos
    for (const theme of themes) {
        if (theme.children.length > 0) {
            sortThemesAlphabetically(theme.children);
        }
    }
}
/**
 * Rellena los combos con los temas y subtemas asociados a la query
 */
function callbackUpdateThemeCategories(json) {
    if (json.length > 0) {
    	// Obtenemos el objeto con la jerarquía correcta de temas/subtemas
        let hierarchicalThemeList = getThemeListFromChannel(getCurrentAddSearchChannel(),json);
        if (Object.keys(hierarchicalThemeList).length > 0) {
            cleanUpOptionsThemeSelect();
            hierarchicalThemeList.forEach(function (item) {
                if (Object.keys(item.children).length > 0) {
                    let childRCRDs = item['themeCategoryId'];
                    item.children.forEach(function (child) {
                       childRCRDs += '-' + child['themeCategoryId'];
                    });
                    jQuery("#select-theme").append('<option childs="' + childRCRDs + '" value="'+ item['themeCategoryId'] +'" class="l1 non-leaf">'+ item['text_' + langPage] +'</option>');
                    item.children.forEach(function (child) {
                        jQuery("#select-theme").append('<option value="'+ child['themeCategoryId'] + '" data-pup="' + item['themeCategoryId'] + '" class="l2">'+ child['text_' + langPage] +'</option>');
                    });
                } else {
                    jQuery("#select-theme").append('<option value="'+ item['themeCategoryId'] +'" class="l1">'+ item['text_' + langPage] +'</option>');
                }
            });
        } else {
            removeThemeSelect();
        }
    } else {
        removeThemeSelect();
    }
    setSelectedParameterForGenericSearch();
    setSelectedParameterForSpecificSearch();
}

function getCategoriesJSON() {
  if (!ajaxJsonPromise) {
    ajaxJsonPromise = new Promise((resolve, reject) => {
      // Realizar la llamada AJAX usando jQuery.getJSON
      $.getJSON(urlCategoriesJSON, (data) => {
		themeCategoryArray = data; 
        resolve(data);
      })
      .fail((error) => {
        reject(error);
      });
    });
  }
  return ajaxJsonPromise;
}

function updateThemeCategories(){
	getCategoriesJSON().then((data) => {
		callbackUpdateThemeCategories(data);
	})    
	.catch((error) => {
		// El recuso no es accesible
		console.log(error.message);
    });  
}

function updateDateFilters(){
	if(typeof sortBean !== 'undefined') {
		if (sortBean != '') {
			document.getElementById('select2-select-order-container').setAttribute('title', sortBean);
			document.getElementById('select2-select-order-container').textContent=sortBean;
			document.getElementById("select-order").value = sortDefault;
		}
	}
	if(typeof $('#datarangef1').val() !== 'undefined') {
		if ($('#datarangef1').val() == $('#datarangef2').val() && $('#datarangef1').val() != '' ) {
			var monthName = getMonthName($('#datarangef1').val().substring(0,2));
			if ($('#list-results h2.title--inside').length > 0) {
				$('#list-results h2.title--inside')[0].textContent = monthName.charAt(0).toUpperCase() + monthName.slice(1);
			}
		}
	}
}

//generación de los temas para el buscador general. Solo en las páginas de
//buscador
function renderThemesListForGenericSearchEngine(id) {
	if (id == null || id == undefined) {
		return;
	}

	let urlFacetsAddSearch = prefixAddSearchUrl;
	if (params.has(termParameter)) {
		if (params.get(termParameter) == '') {
			return;
		}
		urlFacetsAddSearch = urlFacetsAddSearch + "?term=" + encodeURIComponent(params.get(termParameter));
	}
	else {
		urlFacetsAddSearch += "?term=*";
	}

	if (isNotEmpty(langPage)){
		urlFacetsAddSearch = urlFacetsAddSearch +"&lang=" +langPage;
	}
	else {
		urlFacetsAddSearch += "&lang=es";
	}

	if (params.has(channelParameter)){
		let queryFiltroCanales ="&filter={\"and\":[{\"or\":[";
		let listadoCanalesQuery =[];
			jQuery.each(params.getAll(channelParameter),function(index,value){
				listadoCanalesQuery.push("{\"custom_fields.Channel\":\""+value+"\"}");
			});
			queryFiltroCanales += listadoCanalesQuery.join(',');
			queryFiltroCanales += "]}]}";

			urlFacetsAddSearch += queryFiltroCanales;
		}

	urlFacetsAddSearch += "&collectAnalytics=false&fuzzy=auto&facet=custom_fields.Tema&limit=1&page=1";
		
	let localJson;
	
	getCategoriesJSON().then((data) => {
     localJson = data;
    	// Encadenamos una segunda llamada AJAX después del éxito de la primera
		return $.getJSON(urlFacetsAddSearch);
	 })
    .then((addSearchJson) => {
		if(addSearchJson && localJson) {
			//let htmlListadoTemas = '<option value=" " class="l1"></option>';
            let htmlListadoTemas = '';
            let addSearchThemesObject = {};
            addSearchThemesObject['themeCategories'] = [];
            addSearchJson.facets["custom_fields.Tema"].forEach(category => {
                const themeCategoryCod = category.value;
                const themeInfo = localJson[2].themesAndSubthemes.find(theme => theme.cod === themeCategoryCod);
                if (themeInfo) {
                    addSearchThemesObject['themeCategories'].push({
                        themeCategoryId: themeInfo.themeCategoryId,
                        themeCategoryParentCode: themeInfo.themeCategoryParentCode,
                        themeCategoryParentId: themeInfo.themeCategoryParentId,
                        themeCategoryCount: category.count
                    });
                }
            });
            
            const resultObject = mergeInfoThemesAndSubthemes(addSearchThemesObject, localJson);
            const hierarchicalResult = hierarchizeThemesAndSubthemes(resultObject, true);
			
            hierarchicalResult.forEach(function (item) {
                const themeInfo = addSearchJson.facets['custom_fields.Tema'].find(theme => theme.value === item.cod);
                let countHTML = "";
                if (themeInfo != null && themeInfo.count != null){
                    countHTML = ' (' + themeInfo.count + ')';
                }                    
                
                if (Object.keys(item.children).length > 0) {
                    htmlListadoTemas += '<option value2="' + item['themeCategoryId'] + '" value="' + item['cod'] + '" class="l1 non-leaf">' + item['text_' + langPage] + countHTML +'</option>';
                    item.children.forEach(function (child) {
                        const childInfo = addSearchJson.facets['custom_fields.Tema'].find(theme => theme.value === child.cod);
                        let childCountHTML = "";
                        if (childInfo != null && childInfo.count != null){
                            childCountHTML = ' (' + childInfo.count + ')';
                        }   
                        htmlListadoTemas += '<option value2="' + child['themeCategoryId'] + '" value="' + child['cod'] + '" data-pup="' + item['cod'] + '" class="l2">' + child['text_' + langPage] + childCountHTML + '</option>';
                    });
                } else {
                    htmlListadoTemas += '<option value2="' + item['themeCategoryId'] + '" value="' + item['cod'] + '" class="l1">' + item['text_' + langPage] + countHTML +'</option>';
                }
            });

			if(jQuery('#' + id) != undefined) {
                jQuery('#' + id).attr('data-placeholder', selectThemePlaceholder.get(langPage));
				jQuery('#' + id).html(htmlListadoTemas).select2ToTree();
				setSelectedParameterForGenericSearch();
				setSelectedParameterForSpecificSearch();
			}
		}
    })
    .catch((error) => {
		console.log(error.message);
		let htmlListadoTemas = "";
		htmlListadoTemas = "<option value=''></option>";
		jQuery('#' + id).html(htmlListadoTemas);
	 });
}

// generación del HTML los temas del buscador específico (listados) siempre que
// sea necesario.
function renderThemesListForSpecificSearchEngine(id) {
	if (id == null || id == undefined) {
		return;
	}
	getCategoriesJSON().then((localJson) => {
		callbackUpdateThemeCategories(localJson);
	}
	).catch((error) => {
		console.log(error.message);
		let htmlListadoTemas = "<option value=''></option>";
		jQuery('#' + id).attr('data-placeholder', themePlaceholder.get(langPage));
		jQuery('#' + id).html(htmlListadoTemas);
	});
}


$( document ).ready(function() {

  addEventClickFormSpecificSearch();
  addEventClickSuggestedSearch();
  addEventClickPaginationResults();
  addEventClickResultsSendStats();
  addEventClickFormCheckInput();
  addEventClickClearFilters();
  addEventClickSubmitFilters();
  addEventClickSearchBoxButton();
  addEventSanitizeBeforeSendForm();
  addKeyPressListenerInputSearch();
  addKeyUpAutoCompleteGeneralSearchListener();
  addEventClickLinkAutoCompletedElement();
  addKeyUpAutoCompleteGeneralSearchListenerHeader();
  addEventClickLinkAutoCompletedElementHeader();
  addEventClickPaginationLink();
  performGeneralSearchEngine();
  performSpecificSearchEngine();
  performConvocatoriasSearchEngine();
  performBlogSearchEngine();
  performLatestAcademicPublicationsSearchEngine();
  performSpecificSearchEngineSanciones();
  updateThemeCategories();
  updateDateFilters();
});
