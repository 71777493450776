/*global $:false */
var Cookie = {};
(function(){
	"use strict";
	var cookieVersion = "bde_es_clasica",
		cookieVersionMovil = "movil",
		cookieVersionClasica = "clasica";

	this.getVersion = function(){
		var nameEQ = cookieVersion + "=";
		var ca = document.cookie.split(';');
		for(var i=0;i < ca.length;i++) {
			var c = ca[i];
			while (c.charAt(0)==' ') c = c.substring(1,c.length);
			if (c.indexOf(nameEQ) == 0) {return c.substring(nameEQ.length,c.length);}
		}
		return null;
	}

	this.setVersion = function(c_value,c_exdays){
		var expires = "";
		if (c_exdays) {
			var exdate = new Date();
			exdate.setDate(exdate.getDate() + c_exdays);
			expires = "; expires="+exdate.toGMTString();
		}
		
		document.cookie = cookieVersion+"="+c_value+expires+"; path=/; secure";
	}
	
	this.removeClasicVersion = function(c_value){
		var expires = "; expires=Thu, 01 Jan 1970 00:00:00 GMT; path=/; secure";
		document.cookie = cookieVersion+"="+c_value+expires;
	}
	
	this.setVersionMovil = function(){
		this.removeClasicVersion(cookieVersionClasica);
	}

	this.setVersionClasica = function(){
		this.setVersion(cookieVersionClasica,7);
	}

	this.esVersionClasica = function(){
		return this.getVersion() === cookieVersionClasica;
	}

	this.esVersionMovil = function(){
		return !this.esVersionClasica();
	}

}).apply( Cookie );