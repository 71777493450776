var bCookieOk = false;
var sSite = 'wbe';
var sVersionCookie = 'V2';
var sUrlInfo = '/wbe/es/pie/politica-cookies/';
var sTrkEntorno = 'P';


function loadScript( url, callback ) {
  var script = document.createElement( 'script' );
  var dom = window.location.hostname;
  script.type = 'text/javascript';
  script.async = true;
  if(script.readyState) {  // IE 9
    script.onreadystatechange = function() {
      if ( script.readyState === 'loaded' || script.readyState === 'complete' ) {
        script.onreadystatechange = null;
        callback();
      }
    };
  } else {
    script.onload = function() {
      callback();
    };
  }
  if(dom !== 'app.bde.es' && dom !== 'pre.bde.es' && dom !== 'localhost'){
	script.src = url;
	document.getElementsByTagName( 'head' )[0].appendChild( script );
  }
}
jQuery(document).ready(function($){
	loadScript('/f/Sistema/trk/bde-gtm-ga4.js', function() {
		loadScript('/f/Sistema/cookies/cookies.min.v2.js', function() {
			var oCookie={
				   site: sSite,
				   version: sVersionCookie,
				   target: 'body',
				   urlInfo: sUrlInfo,
				   analytics: true,
				   htmCookies : '/f/Sistema/cookies/cookies.html',
				   htmPreferences : '/f/Sistema/cookies/preferences.html',
				   css: '/f/Sistema/cookies/styles-cookies-wbe.css'
			};

			hayCookie(oCookie);
			initT();
		});	

	});
});


function initT(){
	var trk={
	   ent: sTrkEntorno
	   
	};

	if (bCookieOk){
		bde_initGtm(trk);			
	}
}

function doKo(sDonde) {
	if (arguments.length == 0){
		sDonde='all';
	}
	if(sDonde.includes('all') || sDonde.includes('analytics')){
		bCookieOk=false;
	}
}

function doOk(sDonde) {

	if(sDonde.includes('all')||sDonde.includes('analytics')){
		bCookieOk=true;
	}
}

