const urlTestabJSON = "/f/webbe/Sistema/JSON/test_ab_pages.json";
let testabConfig;
function getTestabJSON() {
	$.getJSON(urlTestabJSON, (data) => {
		testabConfig = data;
		initTestabOnPage(testabConfig);
	}).fail((error) => {
		console.log("Error al obtener JSON de configuración para TestAB");
	});
}

function initTestabOnPage(testabConfig) {
	testabConfig['testabEnabled'] = false;
	const debugEnabled = testabConfig.hasOwnProperty('debugEnabled') ? testabConfig.debugEnabled : false;
	const currentUrl = window.location.href;
	const path = new URL(currentUrl).pathname;
	if (!$.isEmptyObject(testabConfig)) {
		const detailPagePaths = testabConfig.hasOwnProperty('detailPagePaths') ? testabConfig.detailPagePaths : null;
		const exactPagePaths = testabConfig.hasOwnProperty('exactPagePaths') ? testabConfig.exactPagePaths : null;
		if (detailPagePaths != null && exactPagePaths != null) {
			const exactPagePath = exactPagePaths.includes(path);
			var isDetailPath = false;
			if (detailPagePaths.some(function (v) { return path.indexOf(v) > -1 })) {
				isDetailPath = true;
			}
			if (exactPagePath || isDetailPath) {

				// Creamos un comentario en el DOM para marcar que se activa el TestAB
				document.body.appendChild(document.createComment("TestAB enabled on page"));
				testabConfig['testabEnabled'] = true;
				if (debugEnabled) console.log("Pagina con TestA/B activado");
				// Obtenemos todas las cookies
				const allPageCookies = document.cookie;

				// Buscamos la cookie con nombre "tab"
				const cookiesArray = allPageCookies.split(';');
				let hasTabCookie = false;
				let audienceValue = "";
				const cookieName = testabConfig.hasOwnProperty('cookieName') ? testabConfig.cookieName : 'tab';

				for (const cookie of cookiesArray) {
					const [name, value] = cookie.trim().split('=');
					if (name === cookieName && (value === '0' || value === '1')) {
						audienceValue = value;
						if (debugEnabled) console.log('Detectada cookie tab con valor: ' + value);
						hasTabCookie = true;
						break;
					}
				}

				if (!hasTabCookie) {
					// Generamos un valor aleatorio '0' o '1' para la audiencia y creamos la cookie
					audienceValue = Math.random() < 0.5 ? '0' : '1';
					const cookiePath = testabConfig.hasOwnProperty('cookiePath') ? testabConfig.cookiePath : '/wbe';
					const cookieMaxAge = testabConfig.hasOwnProperty('maxAge') ? testabConfig.maxAge : '604800';
					const cookieString = cookieName + '=' + audienceValue + '; path=' + cookiePath + '; max-age=' + cookieMaxAge;
					// Establece la cookie
					document.cookie = cookieString;
					if (debugEnabled) console.log('Creada cookie: ' + cookieString);
				}
				testabConfig['audienceValue'] = audienceValue;
			}
		}
	} else {
		if (debugEnabled) console.log('Error en objeto de configuración para TestAB');
	}
}
getTestabJSON();