var Utils = {};
(function(){
	'use strict';
	var $modulosAIgualarAltura = null,
		$goTop = null,
		$linkViewMore = null,
		$toggleParrafos = null,
		firstFixedTooltip = true,
		$fixedTooltip = null,
		esPrimerRecoloque = true,
		$copiaDePortalesEnAccesosDirectos = null,
		$accesosDirectos = null,
		$inputsPlay = null,
		$inputsCirc = null,
		$accesosDirectosHome = null,
		$liAccesosDirectos = null,
		$linksPhone = null,
		$calDvs = null,
		isSafari = false,
		isMobile = false,
		emptVal = false;

	this.calculaAlturaIframeCookies = function(){
		var $iframeCookies = jQuery('#i_cookies');
		if(jQuery.exists($iframeCookies)){
			var $LayerinferiorCookie = $iframeCookies.contents().find('#inferior');
			if(jQuery.exists($LayerinferiorCookie)){
				var alturaCookie = $LayerinferiorCookie.innerHeight();
				$iframeCookies.height(alturaCookie);
			}
		}
	}

	this.initLinksPhone = function(){
		$linksPhone = jQuery('a.tel');
	}

	this.disableLinksPhone = function(){
		if(jQuery.exists($linksPhone)){
			$linksPhone.on('click',function(e){
				e.preventDefault();
			});
		}
	}

	this.capturaModulosDestacados = function(){
		var $modulosDestacados = jQuery('#opciones_der .agrupColDer:lt(2)');
		if($modulosDestacados.length===2){
			$modulosAIgualarAltura = $modulosDestacados;
		}
	}

	this.igualaAlturasModulosDestacados = function(){
		if($modulosAIgualarAltura!==null){
			igualaAlturas($modulosAIgualarAltura);
		}
	}
	this.equalHghts = function(obj){
		var pubItms = jQuery(obj);
		if(pubItms.length > 0){
			igualaAlturas(pubItms);
		}
		window.addEventListener('resize', function(){
			clrHghts(pubItms);
		});
	}
	var igualaAlturas = function($elem){
		var alturaMaxima = 0;
		$elem.each(function(i,item){
			var $that = jQuery(item);
			var alturaItem = $that.height();
			if(alturaItem>alturaMaxima){
				alturaMaxima = alturaItem;
			}
		});
		$elem.height(alturaMaxima);
	}
	var clrHghts = function(pubItms){
		var $elmnts = jQuery(pubItms);

		$elmnts.each(function(j,k){
			if(k.hasAttribute('style')){
				k.removeAttribute('style');
			}
		});
		igualaAlturas(pubItms);
	};
	this.aniadeGoTop = function(){
		$goTop = jQuery('#goTop');
		if(!jQuery.exists($goTop)){
			$goTop = jQuery('<a href="#"><span class="offset">' + literales['btnGoTop'] + '</span></a>').attr('id','goTop');
			jQuery('div.contenedora').append($goTop);
		}
		eniadeEventoGoTop();
	}

	var eniadeEventoGoTop = function(){
		$goTop.on('click',function(e){
			e.preventDefault();
			Utils.mueveScrollToTop();
		});
	}

	this.muestraGoTop = function(){
		$goTop.show();
	}

	this.ocultaGoTop = function(){
		if(jQuery.exists($goTop)){
			$goTop.hide();
		}
	}

	this.mueveScrollToTop = function(){
		//jQuery('html, body').animate({scrollTop : 0},800);
		window.scrollTo(0,0);

	}

	//Funcionalidad de view more para los parrafos de seccion
	this.aniadeViewMore = function(){
		var $parrViewMore = jQuery('#ctr > div.texto_subhome > p:first-child,#ctr > div.intro_seccion .texto_subhome > p:first-child');
		if(jQuery.exists($parrViewMore)){
			var disableViewMore = jQuery.exists($parrViewMore.parents('.disableViewMore')),
				$cajaLinks = jQuery('.cajaLinksCols');
			if(jQuery.exists($cajaLinks)){
				chngCajalinks($cajaLinks);
			}
			if(!disableViewMore){
				$toggleParrafos = $parrViewMore.siblings();
				if(jQuery.exists($toggleParrafos)){
					if($toggleParrafos.attr('id')!=='mapawebB'){
						crearLinkViewMore($parrViewMore);
					}
				}
			}
		}
	};

	var crearLinkViewMore = function($parr){
		var _text = Literal.get('viewmore')+'...';
		$linkViewMore = jQuery('<a/>').attr({'id': 'viewMore', 'href': '#', 'aria-hidden': 'true'}).text(_text);
		$linkViewMore.hide();
		if(jQuery.exists($toggleParrafos)){
			aniadeEventoLinkViewMore();
			$parr.parent().append($linkViewMore);
		}
	}

	var aniadeEventoLinkViewMore = function(){
		$linkViewMore.on('click',function(e){
			e.preventDefault();
			if($toggleParrafos.hasClass('offset')){
				$toggleParrafos.removeClass('offset');
				$linkViewMore.text(Literal.get('viewless'));
			}else{
				$toggleParrafos.addClass('offset');
				$linkViewMore.text(Literal.get('viewmore')+'...');
			}
		});
	}

	var chngCajalinks = function($obj){
		var $ctnSh = jQuery('.texto_subhome');
		jQuery($ctnSh).after(jQuery($obj));		
	}

	this.activarViewMore = function(){
		if(jQuery.exists($linkViewMore)){
			$linkViewMore.show();
			$toggleParrafos.addClass('offset');
		}

	};

	this.desactivarViewMore = function(){
		if(jQuery.exists($linkViewMore)){
			$linkViewMore.hide();
			$toggleParrafos.removeClass('offset');
		}
	};

	this.initPortalesBanco = function(){
		var $portalesBanco = jQuery('#portalesDelBanco');
		$accesosDirectos = jQuery('#accdirectoshome');
		var $clonPortales = $portalesBanco.children().clone();
		$copiaDePortalesEnAccesosDirectos = jQuery('<li class="portalesEnAccDir"/>').html($clonPortales).hide();
		$accesosDirectos.append($copiaDePortalesEnAccesosDirectos);
		aniadeEventoPortalesBanco();
	}

	this.ocultaAccesosDirectos = function(){
		if($liAccesosDirectos != null){
			$liAccesosDirectos.removeClass('sel');
		}
	}

	var aniadeEventoPortalesBanco = function(){
		var $titulo = $copiaDePortalesEnAccesosDirectos.children('h2');
		$titulo.on('click',function(e){
			e.preventDefault();
			var $that = jQuery(this);
			var $parentLi = $that.parent('li');
			if(!$parentLi.hasClass('sel')){
				$copiaDePortalesEnAccesosDirectos.siblings('li').removeClass('sel');
				$copiaDePortalesEnAccesosDirectos.addClass('sel');
			}else{
				$parentLi.removeClass('sel');
			}
		});
	}

	this.mostrarPortalesBanco = function(){
		$copiaDePortalesEnAccesosDirectos.show();
	}

	this.ocultarPortalesBanco = function(){
		$copiaDePortalesEnAccesosDirectos.hide();
		if($copiaDePortalesEnAccesosDirectos.hasClass('sel')){
			$copiaDePortalesEnAccesosDirectos.removeClass('sel');
			$accesosDirectos.children('li:first-child').addClass('sel');
		}

	}

	this.initSelectPlay = function(){
		$inputsPlay = jQuery('input.fcirc, input#ir_monedas, input.env');
		Utils.detSafari();
		if(jQuery.exists($inputsPlay)){
			$inputsPlay.each(function(i,item){
				var $that = jQuery(item);
				$that.addClass('autoPlayInput');
				var $prevSelect = $that.prev('select');
				if(jQuery.exists($prevSelect) && jQuery('div.contBoletin').length === 0){
						$that.hide();
					if(isSafari === true && $that.hasClass('fcirc')){
						$that.show();
						$that.siblings('label').addClass('fcircLbl');
					} else {
						if($that.hasClass('fcirc') && $prevSelect.find('.emptyVal').length === 0){
							createOpt($prevSelect);
						}
						$prevSelect.on('change',function(){
							var $inputToClick = jQuery(this).next('.autoPlayInput'),
								$inputToClickAtt =  $inputToClick.attr('onclick');	
							$inputToClick.trigger('click');
							if($inputToClickAtt){
								if($inputToClickAtt.indexOf('irUri_') === -1 && $inputToClickAtt.indexOf('location.href') === -1){
									Combo.changeSelected();
								}	
							}
						});
					}
				}
			});
		}
		selEmpt();
	}

	var createOpt = function(parent){
		var $nwOpt = document.createElement('option');
		jQuery($nwOpt).attr({value: '#', selected: 'selected'});
		jQuery($nwOpt).addClass('emptyVal');		
		jQuery($nwOpt).text('---');
		jQuery($nwOpt).prependTo(parent);
		emptVal = true;
	}
	
	var selEmpt = function(){
		if(emptVal === true){
			jQuery('.emptyVal').each(function(x,a){
				a.selected = true;
			});
		}
	}
	
	this.destroyEmpt = function(){
		if(emptVal === true){		
			jQuery('.emptyVal').remove();
		}
	}

	this.destroySelectPlay = function(){
		if(jQuery.exists($inputsPlay)){
			$inputsPlay.each(function(i,item){
				var $that = jQuery(item);
				var $prevSelect = $that.prev('select');
				if(jQuery.exists($prevSelect)){
					$that.show();
					$prevSelect.off('change');
				}
			});
		}
	}

	this.selFirstAccesosDirectos = function(){
		var $liAccesosDirectosSel = $accesosDirectosHome.children('li.sel');
		if(!jQuery.exists($liAccesosDirectosSel)){
			$accesosDirectosHome.children('li:first-child').addClass('sel');
		}
	}

	this.accesosDirectosInit = function(){
		$accesosDirectosHome = jQuery('#accdirectoshome');
		if(jQuery.exists($accesosDirectosHome)){
			$liAccesosDirectos = $accesosDirectosHome.children('li');
			if($liAccesosDirectos.length>1){
				enquire.register('screen and (max-width:599px)', {
					match : function() {
						isMobile = true;
					}
				});
				enquire.register('screen and (min-width:600px)', {
					match : function() {
						isMobile = false;	
					}
				});
				aniadeEventosAccesosDirectos();
				Utils.calculaAlturaAccesosDirectos();
			}
		}
	}

	var aniadeEventosAccesosDirectos = function(){
		var $links = $liAccesosDirectos.find('h3 a');
		$links.on('click',function(e){
			e.preventDefault();
			var $that = jQuery(this);
			var $parentLi = $that.parents('li');
			if(!$parentLi.hasClass('sel')){
				$parentLi.siblings('li').removeClass('sel');
				$parentLi.addClass('sel');
			}else{
				if(isMobile){
					$parentLi.removeClass('sel');
				}
			}
		});
	}

	this.calculaAlturaAccesosDirectos = function(){
		if($liAccesosDirectos != null){
			var alturaMaxima = 0;
			$liAccesosDirectos.each(function(i,item){
				var $that = jQuery(item).find('ul');
				var alturaItem = $that.innerHeight();
				alturaMaxima = (alturaItem>alturaMaxima)?alturaItem:alturaMaxima;
			});
			$accesosDirectosHome.addClass('activePestanias').height(alturaMaxima);
			var alturaPestanias = $liAccesosDirectos.children('h3').innerHeight();
			if (alturaPestanias<=38)
			{
				$accesosDirectosHome.css('padding-top',38);
			}
			else {
				$accesosDirectosHome.css('padding-top',alturaPestanias);
			}
			
		}
	}

	this.controlCoofiales = function(){
		var cooficiales = ['ca', 'eu', 'gl', 'ca-valencia'];
		var lang = jQuery( 'html' ).attr( 'lang' );

		return (jQuery.inArray(lang, cooficiales) >= 0) ? lang : false;
	}
	
	this.idiomaPag = function(){
		var lang = jQuery( 'html' ).attr( 'lang' );
		
		if(lang == 'ca-valencia') {
			lang = 'va';
		}
		
		return lang;
	}

	this.comboAnyosVisibilidad = function (v) {
		// Oculta el bot�n que sirve para mostrar los datos en selector #comboAnyos
		jQuery('#comboAnyos #act_anterior fieldset label a').toggle(v);
		if(v == false) {
			jQuery('#comboAnyos #act_anterior fieldset label #anyo').attr('onChange', 'mostrar(); return false;');
		}
		else {
			jQuery('#comboAnyos #act_anterior fieldset label #anyo').removeAttr('onChange');
		}
	}
	this.detSafari = function(){
		var navVendor = navigator.vendor;
		if(navVendor && navVendor.indexOf('Apple') !== -1 ){
			isSafari = true;
		}
	}	
	this.imgToBg = function(dest, img){
		if(jQuery(img).length > 0 && !jQuery(img).hasClass('transparent')){
			var imgSrc = img.attr('src');
			
			dest.css('background', 'url('+ imgSrc +') center center');
			if(imgSrc != '' || imgSrc != null){
				img.addClass('transparent');
			} 
		}
	}
	this.addPgNvTxt = function($pgItem){
		var $pgIt = document.querySelectorAll($pgItem),
			$pgNvTxt = document.querySelectorAll('.pagNavText'),
			spnTxt = literales['pagina'];

		if($pgIt.length>0 && $pgNvTxt.length === 0){
			for(i=0;i<$pgIt.length;i++){
				if($pgIt[i].className !== 'next' && $pgIt[i].className !== 'back'){ 
					var nwSpn = document.createElement('span'),
						pgitHtml = $pgIt[i].innerText;
					
					nwSpn.className = 'offset pagNavText';
					nwSpn.innerText = spnTxt;	
				    $pgIt[i].insertAdjacentElement('afterbegin', nwSpn);
				}
			}
		}		
	}
	this.actSclBtn = function(){
		var $btnslist = jQuery('#socialShareLinks'),
			$combo = jQuery('.comboInf');

		if(jQuery.exists($btnslist)){
			var $sclBtn = jQuery('.socialShare > a').eq(0),
				wLoc = encodeURIComponent(window.location.href);

			$sclBtn.on('click', function(){
				jQuery('.socialShare').toggleClass('active');
				
				return false;
			});

			if(jQuery.exists($btnslist)){
				crearLinks(wLoc);			
			}	
		};
	}
	var crearLinks = function(loc){
		var $lnks = jQuery('#socialShareLinks li > a');

			jQuery($lnks).each(function(x,y){
				var lnksH = jQuery(y).attr('href'),
					thsTrg = '';

				if(lnksH.indexOf('?u=') != -1){
					thsTrg = lnksH.substring(0, lnksH.indexOf('u=')+2); 
				}
				if(lnksH.indexOf('url=') != -1){
					thsTrg = lnksH.substring(0, lnksH.indexOf('url=')+4); 
				}
				if(lnksH.indexOf('body=') != -1){
					var fstP = lnksH.substring(0, lnksH.indexOf('body=')),
						scndP = lnksH.substring(lnksH.indexOf('body=')),
						thrdP = scndP.substring(0, scndP.indexOf(':')+1);					

					thsTrg = fstP + thrdP;
				}
				jQuery(y).attr('href', thsTrg + loc);
			});
	}
	this.shareDocs = function(){
		var $docsUl = document.querySelector('.socialShareLinksDoc');

		if($docsUl) {
			var $docsLiA = document.querySelectorAll('.socialShareLinksDoc li a'),
				x= '';
			for(x=0; x < $docsLiA.length; x++) {
				var aHrf = $docsLiA[x].getAttribute('href'),
					prtclHrf = aHrf.substring(0, aHrf.indexOf('://')+3),
					aHrfMod = aHrf.substring(aHrf.indexOf('://')+3),
					prntClss = $docsLiA[x].parentNode.classList,
					nwSt = '',
					domHrf = '',
					encHrf = ''; 

					if(aHrf.indexOf('mailto:') !== -1){
						prtclHrf = aHrf.substring(0, aHrf.indexOf('body='));
						aHrfMod = aHrf.substring(aHrf.indexOf('body='));
					}
					nwSt = new sclLnksURL(aHrfMod);
					domHrf = nwSt.final;
					encHrf = nwSt.enc;

					$docsLiA[x].href = prtclHrf  + domHrf +  encHrf;
					$docsLiA[x].addEventListener('click', function(){
						trckClck(this.parentNode.classList, this);
					});
			}
		}
	}
	
	var sclLnksURL = function(urlStr){
		var finalSt = '',
			encTxt = '',
			encURI = '',
			encSt = '';

		if(urlStr.indexOf('url=')!== -1){
			if(urlStr.indexOf('text=')!== -1){
				finalSt = urlStr.substring(0, urlStr.indexOf('text=')+5);
				encTxt = encodeURI(urlStr.substring(urlStr.indexOf('text=')+5, urlStr.indexOf('url=')+4));	
				encURI = encodeURIComponent(urlStr.substring(urlStr.indexOf('url=')+4));
				encSt = encTxt + encURI;

			} else{
				finalSt = urlStr.substring(0, urlStr.indexOf('url=')+4);
				encSt = encodeURIComponent(urlStr.substring(urlStr.indexOf('url=')+4));	
			}
		}
		if(urlStr.indexOf('?u=')!== -1){
			finalSt = urlStr.substring(0, urlStr.indexOf('?u=')+4);
			encSt = encodeURIComponent(urlStr.substring(urlStr.indexOf('?u=')+4));
		}
		if(urlStr.indexOf('body=')!== -1){
			if(urlStr.indexOf('https://')!== -1 || urlStr.indexOf('http://')!== -1){
				var prtDir = '';
				if(urlStr.indexOf('https://')!== -1) {
					prtDir = 'https://';
				} else {
					prtDir = 'http://';
				}
				finalSt = urlStr.substring(0, urlStr.indexOf(prtDir)+8);
				encSt = encodeURIComponent(urlStr.substring(urlStr.indexOf(prtDir)+8));	
			} else {
				finalSt = urlStr.substring(0, urlStr.indexOf('body=')+5);
				encSt = encodeURIComponent(urlStr.substring(urlStr.indexOf('body=')+5));	
			}
		}
		return {final: finalSt, enc: encSt};
	}
	var trckClck = function(parentClass, aOb){
		var scl = parentClass,
			prntHref = jQuery(aOb).closest('div').prev().attr('href');
			
		if(prntHref.indexOf('http') === -1){
			prntHref = window.location.protocol + '//' + window.location.host + prntHref;
		}
		for(var a=0;a<scl.length;a++){
			if(scl[a].indexOf('sharedoc-')!== -1){
				var sclNm = whchScl(scl[a]);
				bde_track('documento_compartido', sclNm, prntHref);
			}
		}
	}
	var whchScl = function(sclClss){
		var nm = '';
		switch (sclClss) {
			case 'sharedoc-fb':
			nm = 'Facebook';
			break;
			case 'sharedoc-twitter':
			nm = 'Twitter';
			break;
			case 'sharedoc-linkedin':
			nm = 'Linkedin';
			break;
			case 'sharedoc-mail':
			nm = 'Email';
			break;
		}
		return nm;
	}
	this.setBgColor = function() {
		var c = document.createElement('canvas'),
		ctx = c.getContext('2d'),
		$img = document.querySelectorAll('#dest2 > li > a img:first-of-type');
		
		for(i=0; i< $img.length; i++) {
				var c = document.createElement('canvas'),
				ctx = c.getContext('2d'),
				$liPrnt = $img[i].parentElement.parentElement;
				
				ctx.drawImage($img[i], 0, 0);
				var imgData = ctx.getImageData(0, 0, 1, 1);
				if($img[i].complete === true){
					$liPrnt.style.backgroundColor = 'rgb(' + imgData.data[0] + ',' + imgData.data[1] + ',' + imgData.data[2] + ')';
				
					ctx.putImageData(imgData, 0, 0);    					
				} else {
					$img[i].onload = function(){Utils.setBgColor();}
				}
		}	
	}
	this.addSkipBtn = function(){
		var skipA = document.createElement('a'),
			skpTxt = literales['saltarContenido'], 
			skpClss = 'skipcontent',
			ctnDiv = document.getElementById('wrapperContent');

			if(ctnDiv){
				var cntnt = document.querySelector('#ctr');
				if(!cntnt){
					cntnt = document.querySelector('#ciH');
				}
				skipA.href = '#' + cntnt.id;
				skipA.innerText = skpTxt;
				skipA.className = skpClss;
				ctnDiv.insertBefore(skipA, ctnDiv.firstChild);
			}
	}
	this.toolsBnnr = function(){
		var nvl4Hdrs = document.querySelectorAll('#opciones_der h4');

		for(var u = 0;u<nvl4Hdrs.length; u++){
			if(nvl4Hdrs[u].innerText.toUpperCase() === 'HERRAMIENTAS'){
				
				if(nvl4Hdrs[u].nextElementSibling.tagName.toUpperCase() === 'UL'){
					var toolLst = nvl4Hdrs[u].nextElementSibling,
						lstMg = toolLst.querySelectorAll('li.noicon > a:first-child > img:first-child'); 

					for(var t=0;t<lstMg.length;t++){
						if(lstMg[t].parentNode.innerText.trim() === ''){
							lstMg[t].parentNode.parentNode.classList.add('img_grande_destacado');
						}
					}
				}
			}
		}
	}
	this.chkLnks = function(inURL){
		var hst = '';

		if(window.location.hostname === 'app.bde.es') {
			hst = 'https://www.bde.es';
		} 
		if(window.location.hostname === 'pre.bde.es'){
			hst = 'https://prw.bde.es';
		}

		var allLnks = document.querySelectorAll('a[href*="'+ inURL +'"]');
		
		if(allLnks.length > 0) {
			var urlModal = hst + '/f/Sistema/cookies/modal_descargas_'+ Utils.idiomaPag() +'.html';

			for(var i=0; i<allLnks.length; i++){
				if(allLnks[i].href.indexOf('.pdf') !== -1){
					allLnks[i].addEventListener('click', function(ev){					
						Utils.crtMdl('modal--dwnld', urlModal + ' #inferior', this);
						
						ev.preventDefault();
					});	
				}
			}
		}
	};
	this.crtMdl = function(cssclass, htmlLink, prntLnk){
		var mdlCnt = document.createElement('div');

		mdlCnt.setAttribute('class', cssclass);
		mdlCnt.style.top = window.pageYOffset + 'px';
		Utils.crtMsk();
		window.addEventListener('resize', function(){
			mdlCnt.style.top = window.pageYOffset + 'px';
		});
		document.querySelectorAll('body')[0].insertAdjacentElement('beforeend', mdlCnt);
		jQuery(mdlCnt).load(htmlLink, function(){	
			var cstmArea = document.querySelector('#modal_custom');

			if(cstmArea) {
				var nwP = document.createElement('p'),
					nwLnk = document.createElement('a'),
					$modal__btn = document.querySelector('#modal__btn'),
					$modal__btnCls = document.querySelectorAll('.modal__btn--cls')[0];
				
				if(prntLnk.innerText.trim() === '' ){
					nwLnk.innerText = prntLnk.querySelectorAll('img')[0].alt;
				} else {
					nwLnk.innerText = prntLnk.innerText;
				}
				nwLnk.href = prntLnk.href;
				nwLnk.setAttribute('target', 'blank');
				nwP.appendChild(nwLnk);
				cstmArea.appendChild(nwP);
				
				nwLnk.addEventListener('click', function(){
					Utils.clsMdl(cssclass);
					});
				
				$modal__btn.addEventListener('click', function(){
					Utils.clsMdl(cssclass);
					});
				
				$modal__btnCls.addEventListener('click', function(){
					Utils.clsMdl(cssclass);
				});
				
				Utils.tabTrap(cssclass);
				jQuery(this).find('*[id] a').eq(0).focus();
			}
		});
	};
	this.crtMsk = function(){
		var mskDv = document.createElement('div');

		mskDv.setAttribute('class', 'modal__mask');
		mskDv.setAttribute('id', 'modal__mask');
		mskDv.style.top = window.pageYOffset+ 'px';
		document.querySelectorAll('body')[0].insertAdjacentElement('beforeend', mskDv);
		document.querySelectorAll('body')[0].classList.add('modal__noScroll');
		window.addEventListener('resize', function(){
			document.querySelector('#modal__mask').style.top = window.pageYOffset+ 'px';
		});
	};
	this.clsMdl = function(mdlclss){
		var mdlDv = document.querySelectorAll('.'+ mdlclss)[0],
			mskdv = document.querySelector('#modal__mask');

		if(mdlDv) {
			mdlDv.parentNode.removeChild(mdlDv);
		}
		if(mskdv) {
			mskdv.parentNode.removeChild(mskdv);				
		}
		document.querySelectorAll('body')[0].classList.remove('modal__noScroll');
		Utils.chnFcs('add');
	};
	this.tabTrap = function(brdrDv){
		var $brdrDv = document.querySelectorAll('.' + brdrDv)[0],
			$brdrDvLnk = $brdrDv.querySelectorAll('a');   

		Utils.chnFcs('remove');

		for(var p=0;p<$brdrDvLnk.length;p++){
			$brdrDvLnk[p].removeAttribute('tabindex');
		}
	}
	this.chnFcs = function(op){
		var allLmnts = document.querySelectorAll('body *'),
		mdlLmnts = document.querySelectorAll('.modal--dwnld *');
		
		if(op === 'remove'){
			for(var x=0;x<allLmnts.length; x++){
				if(allLmnts[x].tabIndex !==-1){
					var oldTb = allLmnts[x].tabIndex;
						
					allLmnts[x].setAttribute('data-tabindex', oldTb);
				}
				allLmnts[x].tabIndex = -1;
				jQuery(mdlLmnts).removeAttr('tabindex');
			}
		}
		if(op === 'add'){
			for(var x=0;x<allLmnts.length; x++){
				if(allLmnts[x].getAttribute('data-tabindex') !==null){
					var oldAtt = allLmnts[x].getAttribute('data-tabindex');
					allLmnts[x].tabIndex = oldAtt;
				} else {
					allLmnts[x].removeAttribute('tabindex');
				}
			}		
		}
	}
	this.respVids = function(){
		var vds = document.querySelectorAll('div#ctr iframe');
		
		for(var f=0;f<vds.length; f++){
			if(vds[f].src.indexOf('youtube') !== -1 || vds[f].src.indexOf('youtu.be') !== -1){
				var wdth = vds[f].offsetWidth,
					nHgt = wdth / 1.74;
				vds[f].setAttribute('height', nHgt);
			}
		}
	}
	this.calSelect = function(){
		var calFrm = document.querySelectorAll('#boletin_ope');

		if(calFrm.length > 0){
			var crrntDv = document.querySelector('#tb_actual');

			$calDvs = document.querySelectorAll('.contBoletin');
				
			for(var j=0; j<$calDvs.length; j++){
				$calDvs[j].classList.add('calhidden');
			}
			crrntDv.classList.remove('calhidden');
			calBtn();
		}

	}
	var calBtn = function(sbmt){
		var calFrm = document.querySelector('#boletin_ope');

		chngCal(calFrm);	
		calFrm.addEventListener('submit', function(ev){
			ev.preventDefault();
			chngCal(this);	
		});
	}
	var chngCal = function(ths){
		var vl = ths.anyo.value;
		vl = getSelVal(vl);

		for(var t=0;t<$calDvs.length; t++){
			$calDvs[t].classList.add('calhidden');
		}
		document.querySelector('#tb_' + vl).classList.remove('calhidden');
	}
	var getSelVal = function(str){
		var val = str;

		val = val.substring(val.lastIndexOf('#') + 1);

		if(val.indexOf('ancla_') !== -1){
			val = val.substring(val.lastIndexOf('ancla_') + 6);
		}
		return val;
	}
	this.scrllShdw = function(prnt, shdwColor){
		var toScrll = document.querySelectorAll(prnt);

		for(var x=0; x < toScrll.length; x++){
			var cntDv = document.createElement('div'), 
				rghtShdw = document.createElement('div'),
				lftShdw = document.createElement('div');

			cntDv.setAttribute('id', 'scrollCtn_' + x);
			toScrll[x].insertAdjacentElement('beforebegin', cntDv);
			cntDv.insertBefore(toScrll[x], cntDv.firstChild);
			rghtShdw.classList.add('scrollSdw__right');
			rghtShdw.classList.add('scrollSdw__right--'+ shdwColor);
			lftShdw.classList.add('scrollSdw__left');
			lftShdw.classList.add('scrollSdw__left--'+ shdwColor);
			cntDv.insertBefore(rghtShdw, cntDv.firstChild);
			cntDv.insertBefore(lftShdw, cntDv.firstChild);

			if(toScrll[x].scrollWidth > 0) {
				var dvWdth = toScrll[x].offsetWidth,
					scrWdth = toScrll[x].scrollWidth;

				cntDv.classList.add('scrollRight');
				
				toScrll[x].addEventListener('scroll', function(){
				var scrLft = this.scrollLeft;
				
					this.parentNode.classList.remove('scrollLeft');
					this.parentNode.classList.remove('scrollRight');
					if(scrLft > 0){
						this.parentNode.classList.add('scrollLeft');
					}
					if(scrLft !== scrWdth - dvWdth){
						this.parentNode.classList.add('scrollRight');
					}
				});	
			}
		}
	}
}).apply( Utils );


if(document.querySelectorAll('a[href*="/ficherosgenerales/descargar/"]').length>0){
	document.addEventListener('DOMContentLoaded', function(){
		Utils.chkLnks('/ficherosgenerales/descargar/');
	});
}